import "./PopupBox.scss"

function PopupBox({ children, visible }) {


    if (visible)
        return (
            <div className="PopupBox" style={{ display: visible ? "block" : "none" }}>
                <div className="inner">
                    {children}
                </div>
            </div>
        )
    return null
}
export default PopupBox