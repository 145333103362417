import { useState } from "react"
import ZoomBox from "../../boxes/ZoomBox"
import Clock from "../../text/Clock"

function Device({ children, notch, live, hideStatusbar }) {

    const [Color, setColor] = useState(deviceColors[1])


    const [DarkMode, setDarkMode] = useState(false)


    const toggleDarkMode = () => setDarkMode(!DarkMode)

    let dmSwitch = (
        <div
            className="darkModeToggle"
            onClick={toggleDarkMode}
        >
            <i className={`fa-duotone fa-toggle-${DarkMode ? "on" : "off"}`} />
            <span>Dark-Mode </span>
        </div>
    )


    return (
        <div className="Device">

            <div className="options d-flex flex-row justify-content-between align-items-end">
                <div>
                    {!live ?
                        <></>
                        :
                        null
                    }
                    <span><b>Vorschau (Beta)</b></span>
                </div>


                {dmSwitch}
                {/* <i className="fa-solid fa-qrcode"></i>
                <i className="fa-solid fa-external-link"></i> */}
            </div>



            <ZoomBox>
                <div
                    className={"device " + (DarkMode ? "dark" : "light")}
                    style={{ background: Color.hex }}>
                    <div className="screen">

                        {hideStatusbar ? null :
                            <div className="StatusBar" >
                                <div className="notch"></div>
                                <div className="inner" >
                                    <div>
                                        <span><Clock /></span>
                                        <i className="fa-solid fa-location-arrow"></i>
                                    </div>
                                    <div>
                                        <i className="fa-duotone fa-signal-4"></i>
                                        <i className="fa-duotone fa-battery-full"></i>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className={"content"} style={{ marginTop: hideStatusbar ? 0 : null }}>
                            {children}
                        </div>

                    </div>
                </div>
            </ZoomBox>
            {/* 
            <small>Dark-Theme</small>
            <div className="d-flex justify-content-center">
                <button onClick={() => setDarkMode(!DarkMode)}>
                    {DarkMode ? "Aktiv" : "Aktivieren"}
                </button>
            </div> */}


            {/* <small>Gerät-Farbe</small>
            <div className="d-flex justify-content-center">
                <ColorSelection colors={deviceColors} Color={Color} setColor={setColor} />
            </div> */}

        </div >
    )
}
export default Device



const deviceColors =
    [
        {
            hex: "linear-gradient(180deg, rgba(199,236,249,1) 0%, rgba(147,198,214,1) 100%)",
            title: "Silberblau",
            id: 1,
        },
        {
            hex: "linear-gradient(-4deg, #38433d 0%, #80887c 100%)",
            title: "Nachgrün",
            id: 1.5,
        },
        {
            hex: "linear-gradient(183deg, rgba(255,245,210,1) 0%, rgba(223,204,171,1) 100%)",
            title: "Gold",
            id: 2,
        },
        {
            hex: "linear-gradient(-12deg, rgba(191,192,186,1) 0%, rgba(230,230,220,1) 63%, rgba(240,245,253,1) 80%, rgba(191,192,186,1) 100%)",
            title: "Silber",
            id: 3,
        },
        {
            hex: "linear-gradient(164deg, #686661 0%, #585651 100%)",
            title: "Space Grau",
            id: 4,
        },
        {
            hex: "linear-gradient(150deg, red, blue)",
            title: <>dashdesign;</>,
            id: 5,
        },
    ]
