import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import TextareaAutosize from 'react-textarea-autosize';
import Config from '../../const/Config';


const allowedKeys = ["warning", "update", "city", "voucher", "corona", "test"]

function getKeyPerCity() {

    return;
}



export default function AdminSendNotification(props) {

    const [entries, setEntries] = useState([]);

    const [textInput, setSetTextInput] = useState('');
    const [titleInput, setTitleInput] = useState('');
    const [playSound, setPlaySound] = useState(true);
    const [ttl, setTTL] = useState("2000");
    const [entryNavigation, setEntryNavigation] = useState("");

    const [selection, setSelection] = useState('');
    const [message, setMessage] = useState('');
    const [cityIds, setCityIds] = useState([]);
    const [cityId, setCityId] = useState('');

    function setTextInput(val) {
        setSetTextInput(val)
    }

    useEffect(() => {
        fetch();
    }, []);

    async function fetch() {
        const config = { headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem("token") } };
        console.log(Config.ServerAddress);
        let res = await axios.get(Config.ServerAddress + "/admin/getCities", config);
        setCityIds(res.data);

        let res2 = await axios.get(Config.ServerAddress + "/entries/list");

        setEntries(res2.data);
    }

    async function onClick() {
        const config = { headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem("token") } };
        let data = {
            message: message,
            title: titleInput,
            cityId: cityId,
            ttl: ttl,
            sound: playSound,
            data: entryNavigation ? {
                type: "redirect",
                to: "EntryPage",
                sitePayload: { entryID: Number(entryNavigation) }
            } : null
        }

        //Je nach modus
        if (textInput) {
            data.recipientType = "token"
            data.recipient = textInput.split("\n")
        }
        else if (selection) {
            data.recipientType = "group"
            data.recipient = selection
        }



        await axios.post(Config.ServerAddress + "/admin/sendNotification", data, config)
            .then(res => {
                alert("Erfolgreich gesendet!");
            })
            .catch(err => {
                console.log(err)
            })


    }
    return (
        <div>
            <Helmet>
                <title>Benachrichtigung Versenden</title>
            </Helmet>
            <div className="container mt-5">

                <h2>Benachrichtigung versenden:</h2>


                <div className="row">
                    <div className="col-12 col-lg-6">

                        <h6>An Gruppe senden:</h6>
                        <p>Sendet eine Push-Benachrichtigung an alle Nutzer:innen, die diese "Gruppe" aktiviert haben.</p>
                        <select style={{ marginBottom: 20 }} disabled={textInput} onChange={(e) => setSelection(e.target.value)}>
                            <option value="">Gruppe wählen...</option>
                            {allowedKeys.map(i => <option value={i}>{i}</option>)}
                        </select>
                        {
                            selection ?
                                <select style={{ marginBottom: 20 }} disabled={textInput} onChange={(e) => setEntryNavigation(e.target.value)}>
                                    <option value="">Option wählen...</option>
                                    {cityIds.map(i => <option value={i.ID}>{i.name}</option>)}
                                </select> : null
                        }
                    </div>

                    <div className="col-12 col-lg-6">
                        <h4>Test-Notification</h4>
                        <p>Oder Test-Notification per dashdesign;-push-key senden</p>
                        <TextareaAutosize
                            disabled={selection}
                            value={textInput}
                            onChange={(e) => setTextInput(e.target.value)} />




                    </div>
                </div>


                {/* <h6>Titel: (optional)</h6>
                <input style={{ marginBottom: 50 }} value={title} onChange={(e) => setTitle(e.target.value)} />

                <h6>Untertitel: (optional, Nur für iOS-Geräte)</h6>
                <input style={{ marginBottom: 50 }} value={subtitle} onChange={(e) => setSubtitle(e.target.value)} /> */}
                <h6>Titel:</h6>
                <TextareaAutosize
                    value={titleInput}
                    onChange={(e) => setTitleInput(e.target.value)} style={{ marginBottom: 30 }} />

                <h4>Soll ein Ton-Empfehlung gesendet werden? (iOS)</h4>
                <p>Bei Android wählen die Nutzende selbst, ob ein Ton abgespielt wird. Bei iOS ist das auch so, jedoch können wir eine Empfehlung mitsenden. - Diese überschreibt natürlich nicht den Stummmodus und ähnliche Einstellungen.</p>
                <input checked={playSound} onChange={(e) => setPlaySound(!playSound)} type="checkbox" style={{ width: "auto", margin: 20, marginBottom: 20 }} />

                < h6 > Nachricht eingeben:</h6>
                <TextareaAutosize minRows={2} style={{ marginBottom: 50 }} value={message} onChange={(e) => setMessage(e.target.value)} />

                <h6>TTL (Zeit, in der die Push-Nachricht für die Auslieferung zwischengespeichert wird)</h6>

                <TextareaAutosize
                    disabled={selection}
                    value={ttl}
                    onChange={(e) => setTTL(e.target.value.replace(/\D/, ''))} style={{ marginBottom: 30 }} />

                <h6>Zu welchem Eintrag soll die Nachricht navigieren? (optional)</h6>
                <select style={{ width: '100%', marginBottom: 20 }} onChange={(e) => setEntryNavigation(e.target.value)}>
                    <option value="">Keine Aktion</option>
                    {entries.sort((a, b) => a.general.title.localeCompare(b.general.title)).map(i => <option value={i.meta.entryID}>{i.general.title}</option>)}
                </select>
                <button disabled={(!textInput && (!selection && !cityId) || !message)} onClick={onClick}>Senden</button>



            </div>
        </div >);
}