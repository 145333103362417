import axios from "axios"
import Config from "../const/Config"

export function Login(Username, Password) {
    return new Promise((resolve, reject) => {
        const LOGIN_ENDPOINT = Config.ServerAddress + "/auth/login"
        const LOGIN_BODY = { username: Username, password: Password, }

        if (Username.length > 4 && Password.length > 2) {
            axios.post(LOGIN_ENDPOINT, LOGIN_BODY)
                .then(res => {
                    let { token, payload } = res.data
                    let { uuid, t, mail, preName, familyName, gender, permissions, isAdmin } = payload

                    sessionStorage.setItem("token", token)
                    localStorage.setItem("uuid", uuid)
                    localStorage.setItem("t", t)
                    localStorage.setItem("mail", mail)
                    localStorage.setItem("preName", preName)
                    localStorage.setItem("familyName", familyName)
                    localStorage.setItem("gender", gender)
                    localStorage.setItem("permissions", permissions)
                    localStorage.setItem("isAdmin", isAdmin)

                    resolve(payload)
                })
                .catch(err => {
                    if (err.message == "Network Error") {
                        reject("Serververbindung fehlgeschlagen.")
                        // setError("Serververbindung fehlgeschlagen. 🤨")
                    } else {
                        console.log(err)
                        reject("Ungültige Logindaten.")
                    }
                })
        } else {
            reject("Bitte befüllen Sie alle Felder")
        }
    })



}