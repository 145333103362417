import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Helmet from "react-helmet"
import { Link, useParams } from 'react-router-dom';
import Config from '../../../const/Config';

function AdminCityDistricts() {
    const [List, setList] = useState([]);
    let { appSlug } = useParams();

    useEffect(() => {
        let apiEndpoint = Config.ServerAddress + "/admin/city/districts?appSlug=" + appSlug
        let config = { headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem("token") }, }

        axios.get(apiEndpoint, config)
            .then(res => {
                setList(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    return (
        <div className="col-12 col-md-9 mt-4">
            <Helmet>
                <title>Districts von {appSlug}</title>
            </Helmet>

            <div>
                <h1>{appSlug || "appSlug??"}</h1>

                <div>
                    {List.map((i, k) =>
                        <div key={k}>
                            <p>{i.DistrictName}</p>
                        </div>
                    )}
                </div>

                <AddDistrictInput appSlug={appSlug} onAdd={(e) => setList([...List, e])} />
            </div>
        </div>
    )
}
export default AdminCityDistricts

function AddDistrictInput({ appSlug, onAdd }) {
    const [Name, setName] = useState("");
    const [Error, setError] = useState();

    function send() {
        setError(false)
        let apiEndpoint = Config.ServerAddress + "/admin/city/districts"
        let data = {
            appSlug: appSlug,
            DistrictName: Name,
        }
        let config = { headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem("token") }, }

        axios.post(apiEndpoint, data, config)
            .then(res => {
                onAdd({ DistrictName: Name })
                setName("")
            })
            .catch(err => {
                setError(true)
                console.log(err)
            })
    }

    return (
        <div>
            <hr />
            <input value={Name} placeholder='zB: Weitershain' onChange={(e) => setName(e.target.value)} />
            <button onClick={send}>Erstellen</button>
            {Error ? <p><b>Fehler</b> beim erstellen von {Name}</p> : null}

            <br />
            <small><b>Hinweis:</b> Weiler einzeln aufführen. zB: "Hainerhof (Weitershain)" – In dem Fall ist Weitershain der Ort und der Hainerhof ein Weiler.</small>
        </div>
    )
}