import React from 'react'
import { Link } from 'react-router-dom'
import "./Tile.css"

function Tile({ title, icon, uri, url }) {

    function Content() {
        return (
            <div className="Tile">
                <div className="item" >
                    {icon}
                </div>
                <div>
                    <span className="title">{title}</span>
                </div>
            </div>
        )
    }

    if (uri) return <Link to={uri}><Content /></Link>
    return <a target="_blank" href={url}><Content /></a>
}

export default Tile