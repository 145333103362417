import React, { useState } from "react"
import { getEmail, getFullName } from "../../../functions/auth"
import "./PaymentAddress.scss"

function PaymentAddressInput({ startValue, onChange = () => { }, error }) {

    const [AddressSuggestions, setAddressSuggestions] = useState()
    const [Value, setNewValue] = useState({
        zip: startValue.zip || "",
        city: startValue.city || "",
        street: startValue.street || "",
        fullName: startValue.fullName || getFullName() || "",
        companyName: startValue.companyName || ""
    })

    function update(key, value) {
        let newValue = Value
        newValue[key] = value
        let check = checkAddress(newValue)
        onChange({ target: { value: newValue, check: check } })
        setNewValue({ ...newValue })
    }

    let email = getEmail()

    return (
        <div className="PaymentAddress">
            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="mb-4">
                        <h4>Gewerbename</h4>
                        <input

                            autoComplete="organization"
                            onChange={(e) => update("companyName", e.target.value)}
                            value={Value.companyName}
                            placeholder="z.B.: dashdesign e.K." />
                    </div>
                    <div className="mb-4">
                        <h4>Ihr vollständiger Name</h4>
                        <input
                            required
                            autoComplete="name"
                            onChange={(e) => update("fullName", e.target.value)}
                            value={Value.fullName}
                            placeholder="z.B.: Konrad Mayer" />
                    </div>
                    <div className="mb-4">
                        <h4>Straße + Nr</h4>
                        <div div className="d-flex">
                            <div className="col-12">
                                <input
                                    required
                                    autoComplete="street-address"
                                    onChange={(e) => update("street", e.target.value)}
                                    value={Value.street}
                                    placeholder="z.B.: Poststraße" />
                            </div>
                        </div>
                    </div>
                    <div className="mb-4">
                        <h4>PLZ + Ort</h4>
                        <div div className="d-flex">
                            <div className="col-3 pe-2">
                                <input
                                    required
                                    autoComplete="postal-code"
                                    onChange={(e) => update("zip", e.target.value)}
                                    value={Value.zip}
                                    placeholder="z.B.: 35305" name="zip" id="zip" inputmode="numeric" maxLength={5} pattern="[0-9]*" type="numer" />
                            </div>
                            <input
                                required
                                autoComplete="shipping locality"
                                onChange={(e) => update("city", e.target.value)}
                                value={Value.city}
                                placeholder="z.B.: Grünberg, Weitershain" />
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-6">
                    <p><b>Vorschau:</b></p>
                    <div className={error ? "output error" : "output"}>
                        <span >
                            <b>{genAddressName(Value.fullName, Value.companyName)}<br /></b>
                            {Value.street} {Value.streetNr} <br />
                            {Value.zip} {Value.city} <br />
                            Deutschland
                        </span>
                    </div>
                    <div className="p-2">
                        <p className="text-break text-wrap">Wir senden Ihnen die Rechnung an die folgende E-Mail: <a href={"mailto:" + email}>{email}</a></p>
                    </div>
                </div>
            </div>
        </div >
    )
}
export default PaymentAddressInput

function checkAddress(AddressObj) {
    let { fullName, companyName, zip, street, streetNr, city } = AddressObj
    let result = { value: true, messages: [] }

    //loakal prüfen
    if (genAddressName(fullName, companyName).length <= 10) {
        result.value = false
        result.messages.push("Ungülltiger Gewerbename/Name")
    }
    if (zip.length != 5) {//99999 gibt es nicht, aber 99998
        result.value = false
        result.messages.push("Ungülltige PLZ")
    }
    if (street.length <= 2) { // "Tal" ist die Kürzeste
        result.value = false
        result.messages.push("Ungülltige Straße")
    }
    if (city.length <= 2) { // Gibt 4real 3 stellige Städte
        result.value = false
        result.messages.push("Ungülltiger Ort")
    }

    //todo: auf server?
    return result
}

function genAddressName(fullName, companyName) {
    if (fullName == companyName || !companyName) {
        return "Firma\n" + fullName
    } else {
        return `${companyName}\nz.H.: ${fullName}`
    }
}
