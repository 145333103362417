import axios from "axios";
import { useState } from "react";
import Config from "../../../const/Config";
import "./AddressInput.scss";

function AddressInput({ onChange, value }) {

    const [Address, setAddress] = useState()
    const [Search, setSearch] = useState("")
    const [Suggsetions, setSuggsetions] = useState([]);
    const [Error, setError] = useState(false);

    function loadStreets(searchText) {
        setError(false);
        axios
            .get(Config.ServerAddress + "/geo/steets?searchStreetText=" + searchText)
            .then((res) => {
                setSuggsetions([...res.data]);
            })
            .catch((err) => {
                setError("Fehler beim Laden");
            });
    }

    function handleSearch(searchText) {
        setSearch(searchText);
        if (searchText && searchText.length > 2) {
            loadStreets(searchText);
        } else if (!searchText) {
            setSuggsetions([]);
        }
    }

    function handleClick(streetObj) {
        setSearch(`${streetObj.city}, ${streetObj.street},\n`)
        setSuggsetions([])
        // onChange cllback ausführen
    }

    return (
        <div className="AddressInput">

            <p>{JSON.stringify(value)}</p>

            {/* <ValidateInput value={Address} regex={"true"} /> */}

            <textarea value={Search} onChange={(e) => handleSearch(e.target.value)} />

            {Suggsetions.map((i, k) =>
                <Suggsetion {...i}
                    onClick={() => handleClick(i)}
                />

            )}
        </div>
    )
}
export default AddressInput

function Suggsetion({ city, street, onClick }) {
    return (
        <div onClick={onClick}>
            <p><b>{street}</b>, {city}</p>
        </div>
    )
}