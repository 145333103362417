import { Link } from 'react-router-dom';
import DefaultTile from "../../components/defaults/boxes/DefaultTile";
import "./PageDashboard.scss";

function PageDashboard() {

    return (
        <div className="PageDashboard">

            <h1>Willkommen zurück!</h1>

            <hr />

            <div className="container">
                <div className="row">
                    {tiles.map((i, k) => <DefaultTile {...i} key={k} />)}
                </div>
            </div>


            {/* <div className="container">
                <div className="row">
                    {adminTiles.map((i, k) => <DefaultTile {...i} key={k} />)}
                </div>
            </div> */}

            <Link to="/admin">
                <small style={{ position: "fixed", bottom: 12, }}>Admin</small>
            </Link>

        </div>
    )
}
export default PageDashboard
const adminTiles = [
    {
        title: "Admin",
        icon: <i className="fa-solid fa-user-crown"></i>,
        uri: "/admin",
    },
]
const tiles = [
    // {
    //     title: "Zuletzt bearbeitet",
    //     desc: <>Sie möchten einen weiteren Standort anlegen.</>
    // },
    // {
    //     title: "Bestehender Eintrag bearbeiten",
    //     desc: <>Sie möchten einen weiteren Standort anlegen, einen neuen Betrieb, Verein oder sonstige Organisation eintragen? Dann sind Sie hier genau richtig.</>,
    //     buttons: <><Link className="/neuer-eintrag"><button>Neuen Eintrag buchen</button></Link></>
    // },

    {
        title: "Eintrag verwalten",
        icon: <i className="fa-solid fa-address-card"></i>,
        uri: "/entry/list",
    },
    {
        title: " Eintrag hinzufügen",
        icon: <i className="fa-solid fa-circle-plus"></i>,
        uri: "/neuer-eintrag",
    },
    // {
    //     title: "Berechtigung an bestehenden Eintrag erhalten",
    //     desc: <>Sie möchten einen Eintrag bearbeiten, der nicht von Ihnen erstellt wurde? Kein Problem, schreiben Sie uns bitte eine kurze E-Mail.</>,
    //     buttons: <a href="mailto:mailto:Anforderung: Rechte an Eintrag?body=Hallo%2C%0D%0A%0D%0Aich%20w%C3%BCrde%20gerne%20den%0D%0A%0D%0AEintrag%3A%20_____%0D%0A%0D%0ABearbeiten.%0D%0A%0D%0AMeine%20Nutzer-ID%20ist%3A%20_____">
    //         <button>Berechtigung anfordern</button>
    //     </a>,
    // },

    {
        title: "Website synchronisieren",
        icon: <i className="fa-solid fa-sync"></i>,
        url: "https://dashdesign.eu/entwicklung/web",
    },
    {
        title: "Support",
        icon: <i className="fa-solid fa-user-headset"></i>,
        uri: "/help"
    },
]