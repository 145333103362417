import React, { useEffect, useState } from "react"
import "./ZoomBox.scss"

function ZoomBox({ children }) {
    const [Zoom, setZoom] = useState(1)

    useEffect(() => {
        if (Zoom <= 0.5) {
            setZoom(0.5)
        }
        else if (Zoom >= 2) {
            setZoom(2)
        }
    }, [Zoom])

    return (
        <div className="ZoomBox">
            <div className="mb-3 selector">

                {/* <input
                    type="range"
                    min="0.25"
                    max="3"
                    value={Zoom}
                    onChange={(e) => setZoom(e.target.value)}
                    step={0.01}
                    className="slider" /> */}

                <small>
                    <i onClick={() => setZoom(Zoom - 0.1)} className="fa-solid fa-minus-circle" />
                    <span>{Math.round(Zoom * 100)}%</span>
                    <i onClick={() => setZoom(Zoom + 0.1)} className="fa-solid fa-circle-plus" />
                </small>


            </div>
            <div className="wrap" style={{ transform: `scale(${Zoom})` }}>
                {children}
            </div>
        </div >
    )
}
export default ZoomBox