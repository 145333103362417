import "./SaveButton.scss";

function SaveButton({ saved, checked, onSave }) {

    if (!saved) {
        window.onbeforeunload = function () {
            return "Mittelhessen Konsole schließen? Ihre Änderungen werden eventuell nicht gespeichert.";
        }
    } else {
        window.onbeforeunload = false
    }

    return (
        <div className="SaveButton">
            {saved ?
                <button className="saved">
                    <span className="d-none d-lg-inline">Gepeichert</span>
                    <i className="fa-solid fa-check "></i>
                </button>
                :
                <button onClick={onSave}>
                    <span className="">Speichern</span>
                    <i className="d-none d-lg-inline fa-solid fa-save "></i>
                </button>
            }

        </div>
    )
}
export default SaveButton