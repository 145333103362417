import axios from 'axios';
import React from 'react';
import { Helmet } from 'react-helmet';
import Config from '../../const/Config';
import { getToken } from '../../functions/auth';
import "./AdminManageVersions.scss";

export class AdminManageVersions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            versionList: [],
            versionText: ""
        }
    }
    componentWillMount
    async componentDidMount() {
        const config = { headers: { 'Authorization': getToken() } };

        axios.get(Config.ServerAddress + "/admin/getDisabledVersions", config)
            .then(result => {
                this.setState({ versionList: result.data });
            })
            .catch(err => {
                console.log("Fehler beim laden der Versionen: ", err)
                alert("Fehler beim Laden der Versionen.")
            })

    }


    onEntryClick = (index) => {
        let versionList = this.state.versionList;

        versionList[index].isActive = !versionList[index].isActive;

        this.setState({ versionList }, async () => {
            const config = { headers: { 'Authorization': getToken() } };

            await axios.post(Config.ServerAddress + "/admin/editDisabledVersion", this.state.versionList[index], config);
        });
    }

    onValueChange = (event) => {
        this.setState({ versionText: event.target.value });
    }

    onButtonClick = () => {
        const config = { headers: { 'Authorization': getToken() } };

        axios.post(Config.ServerAddress + "/admin/editDisabledVersion", { version: this.state.versionText, isActive: true }, config).then((res) => {
            this.componentDidMount();
        });

    }

    render() {
        return (
            <div className="AdminManageVersions">
                <Helmet>
                    <title>Versionen verwalten</title>
                </Helmet>
                <div className="container pt-5">
                    <h1>Versionen verwalten</h1>
                    <div>
                        <label>Version eintragen:</label>
                        <div className="d-flex flex-row">
                            <input value={this.state.versionText} onChange={this.onValueChange} />
                            <button onClick={this.onButtonClick}>Bestätigen</button>
                        </div>
                        <hr />
                    </div>
                    <table>
                        {
                            this.state.versionList.length == 0 ?
                                <h2>Lädt... Bitte warten.</h2> :
                                this.state.versionList.map((i, k) =>
                                    <tr className={i.isActive ? "entry isActive" : "entry"}>
                                        <th>
                                            <span>{i.version}</span>
                                        </th>
                                        <th>
                                            <small>{i.isActive ? "Zahlen sind nicht sichtbar" : "Zahlen sichtbar"}</small>
                                        </th>
                                        <th>
                                            {/* <i className="fa-solid fa-eye" /> <i className="fa-solid fa-eye-slash" /> */}
                                            <button onClick={() => this.onEntryClick(k)}>{i.isActive ? "Sichtbar" : "Verstecken"}</button>
                                        </th>
                                    </tr>
                                )
                        }
                    </table>
                </div>
            </div>);
    }
}