import axios from 'axios';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Config from '../../const/Config';

const NEW_PASSWORD_ENDPOINT = Config.ServerAddress + "/auth/newpassword"

function PageNewPassword() {

    const location = useLocation();
    let defaultMail = location.state == null ? "" : location.state.email;

    const [Sended, setSended] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [Email, setEmail] = useState(defaultMail);



    function hanldeSave() {
        setLoading(true)
        axios.post(NEW_PASSWORD_ENDPOINT, {
            email: Email,
        })
        setSended(true)
    }

    return (
        <div className="PageRegister">
            <div className="container">

                {Sended ?
                    <div>
                        <h1>Prüfen Sie Ihre Email's</h1>
                        <br />

                        <i style={{ fontSize: 128, margin: 0, }} className="fa-duotone fa-mail-bulk"></i>
                        <br />
                        <br />
                        <p>Wenn ein Benutzeraccount mit der E-Mail „{Email}“ hinterlget ist, senden wir Ihnen ein neues Passwort zu.
                            Wenn Sie die E-Mail nicht finden können, überprüfen Sie bitte den <b>Spam-Ordner</b>.</p>

                        <Link to="/login" onClick={() => setSended(false)}>Zurück zum Login</Link>
                    </div>
                    :
                    <div>
                        <h1>Passwort zurücksetzen</h1>
                        <p>Bitte geben Sie Ihr E-Mail-Adresse an:</p>
                        <input value={Email} onChange={(e) => setEmail(e.target.value)} placeholder="E-Mail"></input>
                        <button onClick={hanldeSave}>Mail Senden</button>
                    </div>
                }
            </div>
        </div >
        // <div>

        //     <input placeholder="Username oder Email"></input>
        //     <button>Mail Senden</button>


        //     <input placeholder="Code aus Email"></input>

        //     <input placeholder="Passwort" type="password"></input>
        //     <input placeholder="Passwort" type="password"></input>

        // </div>
    )
}

export default PageNewPassword
