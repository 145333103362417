
export const wichtig = [
    {
        title: "Anzeigename",
        type: "STRING",
        objKey: "general.title",
        example: "dashdesign;",
        isRequired: true,
        tip: "Tragen Sie hier den Namen ein, der angezeigt werden soll. Wir empfehlen, der Einfachheit halber, die Rechtsform hier wegzulassen."
    },
    {
        title: "Slogan / Untertitle",
        type: "STRING",
        objKey: "general.subtitle",
        isRequired: true,
    },
    {
        title: "Hauptkategorie",
        type: "DROPDOWN",
        objKey: "general.category",
        isRequired: true,
        // tip: "Die Schlagwörter helfen unserem System, Ihren Eintrag zu kategorisieren."
    },
    {
        title: "Sekundäre Kategorie",
        type: "DROPDOWN",
        objKey: "general.subCategory",
        // tip: "Die Schlagwörter helfen unserem System, Ihren Eintrag zu kategorisieren."
    },
    {
        title: "Schlagwörter",
        type: "HASHTAGS",
        objKey: "general.keywords",
        tip: "Die Schlagwörter helfen unserem System, Ihren Eintrag zu kategorisieren."
    },
    // {
    //     title: "Bilder",
    //     type: "IMAGE",
    //     objKey: "general.keywords",
    //     tip: ""
    // },
    {
        title: "Ausführliche Beschreibung",
        type: "TEXT",
        objKey: "general.description",
        tip: "Beschreiben Sie Ihr Unternehmen in einem ausführlichen Text. Wir empfehlen zwischen fünf und zwanzig Sätze."
    },

    {
        title: "Slug",
        type: "ID",
        objKey: "general.slug",
        tip: "Nutzername, muss einmalig sein"
    },
]

export const öffnungszeiten = [
    {
        title: "Zeiten anzeigen",
        type: "BOOL",
        objKey: "payload.openingTimes.visible",
    },
    {
        title: "Hinweis zu den Öffnungszeiten",
        type: "STRING",
        objKey: "payload.openingTimes.text",
    },
    {
        title: "Zeiten",
        type: "OPENING_HOURS",
        objKey: "payload.openingTimes.hours",
    },

    {
        title: "Wetter",
        type: "WEATHER",
        objKey: "payload.openingTimes.weather",
    },
]

export const kontaktdaten = [
    {
        title: "Anschrift",
        tip: <>Sie möchten mehrere Standorte hinzufügen? Legen Sie bitte für jeden Standort einen neuen Eintrag an.</>,
        //todo: Link einbetten (DATEN gehen verloren) tip: <>Sie möchten mehrere öffentliche Standort hinzufügen? Legen Sie bitte für jeden Standort einen <Link to="/neuer-eintrag">neuen Eintrag</Link> an.</>,
        type: "ADDRESS",
        objKey: "payload.address.street",
    },

    {
        title: "Telefonnummer",
        type: "PHONE",
        objKey: "general.contact.phone",
    },
    {
        title: "Website",
        type: "URL",
        objKey: "general.contact.website",
    },
    {
        title: "Email-Adresse",
        type: "MAIL",
        objKey: "general.contact.mail",
    },
    {
        title: "Termine",
        type: "URL",
        objKey: "general.contact.meeting",
        tip: "Verlinken Sie Ihr Termin-Buchungs-System, wie z.B.: Calendly",
        example: "https://calendly.com/dashdesign-eu",
    },



    {
        title: "",
        type: "LINKS",
        objKey: "payload.otherLinks",
    },

]

export const extras = [

    {
        title: "Speisekarte",
        type: "URL",
        objKey: "general.contact.menu",
        tip: <>Verlinken Sie direkt auf eine zentrale PDF-Datei oder Website. Wenn Sie sicherstellen, dass die angegebene URL immer aktuell bleibt, müssen Sie dann nur noch die Speisekarte auf Ihrer Website aktualisieren und sparen zusätzliche Arbeit.</>,
    },
    {
        title: "360° Rundgang",
        type: "URL",
        objKey: "payload.virtualTour.url",
        tip: <>Matterport-Rundgänge werden direkt in der App angezeigt. <a href="https://diestadt.app/2021/06/22/einbinden-eines-360-rundganges/" target="_blank">Mehr erfahren</a></>,
    },
    {
        title: "Bewertung",
        type: "OBJECT",
        objKey: "payload.feedback",
        typePayload: {
            objectKeys: [

                {
                    title: "Bewertung (1-5 Sterne)",
                    type: "INT",
                    key: "amount",
                    typePayload: { min: 0, max: 5, step: 0.1, },
                    // tip: "",
                    example: "4,8"
                },
                {
                    title: "Quelle",
                    type: "STRING",
                    key: "sourceType",
                    example: "Google Maps"
                    // tip: "",
                },
                {
                    title: "Quell-URL",
                    type: "URL",
                    key: "url",
                    required: true,
                    example: "Google Maps"
                    // tip: "",
                },
            ]
        },
        tip: <>Die Bewertung wird nicht automatisch synchronisiert.</>,
    },
    {
        title: "Mindestalter",
        type: "INT",
        objKey: "general.requiredAge",
        tip: <>Die Angabe ist freiwillig und Hilft Nutzern.</>,
    },
]

export const rechtliches = [
    {
        title: "Offizieller Name",
        type: "STRING",
        objKey: "payload.legal.legalName",
        tip: "Tragen Sie hier den vollständigen Namen (inkl. Rechtsform!) ein."
    },
    // {
    //     title: "Inhaber",
    //     type: "STRING",
    //     objKey: "XXXX",
    //     tip: "Trgane Sie hier den vollständigen Namen (inkl. Rechtsform) ein."
    // },
    {
        title: "Impressum",
        type: "URL",
        objKey: "payload.legal.lawUrl",
        tip: "Gegebenenfalls benötigen Sie ein Impressum. Sprechen Sie uns im Zweifelsfall mit Ihrem Rechtsberater."
    },
    // {
    //     title: "AGB",
    //     type: "PDF",
    //     objKey: "XXXX",
    // },
]

export const design = [
    {
        title: "Hauptfarbe",
        type: "COLOR",
        objKey: "payload.theme.mainColor",
        typePayload: { dark: "#263379", light: "#263379" },
        levelRequired: 2, //Normal, Premium, Premium+
    },
    {
        title: "Sekundärfarbe",
        type: "COLOR",
        objKey: "payload.theme.subColor",
        tip: "",
        levelRequired: 2, //Normal, Premium, Premium+
    },
    {
        title: "Überschrift Farbe",
        type: "COLOR",
        objKey: "payload.theme.headingFarbe",
        tip: "",
        levelRequired: 2, //Normal, Premium, Premium+
    },
    {
        title: "Text Farbe",
        type: "COLOR",
        objKey: "payload.theme.textColor",
        tip: "",
        levelRequired: 2, //Normal, Premium, Premium+
    },
    {
        title: "Hintergrund Farbe",
        type: "COLOR",
        objKey: "payload.theme.backgroundColor",
        tip: "",
        levelRequired: 2, //Normal, Premium, Premium+
    },
]

export const sync = [
    //     {
    //         title: "", //sowas gibnt es nicht, hier gehts um Werbung
    //         type: "STRING",
    //         objKey: "XXXX",
    //         tip: "Sie haben noch keinen DWAT? Sprechen Sie gerne Ihren Website-Ansrechpartner an.",
    //     },
]

export const corona = [
    {
        title: "Regelung",
        type: "SELECT_STRING",
        typePayload: { items: ["2G+", "2G", "3G", "Keine Einschränkungen", "2G++", "2G+PCR",] },
        objKey: "payload.corona.rule",
    },
    {
        title: "Maximale Personenzahl",
        type: "INT",
        objKey: "payload.corona.maxPersons",
    },
    {
        title: "Maskenpflicht",
        type: "SELECT_STRING",
        typePayload: { custom: true, items: ["FFP2/KN95-Maskenpflicht", "Medizinische-Maskenpflicht", "Kein Schutzmaske benötigt"] },
        objKey: "payload.corona.mask",
    },
    {
        title: "Weiterer Hinweis zur Pandemiebekämpfung",
        type: "STRING",
        objKey: "payload.corona.text",
    },
]
export const team = [
    {
        title: "Überschrift",
        type: "SELECT_STRING",
        typePayload: { custom: true, items: ["Unser Team", "Ansprechpartner:innen", "Kontaktpersonen", "Vorstand"] },
        tip: "",
        objKey: "payload.team.title",
    },
    {
        title: "Personen",
        type: "OBJECT_LIST",
        objKey: "payload.team.persons",
        typePayload: {
            className: "ProductInputList",
            objectKeys: [
                {
                    title: "Name*",
                    type: "STRING",
                    key: "name",
                    required: true,
                    example: "Konrad Mayer"
                    // tip: "",
                },
                {
                    title: "Position / Bereich",
                    type: "STRING",
                    key: "jobTitle",
                    required: true,
                    example: "Gründer & Geschäftsführer"
                    // tip: "",
                },
                {
                    title: "Beschreibung",
                    type: "TEXT",
                    key: "description",
                    placeholder: "Beschreiben Sie die Aufgaben dieser Person in 1-2 Sätzen."
                    // tip: "",
                },
                {
                    title: "Foto",
                    type: "IMAGE",
                    key: "description",
                    example: "Ob Smartphone-App, Desktop-App, KI oder eine Serveranwendung – mit unserem jungen, motivierten Team sind wir der optimale Partner um Ihre App Idee zu realisieren."
                    // tip: "",
                },
                //Todo: CONTACT
            ]
        }
    },
]
export const products = [
    {
        title: "Überschrift",
        type: "SELECT_STRING",
        typePayload: { custom: true, items: ["Produkte", "Diensleistungen", "Angebot"] },
        objKey: "payload.products.title",
    },
    {
        title: "Angebote",
        type: "OBJECT_LIST",
        objKey: "payload.products.items",
        typePayload: {
            className: "ProductInputList",
            objectKeys: [
                {
                    title: "Icon",
                    type: "ICON",
                    key: "icon",
                    // tip: "",
                },
                {
                    title: "Titel",
                    type: "STRING",
                    key: "title",
                    required: true,
                    example: "App-Entwicklung"
                    // tip: "",
                },
                {
                    title: "Beschreibung",
                    type: "TEXT",
                    key: "description",
                    example: "Ob Smartphone-App, Desktop-App, KI oder eine Serveranwendung – mit unserem jungen, motivierten Team sind wir der optimale Partner um Ihre App Idee zu realisieren."
                    // tip: "",
                },
                {
                    title: "Vorteile",
                    type: "HASHTAGS",
                    key: "benefits",
                    example: "Kostengünstig, Schickes Design, uws."
                    // tip: "",
                },
                {
                    title: "Mehr Informationen URL",
                    type: "URL",
                    key: "url",
                    example: "z.B.: https://dashdesign.eu/entwicklung/app",
                    tip: "Wenn ein User Interesse an einer Dienstleistung hat, wir die hier angegebene URL geöffnet.",
                },

            ]
        }
    },
]