import "./DashdesignBackendLogo.scss"
import MainLogo from "./MainLogo/MainLogo"

function DashdesignBackendLogo({ }) {
    return (
        <div className="DashdesignBackendLogo">
            <MainLogo />
            <span className="lowTitle">Konsole</span>
        </div>
    )
}
export default DashdesignBackendLogo