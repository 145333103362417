
import { useEffect, useState } from "react"
import { Route, Switch, useParams } from 'react-router-dom'
import SideBar from "../../components/bars/SideBar/SideBar"
import SaveButton from "../../components/defaults/buttons/SaveButton"
import Emulator from '../../components/defaults/emulator/Emulator'
import FieldList from "../../components/entry/FieldList"
import { categorys } from "../../const/Editor/categorys"
import { logout } from "../../functions/auth"
import { loadEntryDataById, modifyEntryDataById } from "../../functions/entry"
import "./EntryEditHandler.scss"

function EntryEditHandler() {
    let { EntryID } = useParams()

    const [Data, setData] = useState(null)
    const [OldData, setOldData] = useState(null)
    const [Error, setError] = useState(false)
    const [MobileShowMenu, setMobileShowMenu] = useState(false)
    const [MobileShowEmulator, setMobileShowEmulator] = useState(false)
    const [Rerender, setRerender] = useState(0)
    const [Saved, setSaved] = useState(true)

    const [extraData, setExtraData] = useState({});

    useEffect(() => {
        console.log("load entry data " + EntryID)
        update();
    }, [])

    function handleChange(newEntry) {
        console.log("handleChange", newEntry)
        setData(newEntry)
        setRerender(Rerender + 1)
        setSaved(false)
    }

    function update() {
        loadEntryDataById(EntryID)
            .then((res) => {
                console.log("Entry geladen")
                setData(res)
                setOldData(res)
            })
            .catch((err) => {
                if (err.response?.status == 401) logout(true)
                alert("Fehler beim laden")
                setError(true)
                console.log(err)
            })
    }

    function handleSave() {
        modifyEntryDataById(EntryID, Data)
            .then(() => {
                setSaved(true)
                setOldData(Data)
            })
            .catch((err) => {
                if (err.response?.status == 401) logout(true)
                alert("Die Änderungen wurden nicht gespeichert!")
            })

    }

    // function isSaved() {
    //     // return !Object.is(Data, OldData)
    //     // console.log("isSaved NEW: " + Data.general.title)
    //     // console.log("isSaved OLD: " + OldData.general.title)
    //     // let res = JSON.stringify(Data) == JSON.stringify(OldData)
    //     return Saved
    // }

    function handleClick() {
        setMobileShowMenu(false)
    }

    if (!Data) return <p>Lädt Daten....</p>
    if (Error) return <p>Fehler beim laden</p>
    return (
        <Switch>
            <div>

                {categorys.map((j, k) => {
                    return j.items.map((i, key) => {
                        let path = `/entry/:EntryID${i.uri}`
                        const Component = i.component;


                        return (
                            <Route key={`${k}-${key}`} path={path}>
                                <div className="EntryEditHandler container-lg">

                                    <div className="d-block d-md-flex flex-row ">

                                        {/* <div>
                                            <button>kejirj</button>
                                        </div> */}

                                        <div className="col w-100 ">

                                            <div className="header">

                                                <div className="d-flex align-items-end justify-content-between p-4">
                                                    <div className="d-flex align-items-center ">
                                                        {/* <img className="entryLogo" src={Data.payload?.images[0]?.url} /> */}
                                                        <h1 className="mb-0 mt-3">{Data.general.title}</h1>
                                                    </div>

                                                    <SaveButton onSave={handleSave} saved={Saved} checked={false} />

                                                </div>

                                                <div className="d-flex d-lg-none  justify-content-between mobileButtons">
                                                    <div className="" onClick={() => setMobileShowMenu(!MobileShowMenu)}>
                                                        {MobileShowMenu ?
                                                            <i className="fa-solid fa-times" />
                                                            :
                                                            <i className="fa-solid fa-bars" />
                                                        }
                                                    </div>

                                                    <div className="d-block d-md-none " onClick={() => setMobileShowEmulator(!MobileShowEmulator)}>
                                                        {MobileShowEmulator ?
                                                            <i className="fa-solid fa-chevron-left" />
                                                            :
                                                            <i className="fa-solid fa-mobile" />
                                                        }
                                                    </div>

                                                </div>
                                            </div>



                                            <div className="d-flex bd-highlight w-100">
                                                <div className="col-12 p-2 bd-highlight mt-3">
                                                    <div className="d-block d-lg-flex">
                                                        <div className={MobileShowMenu ? "d-block" : "d-none d-lg-block"}>
                                                            <SideBar
                                                                onClick={handleClick}
                                                                preUri={`/entry/${EntryID}`}
                                                                content={categorys}
                                                            />
                                                        </div>
                                                        <div className="w-100 page">
                                                            {i.component ?
                                                                <Component
                                                                    entryData={Data}
                                                                    extraData={extraData}
                                                                    setExtraData={setExtraData}
                                                                    update={update}
                                                                    changedCallback={(d) => { console.log("EDIT "); console.log(d) }}
                                                                    key={`${k}-${key}`}
                                                                />
                                                                //<ClonedElementWithMoreProps entryData={Data} {...i} key={`${k}-${key}`} />
                                                                :
                                                                <FieldList entryData={Data} {...i} idd={`${k}-${key}`} key={`${k}-${key}`} onChange={(e) => handleChange(e)} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                        {i.hideEmulator &&
                                            <div className="pt-4 flex-shrink-1 ps-2">
                                                <div className="px-3">
                                                    <small>
                                                        <a target="_blank" href={"https://mittelhessen.app/" + Data.general.slug}>Im Web öffnen</a>
                                                    </small>
                                                </div>
                                                <Emulator
                                                    live={true}
                                                    emulator={i.emulator}
                                                    extraData={extraData}
                                                    mobileShowEmulator={MobileShowEmulator}
                                                    onNavigateBack={() => setMobileShowEmulator(false)}
                                                    entryData={Data} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </Route>
                        )
                    })
                })}
            </div>

        </Switch >
    )
}
export default EntryEditHandler