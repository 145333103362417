import Tile from '../../components/menu/Tile'

function AdminMenu() {
    return (
        <div className="container mt-5">
            <h1>Admin</h1>
            {adminTiles.map((i, k) =>
                <div key={k}>
                    <b>{i.title}</b>
                    <div className="d-flex flex-row p-2 mb-4">
                        {i.items.map((ii, kk) =>
                            <Tile {...ii} key={kk} />
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}
export default AdminMenu

const adminTiles = [

    {
        title: "Wartung:",
        items: [
            {
                title: "Versionen",
                uri: "/admin/versions/",
                icon: <i className="fa-duotone fa-toggle-on"></i>,
            },
            {
                title: "Corona-Zahlen",
                uri: "/admin/corona/",
                icon: <i className="fa-duotone fa-virus"></i>,
            },
            {
                title: "Tags",
                uri: "/admin/tags/",
                icon: <i className="fa-duotone fa-tags"></i>,
            },
            {
                title: "Notifications",
                uri: "/admin/sendNotification/",
                icon: <i className="fa-duotone fa-bell"></i>,
            },
        ],
    },
    {
        title: "Support:",
        items: [
            {
                title: "Standard Mails",
                uri: "/admin/mails/",
                icon: <i className="fa-duotone fa-paper-plane-top"></i>,
            },
            {
                title: "Accounts",
                uri: "/admin/accounts/",
                icon: <i className="fa-duotone fa-users"></i>,
            },
        ],
    },
    {
        title: "Kommunen",
        items: [
            {
                title: "Kacheln",
                uri: "/admin/city/tiles",
                icon: <i className="fa-duotone fa-list"></i>,
            },
        ],
    },
    // {
    //     title: "Kommunen",
    //     items: [
    //         {
    //             title: "Neue App",
    //             uri: "/admin/city/add",
    //             icon: <i className="fa-duotone fa-circle-plus"></i>,
    //         },
    //         {
    //             title: "Aktuelle Apps",
    //             uri: "/admin/city/list",
    //             icon: <i className="fa-duotone fa-list"></i>,
    //         },
    //     ],
    // },
    // {
    //     title: "Externe Links:",
    //     items: [
    //         {
    //             title: "Rechnungen",
    //             url: "https://my.sevdesk.de/#/fi/RE",
    //             icon: <i className="fa-duotone fa-file-invoice-dollar"></i>,
    //         },
    //     ],
    // },
]