import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { getAbbreviation } from '../../../functions/auth'
import ProfileMenu from './ProfileMenu'
import "./TitleBar.scss"

function TitleBar({ showBackButton }) {

    const [MenuOpen, setMenuOpen] = useState(false)

    return (
        <div className="TitleBar">
            <div
                className=" d-flex flex-row align-items-center justify-content-between">
                <Link to="/">
                    <img className="mainLogo" src={process.env.PUBLIC_URL + "/assets/image/logos/mittelhessen.app_konsole_small.png"}></img>
                </Link>

                <div className="pb" onClick={() => setMenuOpen(!MenuOpen)}>
                    <span>{getAbbreviation()}</span>
                </div>
            </div>


            <ProfileMenu isOpen={MenuOpen} />
        </div >
    )
}

export default TitleBar
