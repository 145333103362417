import { useState } from "react";
import { getIcon } from "../../../../../const/ContactOptions";
import IconHeading from "../../../text/IconHeading";
import SubPage from "../../components/SubPage";
import "./EntryPage_EmulatorScreen.scss";
import Corona from "./boxes/Corona";
import Description from "./boxes/Description";
import OpeningTimes from "./boxes/OpeningTimes";
function EntryPage_EmulatorScreen({ entryData, onNavigateBack }) {
    return (
        <div className="EntryPage_EmulatorScreen">
            <SubPage onNavigateBack={onNavigateBack} title={entryData?.general?.title}>

                <ImageCarousel entryData={entryData} />
                <div className="cont">
                    <div className="titleContainer">
                        <h2>{entryData?.general?.title}</h2>
                        <small>{entryData?.general?.subtitle || "Neu in der App!"}</small>
                    </div>
                    <Areas entryData={entryData} />
                    {/* <FastButtons entryData={entryData} />
                    <EntryDetails entryData={entryData} /> */}
                </div>
            </SubPage>
        </div>
    )
}
export default EntryPage_EmulatorScreen

function Areas({ entryData }) {
    const [CurrentArea, setCurrentArea] = useState(0)

    return (<div className="Areas">
        <div className="d-flex justify-content-around">
            {areas.map((i, k) =>
                <div
                    onClick={() => setCurrentArea(k)}
                    className={CurrentArea == k ? "titleItem isActive" : "titleItem"}
                >
                    {i.title}
                </div>
            )}
        </div>

        {/* Area Content  */}
        <div className="areaContent">
            {areas[CurrentArea].content.map((Next, k) => {
                return (
                    <Next entryData={entryData} />
                )
            })}
        </div>
    </div>)
}

const areas = [
    {
        title: "Kontakt",
        content: [FastButtons, PlaceOnMap] // ContactForm, PlaceOnMap,
    },
    {
        title: "Aktuell",
        // Gutscheine, News
        content: [Corona, OpeningTimes] //CoronaRules, OpeningTimes
    },
    {

        title: "Über",
        content: [HashtagCloud, Description] //ProductBox, Description, Feedback,
    },

    {
        title: "Mehr",
        // 360°
        content: [] //[ContactPersons, PDFs, SubEntries,]
    },
]

function HashtagCloud({ entryData }) {
    let keywords = entryData.general?.keywords
    return (
        <div className="style">
            <IconHeading icon="fa-solid fa-hashtag" >Schlagwörter</IconHeading>
            {keywords && keywords != [] ?
                <div className="d-flex flex-wrap">
                    {keywords.map((i, k) =>
                        <small>#{i}</small>
                    )}
                </div>
                :
                <span>Keine Schlagwörter verfügbar.</span>
            }
        </div>
    )

}

function PlaceOnMap({ entryData }) {

    return (
        <div className="p-3">
            <b>Anschrift</b>
            <p>{entryData?.payload?.address?.street || "Keine Anschrift verknüpft."}</p>
        </div>
    )
}


function FastButtons({ entryData }) {

    let data = entryData.payload?.otherLinks || []
    let contactData = entryData?.general?.contact || {}


    return (
        <div className="FastButtons">
            {
                contactData.phone ?
                    <IconHeading icon={"fas fa-phone"}>
                        <h3 className="url">{contactData.phone}</h3>
                    </IconHeading>
                    : null}
            {
                contactData.mail ?
                    <IconHeading icon={"fas fa-envelope"}>
                        <h3 className="url">{contactData.mail}</h3>
                    </IconHeading>
                    : null}
            {
                contactData.website ?
                    <IconHeading icon={"fab fa-safari"}>
                        <a href={contactData.website} target="_blank">
                            <h3 className="url">{contactData.website.replace("https://", "")}</h3>
                        </a>
                    </IconHeading>
                    : null}
            {
                contactData.meeting ?
                    <a href={contactData.meeting} target="_blank">
                        <IconHeading icon={"fas fa-calendar"}>
                            <h3 className="url">Termin vereinbaren</h3>
                        </IconHeading>
                    </a>
                    : null}


            {data.map((i, k) =>
                <IconHeading icon={getIcon(i.contactType)} key={k}>
                    <h3 className="url">{i.displayTitle || i.value.replace("https://", "")}</h3>
                </IconHeading>
            )}

        </div>
    )
}




function ImageCarousel({ entryData }) {
    let images = entryData?.payload?.images || []

    return (<div className="ImageCarousel">
        <div className="carousel slide" data-bs-touch="true" data-bs-ride="carousel">
            <div className="carousel-inner">
                {images.sort((a, b) => b.imageOrder - a.imageOrder).map((i, k) =>
                    <div className={(k == 0 ? "active " : null) + " carousel-item"}>
                        <img src={i.url} className="d-block w-100" alt="..."></img>
                    </div>
                )}
            </div>
        </div>
    </div>
    )
}