import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import CropperComponent from '../../../pages/editor/Images/CropperComponent';
import Config from '../../../const/Config';
import "./ImageInput.scss";

export default class ImageInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            displayModal: false,
            imageUrl: props.value,
            uploading: false
        }
    }


    toggleModal = () => this.setState({ displayModal: !this.state.displayModal });

    onUploadSuccess = (publicID) => {
        let url = Config.CDNAddress + "/image?publicId=" + publicID;
        this.setState({ displayModal: false, imageUrl: url });
        this.props.onChange(url);
    }

    render() {
        return (
            <div className="ImageInput">
                <button onClick={this.toggleModal}>
                    <i className="fa-solid fa-file-upload"></i>
                    <span>Bild hinzufügen</span>
                </button>


                <img style={{ display: "block", maxHeight: 128, overflow: "hidden", background: "var(--backgroundSub)", marginBottom: 16, marginTop: 8 }} src={this.state.imageUrl} />
                <Modal isOpen={this.state.displayModal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>Bild hochladen</ModalHeader>
                    <ModalBody>
                        <CropperComponent
                            imageTypeID={3}
                            onUploadSuccess={this.onUploadSuccess} />
                    </ModalBody>

                </Modal>
            </div>);
    }
}