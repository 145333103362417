import React, { useEffect, useState } from "react"
import IconHeading from "../../../../text/IconHeading"
import "./Description.scss"

function Description({ entryData }) {
    return (
        <div className="Description style">
            <IconHeading icon="fa-solid fa-comment-lines">Beschreibung</IconHeading>
            <p>{entryData?.general?.description || "Keine Beschreibung verfügbar."}</p>
        </div>
    )
}
export default Description