import React, { useEffect, useState } from "react";
import "./LoadingPage.scss";

function LoadingPage({ text = "Lädt", enableBlink = true, blinkDelay = 500, desc }) {

    const [Points, setPoints] = useState("..")
    const [ShowDesc, setShowDesc] = useState(false)

    useEffect(() => {

        setTimeout(() => {
            if (desc) setShowDesc(true)
        }, 3000)

        const interval = setInterval(() => {
            if (Points) {
                setPoints("")
            } else {
                setPoints(Points + ".")
            }
        }, blinkDelay);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="LoadingPage">
            <div className="spinner"></div>
            <span className="text">{text} {enableBlink ? Points : null}</span>
            <span className="desc"> {ShowDesc ? desc : ""}</span>
        </div>
    )
}
export default LoadingPage