import { Route, Switch } from 'react-router'
import { AdminAddCoronaEntry } from '../pages/admin/AdminAddCoronaEntry'
import AdminEditUser from '../pages/admin/AdminEditUser'
import AdminEntryTags from '../pages/admin/AdminEntryTags'
import { AdminManageVersions } from '../pages/admin/AdminManageVersions'
import AdminMenu from '../pages/admin/AdminMenu'
import AdminSendNotification from '../pages/admin/AdminSendNotification'
import AdminUserList from '../pages/admin/AdminUserList'
import AdminCityAdd from '../pages/admin/city/AdminCityAdd'
import AdminCityDistricts from '../pages/admin/city/AdminCityDistricts'
import AdminCityList from '../pages/admin/city/AdminCityList'
import AdminCityTiles from '../pages/admin/city/AdminCityTiles'
import AdminSupportEmail from '../pages/admin/support/AdminSupportEmail'

function AdminSwitch() {
    return (
        <Switch>
            <Route path="/admin/" exact>
                <AdminMenu />
            </Route>

            <Route path="/admin/city/add" exact>
                <AdminCityAdd />
            </Route>
            <Route path="/admin/city/list" exact>
                <AdminCityList />
            </Route>

            <Route path="/admin/city/:appSlug/districts" exact>
                <AdminCityDistricts />
            </Route>
            <Route path="/admin/city/tiles" exact>
                <AdminCityTiles />
            </Route>



            <Route path="/admin/tags" exact>
                <AdminEntryTags />
            </Route>


            <Route path="/admin/accounts" exact>
                <AdminUserList />
            </Route>
            <Route path="/admin/mails" exact>
                <AdminSupportEmail />
            </Route>


            <Route path="/admin/user/:uuid">
                <AdminEditUser />
            </Route>
            <Route path="/admin/corona/">
                <AdminAddCoronaEntry />
            </Route>
            <Route path="/admin/versions/">
                <AdminManageVersions />
            </Route>

            <Route path="/admin/sendNotification/">
                <AdminSendNotification />
            </Route>
        </Switch>
    )
}

export default AdminSwitch
