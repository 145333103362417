import { useState } from "react";
import CodeBox from "../../../components/defaults/boxes/CodeBox";
import IconComponent from "../../../components/defaults/icons/IconComponent";
import WebsiteInput from "../../../components/defaults/inputs/WebsiteInput";
import RegionPicker from "../../../components/picker/RegionPicker";
import { Icon, IconGroup } from "../../../types/Icon";
import "./AdminCityTiles.scss";

interface Tile {
  page: Page;
  icon: Icon;
  title: String;
  position?: Number;
  isActive?: Boolean;
}

interface Page {
  title: String;
  uri: Pages;
  payload: Object;
}
enum Pages {
  ListEntriesPage,
  FlyerPage,
  WebViewPage,
}

const demoTiles: Tile[] = [
  {
    title: "Unsere Flyer",
    page: {
      title: "Flyer",
      uri: Pages.FlyerPage,
      payload: {},
    },
    icon: {
      group: IconGroup.normal,
      name: "file-pdf",
    },
    position: 1,
  },
];

function AdminCityTiles({}) {
  const [Tiles, setTiles] = useState(demoTiles);

  function AddTile() {}

  return (
    <div className="AdminCityTiles pt-5">
      <div className="container">
        <div>
          <h1>Kachelen anpassen</h1>
          <RegionPicker prefix={"In"} />{" "}
          {/* Achtung, das Ding hat noch keine Logik -> Hier sollte Standardmäßig ein Wert ausgewählt sein  */}
          <hr></hr>
        </div>

        <h3>Aktive Kacheln:</h3>
        {Tiles.map((t, k) => (
          <RenderTile {...t} key={k} />
        ))}
      </div>
    </div>
  );
}
export default AdminCityTiles;

function RenderTile(props: Tile) {
  const [IsOpen, setIsOpen] = useState(false);
  const toggleIsOpen = () => setIsOpen(!IsOpen);

  return (
    <div className="Tile">
      <div className="inner" onClick={toggleIsOpen}>
        <IconComponent {...props.icon} />
        <span className="title">{props.title}</span>
      </div>
      {IsOpen ? (
        <div>
          <hr></hr>
          <p>
            <b>Seite:</b> Flyer
          </p>
          {/*  */}
          <div>
            <p>
              <b>Webview Page:</b>
            </p>
            <p>URL</p>
            <WebsiteInput value="https://lego.de" />
            <div>
              <p>Custom CSS (optional)</p>
              <CodeBox children={<>{`body {}`}</>} />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
