import React, { useState } from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { Icons } from "../../../const/Data/Icons"
import "./IconInput.scss"

const DEFAULT_ICON_TYPE = "fa-solid"

function IconInput({ value = {}, setNewValue, onChange }) {
    const [IsOpen, setIsOpen] = useState(false)
    const [Search, setSearch] = useState("")
    let { iconName, iconType } = value

    console.log("iconName  " + JSON.stringify(iconName))

    function selectIcon(iconName) {
        let newValue = { iconName: iconName, iconType: DEFAULT_ICON_TYPE }

        console.log("newIcons Name: ", iconName)
        onChange({ target: { value: newValue } })
        // setNewValue(newValue)
    }




    return (
        <div className="IconInput">

            <div onClick={setIsOpen}>
                {iconName ?
                    <div className="d-flex flex-row  align-items-center">
                        <i className={`fa-solid fa-${iconName}`} />
                        <span className="change" > Icon ändern</span>
                    </div>
                    :
                    <span className="change" > Icon wählen</span>
                }
            </div>

            <Modal isOpen={IsOpen} toggle={() => setIsOpen(false)}>
                <ModalHeader toggle={() => setIsOpen(false)}>Icon auswählen</ModalHeader>
                <ModalBody>
                    <div className="topIcons">
                        <input
                            value={Search}
                            onChange={e => setSearch(e.target.value)}
                            placeholder="Suchbegriff eingeben (auf Englisch)"
                            className="mb-4" />


                        {topIcons.filter(i => i.includes(Search)).map((i, k) =>
                            <button className="iconButton" onClick={() => { selectIcon(i); setIsOpen(false) }}>
                                <i title={i} className={`fa-solid fa-${i}`} key={k} />
                            </button>
                        )}
                    </div>
                </ModalBody>

            </Modal>

            {/* <div className="topIcons">
                {topIcons.map((i, k) =>
                    <button onClick={(i) => selectIcon(i)}>
                        <i title={i} className={`fa-solid fa-${i}`} key={k} />
                    </button>
                )}
            </div> */}
        </div >
    )
}
export default IconInput

let topIcons = Icons