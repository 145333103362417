import axios from 'axios';
import querystring from 'querystring';
import React, { createRef } from 'react';
import Cropper from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { withRouter } from 'react-router';
import Config from '../../../const/Config';
import "./CropperComponent.scss";

const cdnAddress = Config.CDNAddress;

class CropperComponent extends React.Component {

    fileInputRef;

    constructor(props) {
        super(props);
        this.state = {
            error: {
                status: "NA",
                msg: "",
                critical: false
            },
            isLoading: true,
            image: null,
            dimensions: {
                width: window.innerWidth,
                height: window.innerHeight
            },
            crop: {},
            message: {},
            scale: 0,
            imageType: {},
            uploading: false
        };
        this.fileInputRef = createRef();
        this.updatedCropRef = createRef();
        this.cropperRef = createRef();
        this.croppedImageRef = createRef();

    }

    async componentDidMount() {
        window.addEventListener("resize", this.handleResize, false);

        if (this.state.isLoading) {
            const query = {
                imageTypeId: this.props.imageTypeID
            }
            try {
                let res = await axios.get(cdnAddress + "/imagetype?" + querystring.stringify(query))
                if (res.data.status) {
                    this.setState({ error: { status: "INTERNAL_ISSUE", msg: "Interne Serverprobleme, bitte kontaktieren Sie den Support.", critical: true } })
                }
                if (res.data.imageTypeID) {
                    this.updateImageType(res.data);
                    console.log(res.data)
                }
            } catch (error) {
                this.setState({ error: { status: "INTERNAL_ISSUE", msg: "Interne Serverprobleme, bitte kontaktieren Sie den Support.", critical: true } })

            }

        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { crop, scale, imageType } = this.state;
        if (prevState.dimensions != this.state.dimensions) {
            if (this.croppedImageRef.current) {
                let newScale = this.calcScale();
                if (scale != newScale) {
                    this.setState({
                        scale: newScale, crop: {
                            aspect: imageType.ratioWidth / imageType.ratioHeight,
                            x: crop.x * scale / newScale,
                            y: crop.y * scale / newScale,
                            width: crop.width * scale / newScale
                        }
                    });
                }
            }
        }
    }

    calcScale() {
        if (this.croppedImageRef.current) {
            return this.croppedImageRef.current.naturalWidth / this.croppedImageRef.current.width;
        }
        return 0;
    }

    updateImageType = (imageType) => {

        this.setState({
            imageType, isLoading: false, crop: {
                aspect: imageType.ratioWidth / imageType.ratioHeight
            }
        });
    }

    onFileSelected = (e) => {
        const { imageType } = this.state;
        if (e.target.files && e.target.files.length > 0) {

            let img = new Image();
            img.src = window.URL.createObjectURL(e.target.files[0]);
            img.onload = () => {
                if (img.width >= imageType.minWidth && img.height >= imageType.minHeight) {
                    const reader = new FileReader();
                    reader.addEventListener('load', () => this.setState({ image: reader.result }));

                    reader.readAsDataURL(e.target.files[0]);
                    this.setState({ error: { status: 'NA', msg: '', critical: false } });
                }
                else {
                    console.log("Error: Datei zu klein");
                    this.setState({ error: { status: 'IMG_SIZE', msg: `Das Bild ist zu klein. Es muss mindestens die Maße ${imageType.minWidth}px / ${imageType.minHeight}px haben.`, critical: false } });
                }
            }
        }
        console.log("test");
    }

    getCroppedImg(image, crop, fileName, imageType) {
        const canvas = document.createElement("canvas");
        let scale = image.naturalWidth / image.width;

        let roundedWidth = Math.ceil(crop.width * scale / imageType.ratioWidth) * imageType.ratioWidth;
        let roundedHeight = roundedWidth / imageType.ratioWidth * imageType.ratioHeight;
        if (roundedWidth > imageType.maxWidth) {
            roundedWidth = imageType.maxWidth;
            roundedHeight = imageType.maxHeight;
        }

        console.log(image.width)
        console.log(image.naturalWidth)
        console.log(roundedHeight)

        canvas.width = roundedWidth || image.width;
        canvas.height = roundedHeight || image.height;
        const ctx = canvas.getContext("2d");


        // New lines to be added
        const pixelRatio = window.devicePixelRatio;
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";

        ctx.drawImage(
            image,
            crop.x * scale,
            crop.y * scale,
            crop.width * scale * pixelRatio,
            crop.height * scale * pixelRatio,
            0,
            0,
            canvas.width,
            canvas.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                blob.name = fileName;
                resolve(blob);
            }, "image/jpeg", 1);
        });
    }

    onLoad = (image) => {
        const { imageType } = this.state;
        this.croppedImageRef.current = image;

        let newScale = this.calcScale();

        this.setState({ scale: newScale });
        this.updatedCropRef.current = { aspect: imageType.ratioWidth / imageType.ratioHeight, x: 0, y: 0, width: imageType.minWidth / newScale };
    }

    onAbort = () => {
        this.setState({ image: null })
    }

    onCrop = (newCrop) => {
        const { crop } = this.state;

        if (newCrop.width == this.croppedImageRef.width) {
            newCrop.height = this.croppedImageRef.width / crop.aspect;
        }
        if (this.updatedCropRef.current) {

            this.setState({ crop: this.updatedCropRef.current });
            this.updatedCropRef.current = null;
            return;
        }
        this.setState({ crop: newCrop });
    }

    handleResize = () => {
        this.setState({ dimensions: { width: window.innerWidth, height: window.innerHeight } });
    }

    onButtonClick = (e) => {
        const { crop, imageType, image } = this.state;
        let htmlImage = new Image();
        this.setState({ uploading: true });
        htmlImage.onload = async () => {
            let blob = await this.getCroppedImg(this.cropperRef.current.imageRef, crop, "cropped_img.jpg", imageType)

            console.log(blob)

            const formData = new FormData();
            formData.append("image", blob);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + sessionStorage.getItem("token")
                },
            };

            try {
                if (this.props.onUploadStart)
                    this.props.onUploadStart();

                let res = await axios.post(cdnAddress + "/image_upload?imgType=" + imageType.imageTypeID, formData, config)

                if (res.data && res.data.msg && res.data.msg == "Success") {
                    this.fileInputRef.current.value = "";
                    this.setState({ image: null, crop: {} });
                    this.props.onUploadSuccess(res.data.publicID);
                }
            } catch (error) {
                alert("Das Bild konnte aufgrund der größe leider nicht gespeichert werden.") //die Meldeung ist extra kacke geschreiben, damit ich erstmal sgane kann: "Okay dein Bild war zu klein", "Viel zu groß", "naja die Datei war viel zu riesig" 
                console.log(error);

            }
            this.setState({ uploading: false });



        }


        htmlImage.src = image;

    }

    render() {
        if (this.state.error.status != "NA" && this.state.error.critical) {
            return (<p className="error">123{this.state.error.msg}</p>);
        }
        else
            if (this.state.isLoading) {
                return (<p>Laden...</p>);
            }
            else
                return (
                    <div className={`CropperComponent ${this.props.className}`}>

                        <input ref={this.fileInputRef} type="file" accept=".jpg" onChange={this.onFileSelected}></input>

                        {
                            this.state.error.status != "NA" && !this.state.error.critical ?
                                <p>{this.state.error.msg}</p> : null
                        }
                        {
                            <Cropper
                                className={this.state.image ? "" : "disable_crop"}
                                src={this.state.image}
                                crop={this.state.crop}
                                ref={this.cropperRef}
                                minWidth={this.state.imageType.minWidth / this.state.scale}
                                onChange={this.onCrop}
                                onImageLoaded={this.onLoad}
                            />
                        }

                        {
                            this.state.uploading ? <p>Lädt...</p> : null
                        }
                        {
                            this.state.crop.width === 0 || this.state.crop.height === 0 ?
                                <p>Bitte wählen sie einen Bereich aus.</p> : null
                        }
                        {
                            this.state.image ?
                                <div>
                                    <button
                                        onClick={this.onButtonClick}
                                        disabled={this.state.crop.width == 0 || this.state.uploading}>
                                        Bestätigen
                                    </button>
                                    <button className="abort" onClick={this.onAbort}>Abbrechen</button>
                                </div> : null


                        }
                    </div>
                );
    }
}

export default withRouter(CropperComponent);