import "./MittelhessenSkyline.scss"

function MittelhessenSkyline({ backgroundColor = "#414eed", color = "#2935bd", cloudsColor }) {
    return (
        <svg className="MittelhessenSkyline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 400" style={{ background: "transparent" }}>
            <defs>
                <clipPath id="clip-skyline_background">
                    <rect width="1080" height="400" />
                </clipPath>
            </defs>
            <g id="skyline_background" fill={"transparent"} >
                <rect width="1080" height="400" />
                <rect id="bg" data-name="Rechteck 8" width="1080" height="400" fill={backgroundColor} />
                <g id="BOTTOM" transform="translate(-15 34.138)">


                    <path id="Ground" d="M158.024,22.49c.286.759-6.49,24.91,0,28.681,14.45,8.4,53.555-.041,83.663,0,36.384.05,73.963,1.931,106.235,0,19.216-1.15,6.679-9.549,23.4-11.074,30.564-2.787,13.465-19.645,22.029-26.735,4.07-3.368,121.6,15.185,234.58,12.17s32.782,14.478,105.945-3.043c18.129-4.342,18.529,5.467,39.4,0,9.05-2.37,10.392-11.545,22.543-15.276C803.9,4.735,837.022-13.443,846.643-16,859.532-19.418,926.376-33.436,933-35.42c14.184-4.25,98.544-14.5,127.2-17.281,23.874-2.321,75.867,15.433,151.4,75.191s279.978,80.141,279.978,80.141H-39.063s84-12.765,115.532-62.534C99.646,28.356,156.47,18.373,158.024,22.49Z" transform="translate(-70.329 297.369)"

                        fill={color} />
                    <g id="buildings" transform="translate(80.882 148.552)">
                        <g id="gießen_dachcafe" data-name="gießen dachcafe" transform="translate(417.76 28.92)">
                            <rect id="box" width="100" height="118" transform="translate(0.358 33.528)" fill={color} />
                            <rect id="box-2" data-name="box" width="28" height="14" transform="translate(36.358 13.528)" fill={color} />
                            <rect id="box-3" data-name="box" width="100" height="4" transform="translate(0.358 19.528)" fill={color} />
                            <rect id="box-4" data-name="box" width="95" height="12" transform="translate(3.358 23.528)" fill={color} />
                            <rect id="box-5" data-name="box" width="14" height="9" transform="translate(43.358 9.528)" fill={color} />
                            <rect id="antenne" width="2" height="9" transform="translate(50.358 0.528)" fill={color} />
                        </g>
                        <g id="Grünberg_" data-name="Grünberg " transform="translate(7 10)">
                            <g id="Symbolisches_danke_diebsturm" data-name="Symbolisches danke diebsturm" transform="translate(-80.882 -148.552)">
                                <path id="Pfad_4" data-name="Pfad 4" d="M0,1.8H4.67L14.793-21.268,23.936,1.8h5.047V48.436H0Z" transform="translate(602 276.892)" fill={color} />
                            </g>
                        </g>
                        <g id="wetzlar" transform="translate(0 46.626)">
                            <g id="brücke" transform="translate(0 120.517)">
                                <path id="bogen" d="M-.111,28h49.9l-.158,33.633H43.351v-4S44.658,35.026,25.006,34.9,6.641,58.217,6.641,58.217v3.416H-.07Z" transform="translate(0.111 -24.75)" fill={color} />
                                <path id="bogen-2" data-name="bogen" d="M-.111,28h49.9l-.158,33.633H43.351v-4S44.658,35.026,25.006,34.9,6.641,58.217,6.641,58.217v3.416H-.07Z" transform="translate(48.202 -24.75)" fill={color} />
                                <path id="bogen-3" data-name="bogen" d="M-.111,28h49.9l-.158,33.633H43.351v-4S44.658,35.026,25.006,34.9,6.641,58.217,6.641,58.217v3.416H-.07Z" transform="translate(97.53 -24.75)" fill={color} />
                                <path id="bogen-4" data-name="bogen" d="M-.111,28h49.9l-.158,33.633H43.351v-4S44.658,35.026,25.006,34.9,6.641,58.217,6.641,58.217v3.416H-.07Z" transform="translate(145.563 -24.75)" fill={color} />
                                <path id="bogen-5" data-name="bogen" d="M-.111,28h49.9l-.158,33.633H43.351v-4S44.658,35.026,25.006,34.9,6.641,58.217,6.641,58.217v3.416H-.07Z" transform="translate(194.891 -24.75)" fill={color} />
                                <path id="deckel" d="M244.468.236H0S65.3-3.145,126.413-3.145,244.468.236,244.468.236Z" transform="translate(0.241 3.145)" fill={color} />
                            </g>
                            <g id="trum_schloss" data-name="trum schloss" transform="translate(223.918)">
                                <path id="untne" d="M10.634,32.206,20.721,10.818l39.194,1.22V-2.724s2.321-1.756,2.173-3.887,4.306-6.721,4.306-6.721v-4.59H67.43v4.59a44.048,44.048,0,0,0,3.85,4.857c.485,1.122-.2,1.774.457,3.055a5.835,5.835,0,0,0,1.724,1.932V6.8L86.75,2.283l13.289-4.169,11.082,24.394h1.52l1.827-7.136,3.645-5.579h.666l.31-3.749h.707V9.505l4.229,6.134L124.537.115l2.188-10.921h-4.006v-.886h2.625V-15.7l4.5-1.6V-33.248l3.755.876.956-2.353h1.778l-.718-4.871.718-4.743h2.261l.786-.684h18.432L156.806-38.6v4.608l6.813-1.546-.432,16.408,5.164.549,1.7,15.857,1.654-7.342L173.4,6.5v10.1l1.535,2.256V71.405H142.619L-.9,60.873l-.955-20.524L-.606,29.071l1.558,8.2,1.34-9.845,1.3,5.975,1.159-5.975,1.384,13.28L7.567,30.13l.911,1.515.885-5.631Z" transform="translate(1.852 67.553)" fill={color} />
                                <path id="Pfad_1" data-name="Pfad 1" d="M3.092,6.916,1.243,6.044l.147,4.019H.12L-.129,4.778-1.036,4.6l.907-6.9L.593-.466h.965l.22-2.4.9.339,2.58-3.608L5.8-9.329H6.58l.465,3.192L11.562-.311l1.1-1.989.3,6.1L11.709,5.509v4.554H10.683V5.83H9.352v4.233H8.07V5.83a7.028,7.028,0,0,0-1.941-.321A3.282,3.282,0,0,0,4.58,5.83l-.144,4.233H3.092Z" transform="translate(142.305 12.503)" fill={color} />
                                <path id="Pfad_2" data-name="Pfad 2" d="M.786-.266C1.586-.28,1.813.1,1.806.939s-.585.786-1.019.786A.786.786,0,0,1,0,.939C0,.5-.013-.252.786-.266Z" transform="translate(147.617 1.966)" fill={color} />
                                <path id="Pfad_3" data-name="Pfad 3" d="M1.518-.347h.356l.14,1.994L1.4,1.369Z" transform="translate(146.788 0.347)" fill={color} />
                            </g>
                        </g>
                        <g id="MARBURG" transform="translate(-36 82.185)">
                            <path id="schloss" d="M-10.278-19.4h12.8l3.591-2.028v-5.258L5.4-27.793v-1.94l1.087-.684.149-2.308a9.874,9.874,0,0,0,.71-1.385A9.773,9.773,0,0,0,7.787-35.6l1.768-2.366.313-2.245v-4.5l1.291-3v-1.437h1.02V-47.7l1.25,3,.194,4.5.36,2.245a16.793,16.793,0,0,1,1.859,2.173c.629.963.317.933.657,1.68a5.849,5.849,0,0,0,.948,1.385l-.484.272v1.721l1.666,1v1.94l-1.456,1.107v6.062l1.18,1.658H22.68l1.532-6.328L27.876-32l64.485,2.423L98.037-17.97h12.2l2.621,4.352L116,0V43L54.361,64.573-56.7,66.425V57.1l4.047-6.19V45.3l29.621-10.122V8.283l-.943-1.378V.435l2.269-13.374,2.079,9.762,4.065-6.588.851-7.152,1.1,3.57Z" transform="translate(796.118 -15.737)" fill={color} />
                            <path id="Pfad_5" data-name="Pfad 5" d="M10.929,63.942h3.677V57.859h4.006V50.5h3.973V44.472h3.358V36.1h4.3l.584,3.053,11.6-1.516V29.517l1.907-4.108V23.883l2.04-9.656.741,3.972,6.732-46.3L64.859,18.2l1.1-5.622,1.824,9.833v6.257l2.056,2.265v8.779l3.029-.555.91,3.312,4.541-.6,1.275-4.227,1.778,3.373,3.134-.585.819-9.5,1.512-16.214,3.045,18.355v6.082h3.558v38.21l3.018,40.048H1.364V83.5l6.193-6.134V71.471h3.372Z" transform="translate(647.118 -24.737)" fill={color} />
                        </g>
                    </g>
                </g>
                <g id="CLOUDS">
                    <path id="CLOUD_R" data-name="CLOUD R" d="M127.114,19.473C112.47,18.861,90.14,31.83,90.14,31.83s-29.167-7.736-48.211-6.457S-3.717,38.024.419,41.2s163.181,3.8,163.853-1.348S145.631,17.629,127.114,19.473Z" transform="translate(967 14)" fill={cloudsColor || "#5865f2"} />
                    <path id="Cloud_L" data-name="Cloud L" d="M22.933,19.42c9.037-.378,22.818,7.626,22.818,7.626s18-4.774,29.753-3.985,28.17,7.807,25.618,9.77S.415,35.18,0,32,11.5,18.282,22.933,19.42Z" transform="translate(48 82)" fill={cloudsColor || "#5865f2"} />
                </g>

            </g>
        </svg>

    )
}
export default MittelhessenSkyline