import React, { useEffect, useState } from "react"
import { Route, Switch, useParams } from 'react-router-dom'
import SideBar from "../components/bars/SideBar/SideBar"
import Emulator from '../components/defaults/emulator/Emulator'
import { categorys } from "../const/Editor/categorys"
import { logout } from "../functions/auth"
import EntryListPage from "../pages/entry/EntryListPage"
import EntryEditHandler from "../pages/entry/EntryEditHandler"
import FieldList from "../components/entry/FieldList"
import "./HomeSwitch.scss"
import EntryNewPage from "../pages/entry/EntryNewPage"
import EntryNewBuyPage from "../pages/entry/EntryNewBuyPage"

function EntrySwitch({ }) {
    return (
        <Switch className="EntrySwitch">
            <Route path={"/entry/list"} component={EntryListPage} />

            <Route path={"/entry/new/buy"} component={EntryNewBuyPage} />
            <Route path={"/entry/new"} component={EntryNewPage} />

            <Route path={"/entry/:EntryID/"} component={EntryEditHandler} />
        </Switch>
    )
}
export default EntrySwitch