import { useState } from "react"
import TextareaAutosize from "react-textarea-autosize"
import { regexPhone } from "../../const/regex"
import AddressInput from "../defaults/inputs/AddressInput"
import ColorInput from "../defaults/inputs/ColorInput"
import EmailInput from "../defaults/inputs/EmailInput"
import IconInput from "../defaults/inputs/IconInput"
import ListInput from "../defaults/inputs/ListInput"
import NumberInput from "../defaults/inputs/NumberInput"
import OpeningHours from "../defaults/inputs/OpeningHours"
import PdfInput from "../defaults/inputs/PdfInput"
import SelectInput from "../defaults/inputs/SelectInput"
import ThreeHundredSixtyInput from "../defaults/inputs/ThreeHundredSixtyInput"
import ValidateInput from "../defaults/inputs/ValidateInput"
import WebsiteInput from "../defaults/inputs/WebsiteInput"
import "./ObjectEditor.scss"

function ObjectEditor({ objectKeys = [], onChange, value = {} }) {
    const [New, setNew] = useState(value)

    function updateField(key, value) {
        let newValue = New
        newValue[key] = value
        setNew(newValue)
        onChange({ target: { value: newValue } })
    }

    return (<div className="Value">
        {objectKeys.map((f, k) =>
            <div>
                <Input onChange={(e) => updateField(f.key, e.target.value)} value={New[f.key]} field={f} key={k} className={f.key} />
            </div>
        )}
    </div>)

}
export default ObjectEditor

function getPlaceholder(placeholder, example, title) {
    if (placeholder) {
        return placeholder
    }
    else if (example) {
        return `z.B.: ${example}`
    } else {
        return `${title} hier eintragen`
    }
}

function Input(inputProps) {
    let { onChange, value, field, className } = inputProps
    let { type, title, example, placeholder } = field

    inputProps = { ...inputProps, ...field.typePayload }
    inputProps.placeholder = getPlaceholder(placeholder, example, title)


    inputProps.setNewValue = (value) => {
        inputProps.onChange({ target: { value: value } })
    }

    let res
    switch (type) {
        case "LOCATION":
            res = <AddressInput {...inputProps} />
            break;
        case "STRING":
            res = <input  {...inputProps} />
            break;
        case "TEXT":
            res = <TextareaAutosize {...inputProps} />
            break;
        case "BOOL":
            res = <input type="checkbox"  {...inputProps} />
            break;
        case "REVERSED_BOOL":
            res = <input type="checkbox"  {...inputProps} value={!inputProps.value} />
            break;
        case "INT":
            res = <NumberInput type="number"  {...inputProps} />
            break;
        case "SELECT_STRING":
            res = <SelectInput type="STRING"  {...inputProps} />
            break;
        case "SELECT_INT":
            res = <SelectInput type="STRING"  {...inputProps} />
            break;
        case "COLOR":
            res = <ColorInput {...inputProps} />
            break;
        case "URL":
            res = <WebsiteInput {...inputProps} />
            break;
        case "PHONE":
            res = <ValidateInput  {...inputProps} regex={regexPhone} />
            break;
        case "PDF":
            res = <PdfInput {...inputProps} />
            break;
        case "360":
            res = <ThreeHundredSixtyInput {...inputProps} />
            break;
        case "MAIL":
            res = <EmailInput {...inputProps} />
            break;
        case "LIST":
            res = <ListInput {...inputProps} />
            break;
        case "LIST_INT":
            res = <ListInput filter="INT" {...inputProps} />
            break;
        case "HASHTAGS":
            res = <ListInput prefix="#" {...inputProps} />
            break;
        case "OPENING_HOURS":
            res = <OpeningHours {...inputProps} />
            break;
        case "ICON":
            res = <IconInput {...inputProps} />
            break;

        case "ADDRESS":
            res = <AddressInput  {...inputProps} regex={regexPhone} />
            break;

        default:
            res = <p>Versuchen Sie es bitte später erneut! (Support-Code-2: Field-{type})</p>
    }


    return <div>
        <span>{field.title || "Kein titel"}</span>
        {res}
    </div>
}