import React from "react"
import "./ColorInput.scss"

function ColorInput({ value = {}, onChange, placeholder, keyName }) {

    function handleChange(key, newValue) {
        value = {}
        value[key] = newValue
        onChange(value)
    }

    console.log("keyName" + keyName)

    return (
        <div className="ColorInput">

            <div className="theme">
                <input type="color" value={value?.light} onChange={(e) => handleChange("light", e.target.value)} />
                <span>Helles-Design</span>
            </div>
            <div className="theme darkMode">
                <input type="color" value={value?.dark} onChange={(e) => handleChange("dark", e.target.value)} />
                <span>Dunkles-Design</span>
            </div>
        </div>
    )
}
export default ColorInput