import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import CouponInput from "../../components/api/Inputs/CouponInput"
import InputLoop from "../../components/defaults/boxes/InputLoop"
import PriceBox from "../../components/defaults/boxes/PriceBox"
import Emulator from "../../components/defaults/emulator/Emulator"
import SelectBoxes from "../../components/defaults/inputs/SelectBoxes"
import { Benefits } from "../../const/Entry"
import { AboTypes, CompanyTypes, Intervals } from "../../const/Payment"
import "./EntryNewPage.scss"

function EntryNewPage({ }) {
    const [EmulatorPayload, setEmulatorPayload] = useState(false)
    const [PacketSelection, setPacketSelection] = useState({})
    const [BasePrice, setBasePrice] = useState(0)
    const [Entry, setEntry] = useState()
    const [Finish, setFinish] = useState(false)
    const [PriceObj, setPriceObj] = useState({
        perMonth: 3.33,
        durationText: "Mindestlaufzeit 1 Jahr",
        intervalText: "Jährliche Abrechnung",
    })
    const [Discount, setDiscount] = useState({ amount: 0, code: "" })
    function generateBasePrice(apps) {
        let basePrice = calculateBasePrice(apps)
        return basePrice

    }


    function handleChange(e) {
        let { value, isFinish } = e.target

        setPacketSelection(value) //Damit 

        // let entry = packetSelectionToEntryData(value)
        // setEntry(entry)
        let price = generatePrice(value)



        setPriceObj({
            perMonth: price,
            durationText: "Mindestlaufzeit 1 Jahr",
            intervalText: "Jährliche Abrechnung",
        })
        // handleEmulatorUpdate()

        setFinish(isFinish)
        if (isFinish) {
        }
    }

    // function handleEmulatorUpdate(e) {
    //     rerenderEmulator(e)
    //     // rerenderEmulator(e)
    // }

    // useEffect(() => {
    //         rerenderEmulator(EmulatorData)
    // }, [EmulatorData, PacketSelection])

    function handleEmulatorUpdate(e) {
        console.log(e)

        let abo = PacketSelection[2] || []
        let isPremium = abo[0]?.ID > 1
        let isPremiumPlus = abo[0]?.ID > 2

        console.log(abo)
        console.log(abo[0]?.ID)

        let contactOptions = [
            {
                "contactType": "PHONE",
                "displayTitle": "<Ihre Telefonnummer>",
            },
            {
                "contactType": "WEBSITE",
                "displayTitle": "<Ihre Website>",
            },
            {
                "contactType": "INSTAGRAM",
                "displayTitle": "<Ihre Socaial-Media Links>",
            },
        ]

        if (isPremium) {
            contactOptions.push(
                {
                    "contactType": "MENU",
                    "displayTitle": "Speisekarte verlinken / PDF",
                }
            )
        }



        let pl = {
            meta: {
                ID: 1,
            },
            general: {
                title: e?.title || "Neuer Eintrag",
                subtitle: e?.subtitle || "Neu in der App!",
            },
            payload: {
                images: [
                    { url: "https://blog.dashdesign.eu/wp-content/uploads/2022/02/entry-welcome-image-1024x683.jpg" }
                ],
                contactOptions: contactOptions
            },

        }


        setEmulatorPayload(pl)
    }

    let emulatorProps = {
        live: true,
        page: EmulatorPayload ? null : <h1>Hallo</h1>,
        entryData: EmulatorPayload
    }


    // console.log(emulatorProps)

    let linkState = {
        payload: JSON.stringify({
            PriceObj: PriceObj,
            PacketSelection: PacketSelection,
            Discount: Discount,
            // EntryData: packetSelectionToEntryData(PacketSelection),
        })
    }

    function handleNewCoupon(amount, code) {
        setDiscount({ amount: amount, code: code })
    }


    return (
        <div className="EntryNewPage">
            <div className="container" style={{ maxWidth: 1200 }}>
                <div className="d-flex">
                    <div className="d-none d-lg-block EmulatorWrapper p-5">
                        <Emulator {...emulatorProps} />
                    </div>

                    <div className="p-lg-2 p-5 p-lg-5 w-100">

                        <span className="Tag"></span>

                        <div>
                            <h1>Eintrag erstellen</h1>
                            <hr />
                            <p>
                                Sie buchen einen Eintrag in der Mittelhessen-App. Falls es in Ihrer Region allerdings eine kommunale App unseres Unternehmens gibt, werden Sie dort ebenfalls gelistet. <b>offiziellen Grünberg-App</b> gelistet.
                                <br />
                            </p>
                        </div>

                        <div className="features">
                            {Benefits.map((i, k) => <ul key={k}>{i}</ul>)}
                        </div>

                        <InputLoop bonusPayload={{ price: PriceObj.perMonth, onEmulatorUpdate: handleEmulatorUpdate }} inputs={fields} onChange={(e) => { handleChange(e) }} />

                        {PriceObj && Finish ?
                            <>
                                <CouponInput onCouponChange={handleNewCoupon} />
                                <hr></hr>
                                <PriceBox discountAmount={Discount.amount} {...PriceObj} />
                                <hr></hr>
                                <Link
                                    to={{
                                        pathname: "/entry/new/buy",
                                        state: linkState
                                    }}>
                                    <button>Weiter</button>
                                </Link>
                            </> : null}

                    </div>
                </div>
            </div>
        </div>
    )
}
export default EntryNewPage

//*Identisch mit API !!!!
function generatePrice(values) {
    let basePrice = calculateBasePrice(values[0])
    let price = basePrice

    values.forEach(selectBoxesData => {
        if (Array.isArray(selectBoxesData)) {
            selectBoxesData.forEach(box => {
                if (box.priceFactor) {
                    price = price * box.priceFactor
                }
            })
        }
    })

    return price
}
//*Identisch mit API !!!!
function calculateBasePrice(selectedApps) {
    let price = 0
    selectedApps.forEach(i => {
        price += i.price
    })
    return price
}

const fields = [
    {
        title: "Rechtsform",
        description: <>Bei Falschangabe behalten wir uns eine Änderungsgebühr von 98€ und weitere Schritte vor.</>,
        key: "TYPE",
        component:
            <SelectBoxes
                boxes={CompanyTypes}
            // renderBox={(i, isActive) =>
            //     <div>
            //         <div className="icon">{i.icon}</div>
            //         <span className="title">{i.title}</span>
            //         <span className="description">{i.description}</span>
            //     </div>
            // }
            />,
    },
    // {
    //     title: "Apps auswählen",
    //     description: <>Wenn sich Ihr Eintrag beispielsweise in Grünberg befindet, wird Ihr Eintrag zusätzlich in der <b>offiziellen Grünberg-App</b> gelistet. Diese Regelung greift nur bei kommunalen Apps von der Firma dashdesign;.</>,
    //     key: "APPS",
    //     component:
    //         <SelectBoxes
    //             multiple
    //             boxes={AppList}
    //         />,
    // },
    {
        title: "Erste Details eingeben",
        description: "Sie können den Inhalt nachträglich ändern und weitere Details hinzufügen.",
        key: "DETAILS",
        component: <AddDetails />,
    },
    {
        title: "Abomodell wählen",
        key: "COMPANY_TYPE",
        component: <SelectBoxes boxes={AboTypes} />,
        // tip: "Tipp",
    },

    {
        title: "Zahlungsintervall",
        key: "INTERVAL",
        component: <SelectBoxes boxes={Intervals} />,
    },
]

//** Compnente um Firmendetails einzutragen */
function AddDetails({ onChange, onEmulatorUpdate }) {
    const [Error, setError] = useState(false)
    const [Data, setData] = useState({ title: "", subtitle: "", image: null })

    function finish() {
        if (Data.title) {
            onChange({ target: { value: Data } })
            // onChange("DETAILS", Data)
        } else {
            setError(true)
        }
    }


    function handleEmulatorUpdate() {
        onEmulatorUpdate(Data)
    }

    useEffect(() => {
        handleEmulatorUpdate()
    }, [Data])

    function updateData(inputData) {
        if (inputData?.title?.length) {
            setError(false)
        }
        setData({ ...Data, ...inputData })
    }

    return (<div className="AddDetails">

        <h4>Gewerbename</h4>

        <div className="mb-3">
            <div className={Error ? "Error" : null}>
                <input
                    value={Data.title}
                    placeholder={"Neuer Eintrag"}
                    onChange={(e) => updateData({ title: e.target.value })} />
                <span className="msg">Bitte tragen Sie hier den Name Ihres Gewerbes oder Vereins ein!</span>
            </div>
        </div>


        <div className="d-none d-lg-block">
            <h4>Slogan (optional)</h4>
            <input className="mb-3"
                placeholder={"Neu in der App!"}
                value={Data.subtitle}
                onChange={(e) => updateData({ subtitle: e.target.value })} />
        </div>

        {/* <h4>Bild ändern</h4> */}

        <button onClick={finish}>Weiter</button>

        {/* <p>{JSON.stringify(Data)}</p> */}
    </div>)

}

//* Sollte auch in der API sein!!! */
function packetSelectionToEntryData(packetSelection, uuid = 0) {
    if (Array.isArray(packetSelection) && packetSelection.length >= 4) {
        let appIDs = []
        packetSelection[0].forEach(app => (
            appIDs.push(app.ID)
        ))

        let entryData = {
            private: {
                payment: {
                    interval: packetSelection[4][0].ID
                }
            },
            meta: {
                apps: appIDs,
                aboType: packetSelection[2][0].ID,
                type: packetSelection[3][0].ID,
                uuid: uuid,
                entryID: null,
            },
            general: {
                title: packetSelection[2].title || "?",
                subtitle: packetSelection[2].subtitle || "Neu in der App",
            },
            payload: {
                images: [
                    {
                        url: packetSelection[2]?.image,
                    }
                ]
            }
        }
        return entryData
    }
}