import QRCode from "qrcode.react"
import React, { useEffect, useState } from "react"
import { getToken } from "../../functions/auth"
import "./ContinueOnMobile.scss"

function ContinueOnMobile({ functionName, smallText }) {

    const [ShowLoginCode, setShowLoginCode] = useState(false)

    return (
        <div className="ContinueOnMobile">

            <h2>{functionName}</h2>

            <h3>Die Funktion '{functionName}' ist aktuell nur auf dem Smartphone verfügbar. </h3>


            <div className="qrBox">
                {ShowLoginCode ?
                    <div className="LoginCode">
                        <QRCode renderAs="svg" fgColor="#24314b" size={"100%"} value={"https://backend.dashdesign.eu/l/qWa3Dhf-f8$4"} />
                    </div>
                    :
                    <div className="unlockBox" onClick={() => setShowLoginCode(true)}>
                        <QRCode renderAs="svg" fgColor="#24314b" size={"100%"} value={"https://dashdesign.eu/karriere/"} />

                        <div className="overlay">
                            <div className="inner">
                                <div className="icons">
                                    <i className="fa-solid fa-lock" />
                                    <i className="fa-solid fa-unlock" />
                                </div>
                                <span>Anmeldecode <br />einblenden.</span>
                            </div>
                        </div>
                    </div>
                }
            </div>


            <p>
                <small>{smallText}</small>
            </p>

        </div>
    )
}
export default ContinueOnMobile