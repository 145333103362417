import { default as dateformat, default as dateFormat } from 'dateformat'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import Formular from "../../components/api/Formular"
import PopupBox from '../../components/defaults/boxes/PopupBox'
import AddEventButton from '../../components/event/AddEventButton'
import EventItem from '../../components/event/EventItem'
import Config from '../../const/Config'
import "./EventsPage.scss"
import { getFormularProps, loadEventsFromApi } from './EventsUtils'

function EventsPage({ extraData, setExtraData }) {
    let history = useHistory()
    let { EntryID } = useParams()

    const [Data, setData] = useState([]);
    const [EditData, setEditData] = useState({});
    const [isModalVisible, setModalVisibility] = useState(false);
    const [Search, setSearch] = useState("")

    const toggleModal = () => setModalVisibility(!isModalVisible);

    useEffect(() => {
        loadEvents();
    }, [Search])



    function handleSent() {
        alert("Neues Event angelegt. ✅");
        loadEvents();
    }

    function getProps() {
        let ka = getFormularProps([], EntryID, handleSent, handleImageChange);
        ka.bonusPayload = { EntryID: EntryID };
        return ka
    }

    function formatDate(str) {
        return dateformat(new Date(str), "yyyy-mm-dd");
    }

    function onSend() {
        toggleModal();
        loadEvents();
        setModalVisibility(false)
    }

    async function loadEvents() {
        let filter = [{
            "key": "organizer",
            "value": EntryID,
            "type": "LIKE"
        }]

        if (Search) {
            filter.push({
                "key": "title",
                "value": `%${Search}%`,
                "type": "LIKE"
            })
        }
        let data = await loadEventsFromApi(filter)
        setData(data)
    }

    function getEditProps() {
        let overrideObj = Object.assign({}, EditData);


        //Datum anpassen
        if (Object.keys(EditData).length != 0) {
            overrideObj.startDate = dateFormat(new Date(overrideObj.startDate * 1000), "yyyy-mm-dd")
            if (overrideObj.endDate)
                overrideObj.endDate = dateFormat(new Date(overrideObj.endDate * 1000), "yyyy-mm-dd");
        }

        let propObj = getFormularProps(overrideObj, EntryID, onSend, handleImageChange);
        propObj.apiAdress = Config.ServerAddress + "/event/?EntryID=" + EntryID;
        propObj.axiosMethod = "patch"
        propObj.sendText = "Änderungen speichern";
        propObj.onMapData = (a) => { return { ...a, ID: overrideObj.ID } };
        propObj.onSend = () => onSend();
        return propObj;
    }

    function handleImageChange(data, key) {
        if (key === "imageUrl") {
            setExtraData({ ...extraData, imageUrl: data.imageUrl });
            console.log(data);
        }
    }

    const onModalEdit = (props) => {
        console.log(props)
        setEditData(props);
        toggleModal();
    }

    let eventUrl = "https://mittelhessen.app/event/" + EditData.ID

    return (
        <div className="container EventsPage">

            <PopupBox visible={isModalVisible}>
                <div className="container">
                    <div className="pt-5 pb-3 d-flex flex-row justify-content-between align-items-center">
                        <h2>Event bearbeiten</h2>

                        <a href={eventUrl} target="_blank"><i class="fa-solid fa-arrow-up-right-from-square"></i></a>
                        {/* <button onClick={() => toggleModal()}>Fertig</button> */}
                    </div>
                    <Formular   {...getEditProps()} />
                </div>
            </PopupBox>



            <div className="row">

                <div className="col-12 p-0 ">

                    <div className="d-flex flex-row align-items-center justify-content-between">
                        <div className="d-flex flex-row align-items-center ">
                            <h2 className="p-2">Veranstaltungen </h2>

                            {/* //  WordPress-Eventmanager, CMS-Systeme und  */}

                            <AddEventButton formularProps={getProps()} />
                        </div>


                        <input
                            style={{ width: 200 }}
                            placeholder="Durchsuchen 🔎"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>

                    <div>
                        <h3>Automatisch eintragen:</h3>
                        <p>Viele Website-Systeme und Kalender sind mit unserer Platform kompatibel.</p>
                    </div>


                    {Data.length == 0 && !Search ? <p>Es wurden noch keine Events angelegt.</p> : null}
                    {Data.length == 0 && Search ? <p>Keine Treffer für 'Search'.</p> : null}

                    <div className="row">
                        {Data.map((i, k) =>
                            <EventItem key={k} onEdit={onModalEdit} {...i} />
                        )}
                    </div>
                </div>
                <hr></hr>

            </div>

        </div>
    )
}

export default EventsPage