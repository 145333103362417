import React, { useEffect, useState } from "react"
import "./ThreeHundredSixtyInput.scss"
import WebsiteInput from "./WebsiteInput"

function ThreeHundredSixtyInput(props) {

    const [InputValue, setInputValue] = useState("")

    return (
        <div className="ThreeHundredSixtyInput">
            <WebsiteInput props={props} />
        </div>
    )
}
export default ThreeHundredSixtyInput