import React, { useEffect, useState } from "react"
import "./Tip.scss"

function Tip({ children }) {
    const [IsOpen, setIsOpen] = useState(false)
    return (
        <div className="Tip" style={{ opacity: children ? 1 : 0 }}>
            <i className="fa-solid fa-info-circle" />
            <div className="popup">
                <div className="textBox">
                    <small>{children}</small>
                </div>
            </div>
        </div >
    )
}
export default Tip