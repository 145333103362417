import "./Switch.scss"

function Switch(props) {
    let { value, onChange } = props

    function handleChange(e) {
        let newValue = e.target.checked
        console.log(newValue)
        onChange({ target: { value: newValue ? "true" : "false" } })
    }
    return (
        <div>
            <h></h>
            <label className="Switch">
                <input type="checkbox" checked={value == "true"} onChange={handleChange} />
                <span className="slider round"></span>
            </label>
            {value ? "Ja" : "Nein"}
        </div>
    )
}
export default Switch