import React, { useState } from "react";
import "./CodeBox.scss";

function CodeBox({ children }) {

    const [Copyed, setCopyed] = useState()

    async function copy() {
        let value = children
        try {
            await navigator.clipboard.writeText(value);
            setCopyed(true)

            const interval = setInterval(() => {
                setCopyed(false)
                return () => clearInterval(interval);
            }, 2000);
        } catch (err) {
            alert("Code nicht kopiert. Bitte manuell kopieren oder")
        }
    }

    return (
        <div className="CodeBox">
            <div className="text">
                <code>
                    {children}
                </code>
            </div>
            <div onClick={copy}>
                {Copyed ?
                    <button className="finish"><i className="fa-solid fa-square-check"></i> KOPIERT</button>
                    :
                    <button><i className="fa-solid fa-copy"></i> KOPIEREN</button>
                }
            </div>
        </div >
    )
}
export default CodeBox