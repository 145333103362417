import React, { useEffect, useState } from "react"
import "./PasswordInput.scss"

function PasswordInput(props) {
    const [Show, setShow] = useState(false)
    return (
        <div className={"PasswordInput " + props.className}>
            <div className="input-group">
                <input
                    {...props}
                    className="form-control"
                    type={Show ? "text" : "password"}
                    autoComplete="password" />
                <div className="icon" onClick={() => setShow(!Show)}>
                    <i className={Show ? "fa-solid fa-eye-slash" : "fa-solid fa-eye"} />
                </div>
            </div>
        </div>
    )
}
export default PasswordInput