import React from "react";
import "./NumberInput.scss";

function NumberInput(props) {
    let { placeholder, value, onChange, prefix } = props
    console.log("go")

    function handleChange(e) {
        let targetValue = e.target.value
        let newValue = null

        if (targetValue) {
            newValue = parseFloat(targetValue.replace(",", "."))
        }

        onChange({ target: { value: newValue } })
    }

    return (
        <div className="NumberInput">
            <input type="number" {...props} onChange={handleChange} />
        </div>
    )
}
export default NumberInput