import axios from "axios"
import { useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import BuyButton from "../../components/defaults/boxes/BuyButton"
import PriceBox from "../../components/defaults/boxes/PriceBox"
import PaymentAddressInput from "../../components/defaults/inputs/PaymentAddress"
import Config from "../../const/Config"
import { getToken } from "../../functions/auth"
import LoadingPage from "../more/LoadingPage"
import "./EntryNewBuyPage.scss"

function EntryNewBuyPage({ }) {
    const [Ready, setReady] = useState(false)
    const [Loading, setLoading] = useState(false)
    const [Errors, setErrors] = useState([])
    const [ShowErrors, setShowErrors] = useState(false)
    const [Address, setAddress] = useState({})
    const location = useLocation()
    let history = useHistory()
    let payload = location.state?.payload

    function redirectEntryPage(entryID) {
        if (entryID) history.push(`/entry/${entryID}/wichtig`)
    }


    if (payload) {
        let data = JSON.parse(payload)
        let { PriceObj, PacketSelection, Discount } = data

        let companyInfos = PacketSelection[1] || {}
        let { title } = companyInfos

        console.log(PacketSelection)

        if (!PriceObj) {
            alert("Fehler beim Kauf\nSupport-Details: Es wurden keine Kaufdaten übergeben (E-1335)")
        }

        function handleAddressChange(e) {
            let { value, check } = e.target
            console.log(e.target)
            setAddress(value)
            setShowErrors(false)
            setReady(check.value)
            if (check.messages) {
                //kann weg: setReady(false)
                setErrors(check.messages)
            }
        }

        function handleBuy() {
            if (Errors.length != 0 || Address == {}) {
                console.log(Errors)
                console.log(Address)
                setShowErrors(true)
            } else {
                setLoading(true)

                let buyPayload = {
                    Address: Address,
                    PacketSelection, //Alle Daten um Entry anzulegen
                    PriceObj: PriceObj, //Um sicherhithalber Preisunterschiede abzufangen
                    Discount: Discount //Rabatt 
                }

                let config = {
                    method: 'post',
                    url: Config.ServerAddress + '/entries/create/',
                    headers: {
                        "Authorization": getToken()
                    },
                    data: buyPayload
                };

                axios(config)
                    .then((response) => {
                        let { entryID } = response.data
                        redirectEntryPage(entryID)
                    })
                    .catch((error) => {
                        setShowErrors(true)
                        setErrors(["Der Eintrag konnte nicht angelegt werden. Versuchen Sie es bitte erneut."])

                        console.log(error);
                    })
                    .finally(() => {
                        setLoading(false)
                    })
            }
        }


        if (Loading) {
            return <LoadingPage text="Buchung wird bearbeitet" desc="Bitte warten Sie noch einige Sekunden." />
        }

        return (
            <div className="EntryNewBuyPage">
                <div className="container">

                    <div className="mt-4 mb-4">
                        <h1>Nur noch dieser Schritt!</h1>
                        <hr />
                    </div>

                    <h3>Ihre Rechnungsadresse:</h3>
                    <PaymentAddressInput error={(ShowErrors && Errors != [])} startValue={{ companyName: title }} onChange={handleAddressChange} />

                    <hr />
                    <div className="pt-4">
                        <PriceBox discountAmount={Discount.amount} {...PriceObj} />
                    </div>
                    <div className="mt-5">


                    </div>
                </div>
                <div className="fixedPush" />
                <div className="fixed">
                    <div className="container">
                        <p className="error">
                            {ShowErrors ?
                                Errors.map((i, k) =>
                                    k == Errors.length - 1 ?
                                        `${i}`
                                        :
                                        `${i}, `
                                )
                                : ""}
                        </p>

                        <BuyButton free={Discount.amount == 100} onClick={handleBuy} softDisabled={!Ready} />
                    </div>
                </div>
            </div>
        )
    } else {
        return <PaymentAddressInput />
        return "Fehler beim Kauf\nSupport-Details: Es wurden keine Kaufdaten übergeben (E-1337)"
    }

}
export default EntryNewBuyPage