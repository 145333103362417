import React, { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import "./SortableImage.css";
import { Image } from "./Image";
import deleteImg from './delete.png';

export function SortableImage({ id, imageDetail, onDelete }) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({ id: id });

    const [buttonVisible, setButtonVisible] = useState(false);
    const [imageVisible, setImageVisible] = useState(true);

    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition
    };

    function handleDelete()
    {
        setImageVisible(false);
        setTimeout(() => {
            onDelete(imageDetail);
        }, 100);
    }

  return (
    <div className={"image-container anim " + (imageVisible ? "visible-on" : "visible-off")}>
        
        <Image
        ref={setNodeRef}
        style={style}
        imageDetail={imageDetail}
        {...attributes}
        {...listeners}
        id={id}
        onMouseEnter={() => setButtonVisible(true)}
        onMouseLeave={() => setButtonVisible(false)}
        />

        <img 
        className={"delete anim " + (buttonVisible ? "visible-on" : "visible-off")} 
        src={deleteImg} 
        onMouseEnter={() => setButtonVisible(true)}
        onDragStart={e => e.preventDefault()}
        onClick={handleDelete}/>

    </div>
    
  );
}
