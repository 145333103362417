import axios from "axios"
import React, { useEffect, useState } from "react"
import Config from "../../const/Config"
import { logout } from "../../functions/auth"
import DefaultLoader from "../defaults/loader/DefaultLoader"
import "./DefaultFetcher.scss"

function DefaultFetcher({ config, renderItem }) {
    const [Data, setData] = useState({})
    const [Error, setError] = useState(true)
    const [Loading, setLoading] = useState(false)

    useEffect(() => {
        loadData()
    }, [])

    function loadData() {
        setLoading(true)
        axios(config)
            .then((res) => {
                let data = res.data
                let renderResult = []
                data.forEach((e, k) => {
                    renderResult.push(renderItem(e, k))
                });
                setData(renderResult)
                setError(false)
                console.log("Data geladen")
                console.log(Data)
            })
            .catch(err => {
                console.log(err)
                console.log(config)
                if (err.response.status == 403) logout(true)
                setError(true)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <>
            {Loading ?
                <div className="DefaultFetcherLoader">
                    <DefaultLoader />
                </div>
                :
                <>
                    {Error ?
                        <div className="DefaultFetcherError">
                            <p>Fehler beim laden.</p>
                            <button onClick={loadData}>Erneut versuchen.</button>
                        </div>
                        :
                        <>
                            {Data == {} || Data == [] ? "Keine Daten" : null}
                            {Data || "Keine Daten geladen."}
                        </>
                    }
                </>
            }
        </>
    )
}
export default DefaultFetcher