import React from "react"
import "./MainLogo.scss"

function MainLogo({ white }) {
    let logoSrc = process.env.PUBLIC_URL + "/assets/image/logo/mittelhessen.app_"
    let logoLight = logoSrc + "light.png"
    let logoDark = logoSrc + "dark.png"



    return (
        <div className={"MainLogo " + (white ? "white" : null)}>
            {/* <picture className="w-100">
                <source srcSet={logoDark} media="(prefers-color-scheme: dark)" />
                <img alt="Logo der Mittelhessen-App" src={logoLight} />
            </picture> */}

            <span className="text">mittel<span className="sub">hessen</span>.app</span>
        </div>
    )
}
export default MainLogo