import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import Config from '../../const/Config';

function AdminEntryTags() {
    const [EntiesWithoutTags, setEntiesWithoutTags] = useState([]);

    useEffect(() => {
        axios.get(fetchUrl, config)
            .then(res => {
                setEntiesWithoutTags(res.data)
            })
            .catch(err => { alert("Fehler beim laden der Einträge ohne Tags: " + err) })
    }, [])

    let fetchUrl = Config.ServerAddress + "/admin/getEntryWithoutTags"
    let config = {
        headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem("token") },
    }

    return (
        <div className="mt-4">
            <h2>Tags</h2>
            <br /><br />

            <div>
                {EntiesWithoutTags.map((i, k) =>
                    <EntryItem key={k} {...i} />
                )}
            </div>

        </div>
    )
}
export default AdminEntryTags

let topTags = ["Firma", "Verein", "Computer", "Online-Shop", "Finanzen", "Steuern", "Digitalisierung", "Websites", "Gemütlich", "Gastro", "Stadt", "Gemeinde", "Privatperson", "Politik", "Vor Ort essen", "Action", "Kameras", "Bildersercvice", "Fotograf", "Fahrschule", "Bildungseinrichtung", "Essen abholen", "Lieferservice von Essen", "Beliebt", "Bar", "Restaurant", "Cafe", "Fastfood", "Bäcker", "Catering", "Shopping", "Lebensmittel", "Fairtrade", "Technik", "Schreibwaren", "Wohnen", "Freizeit", "Entspannen", "Sport", "Reisen", "Kultur", "Ferien", "Dienstleister", "Handwerk", "Kfz", "Versicherung", "Events", "Teszentrum", "Apotheke", "Arzt", "Gesundheitsberatung", "Pflege", "Abschalten", "Tiere", "Familienfreundlich", "Speziell für Kinder", "Nichts für Kinder", "Tankstelle", "Taxi", "Immobilienmarkler", "Mitglied des Grünberger Gewerbevereins", "Annahme von GWG-Gutscheinen", "Kfz", "Werkstatt", "Bau", "Pflanzen", "Winterdienst"]
// input value for every given suggestion.

function EntryItem(i) {
    const [Suggestions, setSuggestions] = useState([]);
    const [Value, setNewValue] = useState("");
    const [Tags, setTags] = useState([i.tags]);
    const [Loading, setLoading] = useState(false);

    const handlePress = (suggestion) => {
        setTags([...Tags, suggestion])
    }

    const getSuggestionValue = suggestion => {
        setTags([...Tags, suggestion])
        addTag(i.EntryID, suggestion)
        // return <div onPress={() => handlePress(suggestion)}>
        //     {suggestion}
        // </div>
    };

    const getSuggestions = value => {
        const inputValue = (value + "").toLowerCase();
        const inputLength = inputValue.length;


        return inputLength === 0 ? [] : topTags.filter(tag =>
            tag.toLowerCase().includes(value)
        );
    };
    const renderSuggestion = suggestion => (
        <div>
            {suggestion}
        </div>
    );

    function onSuggestionsFetchRequested({ value }) {
        setSuggestions(getSuggestions(value))
    };
    function onSuggestionsClearRequested() {
        setSuggestions([])
        setNewValue("")
    };
    function onChange(e) {

        setNewValue(e.target.value)
    }

    let inputProps = {
        placeholder: 'Tag suchen oder neu erstellen',
        value: Value,
        onChange: onChange,
    };

    let fetchUrl = Config.ServerAddress + "/admin/postEntryAddTag"
    let config = {
        headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem("token") },
    }

    function addTag(EntryID, tag) {
        setLoading(true)
        setTags([...Tags, tag])
        setNewValue(Value)


        axios.post(fetchUrl, { eid: EntryID, tag: tag }, config)
            .then(res => {
                console.log(res)
                setLoading(false)
            })
            .catch(err => {
                alert("Fehler beim erstellen des Tags " + tag + " :" + err)
                console.log(err)
                setLoading(false)
            })
    }



    // let renderSuggestion = suggestion => (
    //     <div className="d-flex flex-row" >
    //         <span className="col">{suggestion}</span>
    //         <i className="fa-solid fa-circle-plus"></i>
    //     </div>
    // );

    if (topTags.includes)

        return (
            <div className="mb-4">
                <p><b>{i.title}</b> <small>ID: {i.EntryID}</small></p>

                <div className="d-flex flex-row mb-2">
                    <small>
                        Hinzugefügt:<br />
                        {Tags.map((i, k) =>
                            <small key={k}>
                                {i + ", "}
                            </small>
                        )}
                    </small>
                </div>

                {Loading ? <span>Wird gespeichert ... </span> : null}

                <Autosuggest
                    suggestions={Suggestions}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                />
            </div>
        )
}