import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Helmet from "react-helmet"
import { Link } from 'react-router-dom';
import Config from '../../../const/Config';

function AdminCityList() {

    const [List, setList] = useState([]);

    useEffect(() => {
        let apiEndpoint = Config.ServerAddress + "/admin/city/list"
        let config = { headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem("token") }, }

        axios.get(apiEndpoint, config)
            .then(res => {
                setList(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    function g() {

        // axios.post(apiEndpoint, data, config)
        //     .then(res => {
        //         alert("OK, erstellt.")
        //         console.log(res)
        //     })
        //     .catch(err => {
        //         alert("Fehler beim Anlegen der App: " + err)
        //     })
    }


    return (
        <div className="col-12 col-md-9 mt-4">
            <Helmet>
                <title>Alle Apps / Städte - Adminbereich</title>
            </Helmet>


            <div>
                <h1>Alle Kommunen/Apps:</h1>
                {List.map((i, k) => <CityItem {...i} key={k} />)}
            </div>
        </div>
    )
}
export default AdminCityList

function CityItem({ appName, ID, fullCityName, appSlug }) {
    return (
        <div className='mb-5'>
            <h2>{appName}</h2>
            <p>
                <b>CityId:</b> {ID}<br />
                <b>App für:</b> {fullCityName}<br />
                <b>Slug:</b> {appSlug}
            </p>

            <Link to={`/admin/city/${appSlug}/districts`}>
                <button>Ortsteile verwalten</button>
            </Link>
        </div>
    )
}