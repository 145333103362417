import React, { useEffect, useState } from "react"
import "./AiButton.scss"

function AiButton({ children, onClick }) {
    return (
        <div className="AiButton" onClick={onClick}>
            <i className="fa-solid fa-sparkles" />
            <span>{children}</span>
        </div>
    )
}
export default AiButton