import Video_EmulatorScreen from "../../components/defaults/emulator/screens/Video_EmulatorScreen";
import IFrameEditPage from "../../pages/editor/IFrame/IFrameEditPage";
import CropperPage from "../../pages/editor/Images/CropperPage";
import EventsPage from "../../pages/event/EventsPage";
import ContinueOnMobile from "../../pages/more/ContinueOnMobile";
import { corona, extras, kontaktdaten, rechtliches, sync, wichtig, öffnungszeiten } from "./fields";

export const categorys = [
    {
        title: "Allgemein",
        items: [
            {
                title: "Wichtig",
                icon: "home",
                uri: `/wichtig`,
                fields: wichtig,
            },
            {
                title: "Kontaktdaten",
                icon: "home",
                uri: `/kontakt`,
                fields: kontaktdaten,
            },
            {
                title: "Bilder",
                icon: "home",
                uri: "/bilder",
                component: (props) => <CropperPage EntryID={props.entryData.meta.entryID} update={props.update} />
            },
            {
                title: "Öffnungszeiten",
                icon: "list",
                uri: `/öffnungszeiten`,
                fields: öffnungszeiten,
            },
            {
                title: "Extras",
                uri: `/extras`,
                fields: extras,
            },

        ],
    },
    {
        title: "Inhalte",
        items: [
            {
                title: "Veranstaltungen",
                icon: "home",
                uri: `/veranstaltungen`,
                hideEmulator: true,
                component: (props) => <EventsPage {...props} />,
            },
            // {
            //     title: "Beiträge",
            //     icon: "home",
            //     uri: `/beiträge`,
            //     label: "Mobil",
            //     emulator: {
            //         page: Video_EmulatorScreen,
            //         props: { videoSrc: process.env.PUBLIC_URL + "/assets/video/loginOnMobileVideo.mp4" },
            //         live: false,
            //         hideStatusbar: true,
            //     },
            //     component: (props) => <ContinueOnMobile
            //         {...props}
            //         functionName="Beiträge"
            //         smallText={<a target="_blank" href="https://dashpoll.net/">Powered by dashpoll;</a>}
            //     />,
            // },
            {
                title: "Gutscheine",
                icon: "list",
                uri: `/gutscheine`,
                label: "Mobil",
                emulator: {
                    page: Video_EmulatorScreen,
                    props: { videoSrc: process.env.PUBLIC_URL + "/assets/video/loginOnMobileVideo.mp4" },
                    live: false,
                    hideStatusbar: true,
                },
                component: (props) => <ContinueOnMobile
                    {...props}
                    functionName="Gutscheine"
                    smallText={<>Sie möchten viele Gutscheine über eine Schnittstelle einpflegen. Kontaktieren Sie gerne unseren <a href="https://dashdesign.eu" target="_blank">Vertrieb</a>.</>}
                />,
                // children: <p>G 1</p>
            },
            // {
            //     title: "Werbebanner",
            //     // label: "Bald verfügbar",
            //     uri: `/werbebanner`,
            //     emulator: {
            //         page: Image_EmulatorScreen,
            //         props: { src: process.env.PUBLIC_URL + "/assets/image/screenshotWerbebanner.jpg", alt: "" },
            //         live: false,
            //         hideStatusbar: true,
            //     },
            // },
        ],
    },
    {
        title: "Erweitert",
        items: [
            {
                title: "Corona",
                uri: `/corona`,
                fields: corona,
                label: "Temporär"
            },
            // {
            //     title: "Angebot",
            //     uri: `/angebot`,
            //     fields: products,
            // },
            // {
            //     title: "Team",
            //     uri: `/team`,
            //     fields: team,
            // },
            // {
            //     title: "Tochter-/Muttereinträge",
            //     uri: `/erweitert`,
            //     label: "NEU",

            // },
            // {
            //     title: "Benachrichtigungen",
            //     uri: `/Benachrichtigungen`,
            // },
        ],
    },
    {
        title: "Erweitert",
        items: [
            {
                title: "Rechtliches",
                uri: `/rechtliches`,
                fields: rechtliches,
            },
            {
                title: "Corporate Design",
                tip: "Das sogenannte „Corporate Design“ ist ein wichtiges Marketinginstrument, welches Ihren Wiedererkennungswert enorm steigert. Passen Sie Ihr Eintrag so an, dass dieser in Ihrem Design dargestellt wird.",
                uri: `/design`,
                // fields: design,
            },
            {
                title: "Website synchronisation",
                uri: `/website-sync`,
                fields: sync,
                hideEmulator: true,
                component: (props) => <IFrameEditPage entryID={props.entryData.meta.entryID} />
            },
            {
                title: "Benutzer:innen-Verwaltung",
                hideEmulator: true,

                uri: `/zugriff`,
            },
        ]
    }
]
