import React from "react"
import { regexUrl } from "../../../const/regex"
import ValidateInput from "./ValidateInput"
import "./WebsiteInput.scss"

function WebsiteInput(props) {
    let regex = regexUrl
    return (
        <div className="WebsiteInput">
            <ValidateInput {...props} regex={regex} />
        </div>
    )
}
export default WebsiteInput