export const businessCategoryList = [
    "3D-Druckservice",
    "Abenteuersportarten",
    "Abenteuersportcenter",
    "Abgasuntersuchungsstelle",
    "Abrissunternehmen",
    "Abschleppdienst",
    "Abundant Life Church",
    "Abwasserentsorgungsdienst",
    "Achterbahn",
    "Acura-Händler",
    "Adoptionsvermittlung",
    "Aero-Dance-Kurs",
    "Afghanisches Restaurant",
    "Afrikanisches Restaurant",
    "Agentur für Gewerbeimmobilien",
    "Agentur für Hausratsversicherungen",
    "Agrargenossenschaft",
    "Agrotourismus",
    "Aikido-Schule",
    "Aikidoklub",
    "Airbrush-Service",
    "Airbrushbedarf",
    "Aktenarchivierungseinrichtung",
    "Aktenvernichtungsdienst",
    "Akupunkteur",
    "Akupunkturklinik",
    "Akupunkturschule",
    "Akustikberater",
    "Akzidenzdruckerei",
    "Alfa-Romeo-Händler",
    "Allergologe",
    "Allgemeinkrankenhaus",
    "Allgemeinmediziner",
    "Alliance Church",
    "Allround-Handwerker",
    "Altenpflege",
    "Altwarenhändler",
    "Aluminiumanbieter",
    "Aluminiumfenster",
    "Aluminiumrahmenanbieter",
    "Ambulanter Pflegedienst",
    "Ambulanz",
    "Amerikanisches Restaurant",
    "Amish-Möbelgeschäft",
    "Amtlich zugelassener Buch- und Rechnungsprüfer",
    "Anago-Restaurant",
    "Anbieter für die Metallindustrie",
    "Anbieter von Angelausflügen",
    "Anbieter von Ausrüstung für behinderte Menschen",
    "Anbieter von Autoalarmanlagen",
    "Anbieter von Betriebseinrichtung",
    "Anbieter von Bohrausrüstung",
    "Anbieter von Diabetikerbedarf",
    "Anbieter von Elektronikbauteilen",
    "Anbieter von Expertendiensten",
    "Anbieter von Fahrgeschäftsausrüstung",
    "Anbieter von Geschmacksstoffen und Aromen",
    "Anbieter von Haushaltsgeräteteilen",
    "Anbieter von Hubschraubertouren",
    "Anbieter von Häusern in Modulbauweise",
    "Anbieter von Industriestaubsaugern",
    "Anbieter von Kabelfernsehen",
    "Anbieter von Lkw-Aufsätzen",
    "Anbieter von Maschinen, Apparaten und Komponenten für die Lebensmittelindustrie",
    "Anbieter von Messgeräten",
    "Anbieter von Metallpressverfahren",
    "Anbieter von Mobilitätsausrüstung",
    "Anbieter von Physiotherapiegeräten",
    "Anbieter von Rechtstitleversicherungen",
    "Anbieter von Sicherheitssystemen",
    "Anbieter von Solar-Warmwasseranlagen",
    "Anbieter von Videokonferenzeinrichtungen",
    "Anbieter von Wasserenthärtungsanlagen",
    "Anbieter von Wasserkulturanlagen",
    "Anbieter von Zahnimplantaten",
    "Anbieter von audiovisuellen Geräten",
    "Anbieter von individuellen Eigenheimen",
    "Anbieter von juristischen Dienstleistungen",
    "Andachtsstätte",
    "Andalusisches Restaurant",
    "Angelgeschäft",
    "Angelverein",
    "Anglersteg",
    "Anglikanische Kirche",
    "Anhängerbedarf",
    "Anhängerhersteller",
    "Anhängerhändler",
    "Anhängerreparaturwerkstatt",
    "Anhängervermietung",
    "Animeklub",
    "Anlageberatung",
    "Anrufbeantwortungsdienst",
    "Antennendienst",
    "Antiquariat",
    "Antiquitätengeschäft",
    "Antriebswellengeschäft",
    "Anwalt",
    "Anwalt für Arbeitsrecht",
    "Anwalt für Erbrecht",
    "Anwalt für Immobilienrecht",
    "Anwalt für Medizinrecht",
    "Anwalt für Seniorenrecht",
    "Anwalt für Sozialversicherungsrecht",
    "Anwalt für Steuerrecht",
    "Anwalt für Verwaltungsrecht",
    "Anwaltskanzlei",
    "Anwaltsvermittlung",
    "Anästhesist",
    "Apartmenthaus",
    "Apostolische Kirche",
    "Apotheke",
    "Apotheke für Tiermedikamente",
    "Aquakulturfarm",
    "Aquariumgeschäft",
    "Arbeiterklub",
    "Arbeitsamt",
    "Arbeitsschutzdienst",
    "Arbeitsvermittlung",
    "Architekt",
    "Architektenkammer",
    "Architekturschule",
    "Archäologisches Museum",
    "Argentinisches Restaurant",
    "Armeegebäude",
    "Armeekasernen",
    "Armenische Kirche",
    "Armenisches Restaurant",
    "Aromatherapieanbieter",
    "Aromatherapiebedarf",
    "Aromatherapiekurs",
    "Arzneimittelteststelle",
    "Arzt",
    "Arzt für Arbeitsmedizin",
    "Arzt für Fruchtbarkeitsuntersuchungen",
    "Arzt für Infektionskrankheiten",
    "Arzt für Naturheilkunde",
    "Arzt für Neugeborene",
    "Arzt für Schmerztherapie",
    "Arzt für ganzheitliche Medizin",
    "Arztassistent",
    "Arztpraxis",
    "Arztüberweisungsdienst",
    "Asbestuntersuchungsdienst",
    "Asia-Shop",
    "Asiatisches Haushaltswarengeschäft",
    "Asiatisches Restaurant",
    "Asphaltmischwerk",
    "Assemblies of God Church",
    "Assistante Maternelle",
    "Aston-Martin-Händler",
    "Astrologe",
    "Asturisches Restaurant",
    "Audi-Händler",
    "Audiologe",
    "Auffanglager für Immigranten",
    "Auftragnehmer",
    "Auftragnehmer für Marmorarbeiten",
    "Auftragnehmer für Stuckarbeiten",
    "Auftragnehmer für den Innenausbau",
    "Aufzugservice",
    "Augenarzt",
    "Augenklinik",
    "Augenoptiker",
    "Augenoptikergeschäft",
    "Auktionshaus",
    "Ausbildungszentrum",
    "Ausländisches Konsulat",
    "Ausrüstung für die Nahrungsmittelverarbeitung",
    "Ausrüstungsverleih",
    "Aussichtsplattform",
    "Ausstellungs- und Handelszentrum",
    "Austernanbieter",
    "Austernrestaurant",
    "Australisches Restaurant",
    "Auto-Leasingservice",
    "Auto-Maschinenwerkstatt",
    "Auto-Tuning-Werkstatt",
    "Autoauktionshaus",
    "Autoelektrikdienst",
    "Autoglaswerkstatt",
    "Autohändler",
    "Autokarosseriebauer",
    "Autokino",
    "Autokühler-Reparaturdienst",
    "Automakler",
    "Automat für Hautpflegeprodukte",
    "Automat für elektronische Geräte",
    "Automat mit Kosmetikprodukten",
    "Automatenvideothek",
    "Automatenvideothek für Videospiele",
    "Automationsunternehmen",
    "Automobilclub",
    "Automobillager",
    "Automobilwerk",
    "Autopolsterei",
    "Autorennbahn",
    "Autorestaurierung",
    "Autoschiebedach-Werkstatt",
    "Autoteilehersteller",
    "Autovermietung",
    "Autoverwertung",
    "Autowerkstatt",
    "Autowäsche",
    "Außenreitanlage",
    "Ayurveda-Klinik",
    "BMW-Händler",
    "BMW-Motorradhändler",
    "BMX-Bahn",
    "BMX-Park",
    "Babyausstattungsgeschäft",
    "Babyschwimmschule",
    "Backbedarf",
    "Bademodengeschäft",
    "Badesee",
    "Badisches Restaurant",
    "Badmintonfeld",
    "Badmintonverein",
    "Bagelshop",
    "Baggerunternehmen",
    "Bahnanlagenanbieter",
    "Bahnunternehmen",
    "Bahá'í Haus der Andacht",
    "Bakso-Restaurant",
    "Balinesisches Restaurant",
    "Ballettschule",
    "Ballonfahrtenanbieter",
    "Bangladeschisches Restaurant",
    "Bankettsaal",
    "Banner- und Schilderhandel",
    "Baptistische Kirche",
    "Bar & Grillrestaurant",
    "Bar mit Animierdamen",
    "Bar mit Livemusik",
    "Bar mit Rohschinkenspeisen",
    "Bar-Tabac",
    "Barbecuebereich",
    "Barbecuerestaurant für Innereien",
    "Baseballfeld",
    "Baseballgeschäft",
    "Baseballverein",
    "Basilika",
    "Basis der Streitkräfte der Vereinigten Staaten",
    "Basketballfeld",
    "Basketballverein",
    "Baskisches Restaurant",
    "Bastelgeschäft",
    "Batak-Restaurant",
    "Bauausrüstungsverleih",
    "Bauberater",
    "Bauernmarkt",
    "Baufachhandel",
    "Bauführerverband",
    "Baugutachter",
    "Bauingenieur",
    "Bauinspektor",
    "Baukalkulator",
    "Baumarkt",
    "Baumaschinenhandel",
    "Baumaschinenhandlung",
    "Baumaschinenvermietung",
    "Baumschule",
    "Baumwollspinnerei",
    "Baumzucht",
    "Bauprojektentwickler",
    "Bausparkasse",
    "Baustoffhandel",
    "Bautischlerei",
    "Bauunternehmen",
    "Bauunternehmen für Basketball-Spielfelder",
    "Bayerisches Restaurant",
    "Beachvolleyball-Verein",
    "Beachvolleyballfeld",
    "Bedachungsfachhandel",
    "Befestigungsmittelanbieter",
    "Beförderungs- und Begleitservice",
    "Behindertentransportdienst",
    "Behörde",
    "Behörde für Veteranenangelegenheiten",
    "Behörde für öffentliche Sicherheit",
    "Bekleidungsgeschäft",
    "Bekleidungsgeschäft für Jugendliche",
    "Bekleidungsgeschäft für Übergroßen",
    "Beleuchtungsgeschäft",
    "Belgisches Restaurant",
    "Bentley-Händler",
    "Berater",
    "Berater für audiovisuelle Anwendungen",
    "Berater für die australische Rente (Superannuation)",
    "Berater für internationalen Handel",
    "Bereich für Geländefahrten",
    "Bergbahn",
    "Bergbauausrüstung",
    "Bergbauingenieur",
    "Bergbauunternehmen",
    "Berghütte",
    "Bergwerk",
    "Berufsberatung",
    "Berufsschule",
    "Besprechungsplanungsdienst",
    "Bestattungsinstitut",
    "Besteckfachhandel",
    "Besucherzentrum",
    "Betawi-Restaurant",
    "Betonbauunternehmen",
    "Betonlieferant",
    "Betonwerk",
    "Betrieb für Baumarbeiten",
    "Betrieb für Fassadenverkleidungen",
    "Betriebshof",
    "Betriebswirtschaftlicher Dienst",
    "Bettenfachgeschäft",
    "Bettwäsche-Fachgeschäft",
    "Bewährungshilfe",
    "Bezirksregierungsstelle",
    "Bezirksrichter",
    "Bezirksverwaltung",
    "Bibliothek",
    "Biergarten",
    "Bierhandlung",
    "Biervertrieb",
    "Bikram-Yoga-Studio",
    "Bilderrahmengeschäft",
    "Bildungsberater",
    "Bildungseinrichtung",
    "Bildungsministerium",
    "Bildungstestservice",
    "Bildungszentrum",
    "Billardhalle",
    "Bingohalle",
    "Bio-Landwirtschaftsbetrieb",
    "Biofeedback-Therapeut",
    "Bioladen",
    "Biorestaurant",
    "Biotechnologieunternehmen",
    "Biryani-Restaurant",
    "Blechanbieter",
    "Blockhausbauer",
    "Blockhäuser",
    "Blues-Club",
    "Blumen-Großhändler",
    "Blumengeschäft",
    "Blumenmarkt",
    "Blutbank",
    "Blutspendezentrum",
    "Bocciabahn",
    "Bodenleger",
    "Bodennachbearbeitungs-Dienst",
    "Bodenuntersuchungsdienst",
    "Bodypiercing-Studio",
    "Bogenschießanlage",
    "Bogenschießhalle",
    "Bogenschießveranstaltung",
    "Bogenschützenbedarf",
    "Bogenschützenverein",
    "Bohrunternehmen",
    "Bootcamp",
    "Bootplanenanbieter",
    "Bootsanhängerhändler",
    "Bootsbauer",
    "Bootshändler",
    "Bootslagerung",
    "Bootslehrer",
    "Bootsrampe",
    "Bootstourenanbieter",
    "Bootsverein",
    "Bootsverleih",
    "Bootswerkstatt",
    "Bootszubehörhändler",
    "Botanischer Garten",
    "Botschaft",
    "Bowlingbahn",
    "Bowlingbedarf",
    "Bowlingklub",
    "Boxclub",
    "Boxklub",
    "Boxring",
    "Brandschaden-Sanierungsdienst",
    "Brandschutzanlagen-Anbieter",
    "Brandschutzberater",
    "Brandschutzdienst",
    "Brasilianische Pastelaria",
    "Brasilianisches Restaurant",
    "Brauerei",
    "Brauereischänke",
    "Brauhaus",
    "Brautmodengeschäft",
    "Bremsenspezialist",
    "Brennholzhändler",
    "Brezelgeschäft",
    "Bridge-Club",
    "Briefkastenanbieter",
    "Briefmarkenhändler",
    "Briefumschlaganbieter",
    "Bringdienst für Essen",
    "Britisches Restaurant",
    "Brunch-Restaurant",
    "Brunnenbauer",
    "Brunnenbohrunternehmen",
    "Brücke",
    "Bubble-Tea-Laden",
    "Buchbinderei",
    "Buchhalter",
    "Buchhaltungsdienst",
    "Buchhandlung",
    "Buchverlag",
    "Buddhistischer Tempel",
    "Buffet-Restaurant",
    "Bugatti-Händler",
    "Buick-Händler",
    "Bulgarisches Restaurant",
    "Bundesarbeitsministerium",
    "Bundesbehörde",
    "Bundeskreditgenossenschaft",
    "Bundespolizei",
    "Buntglasstudio",
    "Burgerrestaurant",
    "Burmesisches Restaurant",
    "Burrito-Restaurant",
    "Bus und Car Unternehmen",
    "Busdepot",
    "Busfahrkartenagentur",
    "Business School",
    "Business-to-Business-Dienst",
    "Busreiseunternehmen",
    "Busunternehmen",
    "Busvermietung",
    "Butsudan-Geschäft",
    "Bäckerei",
    "Bäckereiausrüstung",
    "Bäderstudio",
    "Börse",
    "Börsenmakler",
    "Bühne",
    "Bühnenbeleuchtungsanlagen-Anbieter",
    "Bürgerinformationsbüro",
    "Bürgervereinigung",
    "Büro der Landesregierung",
    "Büro für rechtliche Angelegenheiten",
    "Büroausstattungsanbieter",
    "Büroausstattungsvermietung",
    "Büroflächenvermietung",
    "Büromöbelhändler",
    "CD-Geschäft",
    "Cadillac-Händler",
    "Café",
    "Café mit Frucht- und Süßspeisen",
    "Cajun-Restaurant",
    "Call Center",
    "Calvary Chapel Church",
    "Campingausrüster",
    "Campinghütte",
    "Campingplatz",
    "Cannabisladen",
    "Carport- und Laubenbau",
    "Carsharing-Stellplatz",
    "Catering",
    "Champon-Nudelrestaurant",
    "Chanko-Restaurant",
    "Charter School",
    "Cheesesteak-Restaurant",
    "Chemiewerk",
    "Cheminéebauer",
    "Chemische Reinigung",
    "Chevrolet-Händler",
    "Chicken-Wings-Restaurant",
    "Chilenisches Restaurant",
    "Chinesisch-Sprachschule",
    "Chinesischer Supermarkt",
    "Chinesisches Abholrestaurant",
    "Chinesisches Gebäck",
    "Chinesisches Nudelrestaurant",
    "Chinesisches Restaurant",
    "Chinesisches Restaurant mit Lieferservice",
    "Chiropraktiker",
    "Chirurg",
    "Chirurgiebedarf",
    "Chirurgische Klinik",
    "Chophouse-Restaurant",
    "Chor",
    "Christbaumzucht",
    "Christkindlmarkt",
    "Christliche Buchhandlung",
    "Christliche Kirche",
    "Chrysler-Händler",
    "Ciderbar",
    "Citroën-Händler",
    "Co-Working-Fläche",
    "Coaching Center",
    "Cocktailbar",
    "Coffeeshop",
    "Comedy-Club",
    "Comiccafé",
    "Comicladen",
    "Computergeschäft",
    "Computerreparaturdienst",
    "Computerschule",
    "Computerservice",
    "Computersicherheitsdienst",
    "Computerspieleladen",
    "Computersupport und -dienste",
    "Confiserie",
    "Containeranbieter",
    "Containerdienst",
    "Copyshop",
    "Cosplay-Café",
    "Costa-ricanisches Restaurant",
    "Country-Klub",
    "Couscousrestaurant",
    "Craniosakraltherapie",
    "Cricketclub",
    "Cricketgeschäft",
    "Cricketplatz",
    "Cupcakeladen",
    "Curlinghalle",
    "Curlingverein",
    "DJ",
    "DS-Automobiles-Händler",
    "DVD-Geschäft",
    "Dachdecker",
    "Dachfensterbau und -installationsbetrieb",
    "Dachrinnen-Reinigungsservice",
    "Daihatsu-Händler",
    "Damen- und Herrenfriseur",
    "Damenmodengeschäft",
    "Damenschneider",
    "Dampfbrötchenladen",
    "Dampfschiffrestaurant",
    "Dan-Dan-Nudelrestaurant",
    "Dartbar",
    "Dartbedarf",
    "Datenrettungsdienst",
    "Day Spa",
    "Delikatessengeschäft",
    "Denkmalpflege",
    "Dentalhygienefachkraft",
    "Dentallabor",
    "Depot",
    "Designagentur",
    "Desktop-Publishing-Dienst",
    "Desserterie",
    "Dessertrestaurant",
    "Dessousgeschäft",
    "Dessousgroßhändler",
    "Deutsch-Sprachschule",
    "Deutsches Restaurant",
    "Diabeteszentrum",
    "Diabetologe",
    "Diagnose-Center",
    "Dialysezentrum",
    "Diamantenhändler",
    "Diamanthändler",
    "Dienst für Haarentfernung per Elektrolyse",
    "Dienst für die Lebenslauferstellung",
    "Dienst für professionelle Autopflege",
    "Dienst für technische Zeichnungen",
    "Dienst für ärztliche Bescheinigungen",
    "Dienst- und Hilfsorganisation für Menschen mit Behinderung",
    "Dienstleister im Bereich technisches Zeichnen",
    "Dienstleistungen im Bereich von sanitären Anlagen",
    "Dieselmotorhändler",
    "Digitaldruckerei",
    "Dim-Sum-Restaurant",
    "Dinner-Theater",
    "Discounter",
    "Diskothek",
    "Dockbauer",
    "Dodge-Händler",
    "Dojo-Restaurant",
    "Dom",
    "Dominikanisches Restaurant",
    "Donut-Shop",
    "Down-Home-Cooking-Restaurant",
    "Drachenfachgeschäft",
    "Drechsler",
    "Dreherei",
    "Driving Range",
    "Drogerie",
    "Druckbildanbieter",
    "Druckerei",
    "Druckerreparaturservice",
    "Duty-free-Shop",
    "Dämmstoff-Fachhandel",
    "Dänisches Restaurant",
    "Düngemittelanbieter",
    "E-Commerce-Dienst",
    "E-Werk",
    "E-Zigaretten-Geschäft",
    "EDV-Berater",
    "Ecuadorianisches Restaurant",
    "Eierlieferant",
    "Ein-Euro-Laden",
    "Einberufungsbüro",
    "Einkaufszentrum",
    "Einkommensteuer-Hilfeverein",
    "Einladungsdruckdienst",
    "Einrichtung für Alkoholentzugstherapie",
    "Einrichtung für betreutes Wohnen",
    "Einwohneramt",
    "Einzelhandelsmonopol für alkoholische Getränke",
    "Einäscherungsdienst",
    "Eisanbieter",
    "Eiscafé",
    "Eisdiele",
    "Eisenbahngesellschaft",
    "Eisenbahnmuseum",
    "Eisenwarenhändler",
    "Eishockeyspielfeld",
    "Eishockeyverein",
    "Eislaufverein",
    "Eissporthalle",
    "Eklektisches Restaurant",
    "Elektriker",
    "Elektrofachgeschäft",
    "Elektrofachmarkt",
    "Elektrogeräte-Großhändler",
    "Elektrogroßhandel",
    "Elektroinstallationsdienst",
    "Elektroreparaturgeschäft",
    "Elektrotechniker",
    "Elternverband",
    "Endodontologe",
    "Endokrinologe",
    "Energieausrüstung und -lösungen",
    "Englisch-Sprachcamp",
    "Englisch-Sprachschule",
    "Englisches Restaurant",
    "Entbindungsklinik",
    "Entsalzungsanlage",
    "Entsorgungsunternehmen",
    "Episkopalkirche",
    "Erdbauunternehmen",
    "Ergotherapeut",
    "Eritreisches Restaurant",
    "Erlebnisbad",
    "Ernährungsberater",
    "Erotische Massagen",
    "Escape-Room-Zentrum",
    "Esoterikladen",
    "Espressobar",
    "Essensausgabe",
    "Ethnisches Restaurant",
    "Ethnologisches Museum",
    "Etikettendruckerei",
    "Evangelische Kirche",
    "Eventmanagement-Firma",
    "Experte für Eigentumsübertragungen",
    "Exponat",
    "Extremadurisches Restaurant",
    "Eyebrowbar",
    "Fachanwalt für Personenschäden",
    "Fachgeschäft für Berufskleidung",
    "Fachgeschäft für Bettwaren",
    "Fachgeschäft für Damenmode",
    "Fachgeschäft für Fahnen",
    "Fachgeschäft für Fest- und Abendmode",
    "Fachgeschäft für Flugzeugbedarf",
    "Fachgeschäft für Grillgeräte",
    "Fachgeschäft für Haushaltsgeräte",
    "Fachgeschäft für Hochzeitsartikel",
    "Fachgeschäft für Lkw-Zubehör",
    "Fachgeschäft für Rollstühle",
    "Fachgeschäft für Schreibgeräte",
    "Fachgeschäft für Teleskope",
    "Fachgeschäft für Unterrichtsmaterialien",
    "Fachgeschäft für Waschmaschinen und Wäschetrockner",
    "Fachgeschäft für Weihnachtsartikel",
    "Fachhandel für Angelköder",
    "Fachhandel für Arbeitsbekleidung",
    "Fachhandel für Arbeitsplatten",
    "Fachhandel für Autobatterien",
    "Fachhandel für Autoradios",
    "Fachhandel für Außenbordmotoren",
    "Fachhandel für Batterien",
    "Fachhandel für Billardzubehör",
    "Fachhandel für Bodenbeläge",
    "Fachhandel für Brauzubehör",
    "Fachhandel für Bürobedarf",
    "Fachhandel für DJ-Zubehör",
    "Fachhandel für Einbruchalarmsysteme",
    "Fachhandel für Elektromotoren",
    "Fachhandel für Feuerwerksartikel",
    "Fachhandel für Geschenkverpackungen",
    "Fachhandel für Glasartikel",
    "Fachhandel für Gummierzeugnisse",
    "Fachhandel für Kaminöfen",
    "Fachhandel für Kartenmaterial",
    "Fachhandel für Kirchenbedarf",
    "Fachhandel für Kosmetikbedarf",
    "Fachhandel für Kuchendekoration",
    "Fachhandel für Küchenbedarf",
    "Fachhandel für Regale",
    "Fachhandel für Sport- und Fitnessgeräte",
    "Fachhandel für Verpackungsmaterial",
    "Fachhandel für orthopädische Schuhe",
    "Fachklinik",
    "Fachschule",
    "Fachwerkhersteller",
    "Fahrradgeschäft",
    "Fahrradklub",
    "Fahrradpark",
    "Fahrradstellplatz",
    "Fahrradständer",
    "Fahrradverleih",
    "Fahrradwerkstatt",
    "Fahrschule",
    "Fahrzeughändler",
    "Fahrzeuglackiererei",
    "Fahrzeugzubehörgeschäft",
    "Fakultät für Chemie",
    "Fakultät für Geografie und Geschichte",
    "Fakultät für Medien- und Informationswissenschaften",
    "Falafel-Restaurant",
    "Fallschirmsprungzentrum",
    "Familienberater",
    "Familienrestaurant",
    "Familientag-Pflegedienst",
    "Farbenfachgeschäft",
    "Fast-Food-Restaurant",
    "Fast-Food-Restaurant im Hongkong-Stil",
    "Faxdienst",
    "Fechthalle",
    "Fechtschule",
    "Feinkost-Metzgerei",
    "Feinkostgeschäft",
    "Felgenfachhandel",
    "Feng-Shui-Berater",
    "Feng-Shui-Laden",
    "Fensteranbieter",
    "Fensterinstallationsdienst",
    "Fensterreinigungsdienst",
    "Ferienhaus",
    "Ferienhausvermietung",
    "Ferienort/Ferienanlage",
    "Ferienpark",
    "Ferienranch",
    "Ferienunterkunftsvermittlung",
    "Ferienwohnung",
    "Fernsehgeräte-Reparaturdienst",
    "Fernsehsender",
    "Fernsprechamt",
    "Ferrari-Händler",
    "Fertigbeton-Anbieter",
    "Festhalle",
    "Festplatz",
    "Festung",
    "Feueralarmanbieter",
    "Feuerwache",
    "Feuerwehrschule",
    "Fiathändler",
    "Fiberglasanbieter",
    "Figurenladen",
    "Film- und Fotoarchiv",
    "Filteranlage",
    "Finanzabteilung der Sozialversicherungen",
    "Finanzamt",
    "Finanzberater",
    "Finanzinstitut",
    "Finanzministerium",
    "Finanzplaner",
    "Finanzpolizei",
    "Fingerabdruckdienst",
    "Finnisches Restaurant",
    "Firmencampus",
    "Fischerhütte",
    "Fischfarm",
    "Fischgeschäft",
    "Fischmarkt",
    "Fischteich",
    "Fischverarbeitung",
    "Fish & Chips-Restaurant",
    "Fitnessprogramm",
    "Fitnessstudio",
    "Flamencoschule",
    "Flamencotanz-Fachgeschäft",
    "Flamencotheater",
    "Flaschen- und Dosenrückgabezentrum",
    "Fleischgroßhändler",
    "Fleischwarenverpackungsbetrieb",
    "Fliegengitter-Fachgeschäft",
    "Fliesenfachhandel",
    "Fliesenleger",
    "Flohmarkt",
    "Floßfahrtbedarf",
    "Flug- und Landeplatz",
    "Fluggesellschaft",
    "Flughafen",
    "Flughafen-Pendeldienst",
    "Flugschule",
    "Flugzeug",
    "Flugzeughändler",
    "Flugzeugverleih",
    "Flugzeugwartungsunternehmen",
    "Fläche für Sportschützenveranstaltung",
    "Flüchtlingslager",
    "Fondue-Restaurant",
    "Food-Court",
    "Football-Spielfeld",
    "Ford-Händler",
    "Formenbauer",
    "Forschung und Produktentwicklung",
    "Forschungsinstitut",
    "Forschungsstiftung",
    "Forstamt",
    "Forstwirtschaftlicher Dienstleister",
    "Fotoautomat",
    "Fotofachgeschäft",
    "Fotograf",
    "Fotolabor",
    "Fotorestaurierungsdienst",
    "Fotoservice",
    "Fotostudio",
    "Fotovoltaik-Solarstromanlage",
    "Foursquare Church",
    "Frachtspeditionsdienst",
    "Französisch-Sprachschule",
    "Französisches Restaurant",
    "Französisches Restaurant mit Haute Cuisine",
    "Französisches Steakhaus",
    "Frauenarzt/-ärztin",
    "Frauenhaus",
    "Frauenklinik",
    "Frauenorganisation",
    "Frauenschutzdienst",
    "Freibad",
    "Freilichtmuseum",
    "Freiluftkino",
    "Freistilringen",
    "Freiwillige Feuerwehr",
    "Freiwilligenorganisation",
    "Freizeitpark",
    "Freizeitverein",
    "Freizeitzentrum",
    "Friedhof",
    "Friends Church",
    "Frisbeegolfbahn",
    "Frischkostladen",
    "Friseurbedarf",
    "Friseursalon",
    "Frozen-Yogurt-Shop",
    "Fruchtbarkeitsklinik",
    "Fränkisches Restaurant",
    "Frühstückslokal",
    "Fugu-Restaurant",
    "Full Gospel Church",
    "Fundbüro",
    "Futongeschäft",
    "Futsalfeld",
    "Futterhandlung",
    "Fußbad",
    "Fußballgeschäft",
    "Fußballplatz",
    "Fußballtraining",
    "Fußballverein",
    "Fußgängerzone",
    "Fußmassagesalon",
    "Fußpflege",
    "Fähre",
    "Führerscheinbehörde",
    "GMC-Händler",
    "GPS-Anbieter",
    "Gabelstaplerhändler",
    "Gabelstaplervermietung",
    "Galerie für Aboriginalkunst",
    "Galizisches Restaurant",
    "Garagenbauer",
    "Garagentoranbieter",
    "Gardinengeschäft",
    "Garnelenzucht",
    "Garngeschäft",
    "Garten",
    "Gartenbauer",
    "Gartencenter",
    "Gartenmöbelgeschäft",
    "Gasflaschenanbieter",
    "Gasinstallateur",
    "Gasthof",
    "Gastroenterologe",
    "Gastrokneipe",
    "Gaststättenbedarf",
    "Gasversorgungsunternehmen",
    "Gay Bar",
    "Gay Sauna",
    "Gebrauchtmotorradhändler",
    "Gebrauchtreifenhandel",
    "Gebrauchtwagenhändler",
    "Gebrauchtwarenhändler",
    "Gebrauchtwarenladen",
    "Geburtsvorbereitungskurs",
    "Gebäuderestaurierungsdienst",
    "Gedenkpark",
    "Geflügelhof",
    "Geflügelladen",
    "Gefängnis",
    "Gefäßchirurg",
    "Geheiligte Stätte",
    "Gehörlosendienst",
    "Gehörlosenschule",
    "Geigenbauer",
    "Geisterhaus",
    "Geisterstadt",
    "Geldautomat",
    "Geldtransferdienst",
    "Geldüberweisungsdienst",
    "Geländeranbieter",
    "Gemeindeverwaltungsamt",
    "Gemeindezentrum",
    "Gemeinnützige Einrichtung",
    "Gemeinnützige Organisation",
    "Gemeinschaftsgarten",
    "Gemischte Schule",
    "Gemischtwarenladen",
    "Gemmenschneider",
    "Gemälde",
    "Gemüsegroßmarkt",
    "Genealoge",
    "Generalunternehmer",
    "Genesis-Händler",
    "Georgisches Restaurant",
    "Gepäckaufbewahrung",
    "Gerberei",
    "Gerichtliche Versteigerung",
    "Gerichtsmediziner",
    "Gerichtsstenograf",
    "Gerichtszusteller",
    "Gerüstbauunternehmen",
    "Geschenkkorbgeschäft",
    "Geschenkladen",
    "Geschlossene Wohnanlage",
    "Geschäft",
    "Geschäft für Acrylprodukte",
    "Geschäft für Artikel rund um den Buddhismus",
    "Geschäft für Audiogeräte",
    "Geschäft für Babybekleidung",
    "Geschäft für Computerzubehör",
    "Geschäft für Duschtüren",
    "Geschäft für Filzstiefel",
    "Geschäft für Fleisch- und Wurstdelikatessen",
    "Geschäft für Gartenmöbel",
    "Geschäft für Gas und zugehörige Artikel",
    "Geschäft für Heimtierbedarf",
    "Geschäft für Hotelzubehör",
    "Geschäft für Kopierbedarf",
    "Geschäft für Künstlerbedarf",
    "Geschäft für Künstlerzubehör",
    "Geschäft für Milchprodukte",
    "Geschäft für Mobiltelefonzubehör",
    "Geschäft für Modeaccessoires",
    "Geschäft für Outdoor-Kleidung und ‑Ausrüstung",
    "Geschäft für Partyzubehör",
    "Geschäft für Schulbedarf",
    "Geschäft für Second-Hand-Büromöbel",
    "Geschäft für Second-Hand-CDs",
    "Geschäft für Second-Hand-Computer",
    "Geschäft für Second-Hand-Kleidung",
    "Geschäft für Second-Hand-Musikinstrumente",
    "Geschäft für Second-Hand-Möbel",
    "Geschäft für Siebdrucke",
    "Geschäft für Softdrinks",
    "Geschäft für Sportlerernährung",
    "Geschäft für Strandbekleidung",
    "Geschäft für Tauchzubehör",
    "Geschäft für Theaterbedarf",
    "Geschäft für Trockenfrüchte",
    "Geschäft für Vitaminpräparate und Nahrungsergänzungsmittel",
    "Geschäft für Waren amerikanischer Ureinwohner",
    "Geschäft für Westernkleidung",
    "Geschäft für afrikanische Waren",
    "Geschäft für antike Möbel",
    "Geschäft für antikes Einrichtungsmaterial",
    "Geschäft für australische Waren",
    "Geschäft für chinesische Medizin",
    "Geschäft für gebrauchte Autoteile",
    "Geschäft für gebrauchte Haushaltsgeräte",
    "Geschäft für gebrauchte Spiele",
    "Geschäft für getrocknete Meeresfrüchte",
    "Geschäft für indische Lebensmittel",
    "Geschäft für irische Waren",
    "Geschäft für italienische Lebensmittel",
    "Geschäft für japanische Lebensmittel",
    "Geschäft für koreanische Lebensmittel",
    "Geschäft für koschere Lebensmittel",
    "Geschäft für mexikanische Lebensmittel",
    "Geschäft für mexikanische Waren",
    "Geschäft für nicht jugendfreie DVDs",
    "Geschäft für orientalische Waren",
    "Geschäft für religiöse Artikel",
    "Geschäft für rustikale Möbel",
    "Geschäft für traditionelle Kostüme",
    "Geschäft für tropische Fische",
    "Geschäft zum Auffüllen von Druckertinte",
    "Geschäftsausstattungsanbieter",
    "Geschäftsstelle",
    "Geschäftszentrum",
    "Gesetzlich definierter Beherbergungsbetrieb",
    "Gesundheit und Sicherheit am Arbeitsplatz",
    "Gesundheitsamt",
    "Gesundheitsberater",
    "Gesundheitszentrum für Kinder",
    "Getreidemühle",
    "Getriebe-Fachhandel",
    "Getränkelieferant",
    "Gewerbegebiet",
    "Gewerblicher Immobilienprüfer",
    "Gewerblicher Kühlschrankanbieter",
    "Gewerkschaft",
    "Gewerbeverein",
    "Gewichthebebereich",
    "Gewächshaus",
    "Gewürzgeschäft",
    "Gießerei",
    "Gipser",
    "Gitarrengeschäft",
    "Gitarrenlehrer",
    "Glas-Reparaturservice",
    "Glasblockanbieter",
    "Glasbläser",
    "Glaser",
    "Glasfachhandel",
    "Glasfaser-Reparaturdienst",
    "Glashersteller",
    "Glasperlengeschäft",
    "Glasperlengroßhändler",
    "Glaswarengeschäft",
    "Glaswarengroßhändler",
    "Glutenfreies Restaurant",
    "Glücksspiellehrer",
    "Go-Kart-Bahn",
    "Goanisches Restaurant",
    "Goldhändler",
    "Goldschmied",
    "Golfclub",
    "Golflehrer",
    "Golfplatz",
    "Golfplatz-Bauunternehmen",
    "Golfshop",
    "Golfwagenhändler",
    "Gospelkirche",
    "Gourmetrestaurant",
    "Graffitientfernungsdienst",
    "Grafikdesigner",
    "Granitanbieter",
    "Graveur",
    "Grenzschützer",
    "Grenzübergangsstelle",
    "Griechisch-orthodoxe Kirche",
    "Griechisches Restaurant",
    "Grillplatz",
    "Grillrestaurant",
    "Grosshändler",
    "Großbäckerei",
    "Großhandels-Supermarkt",
    "Großhandelsmarktplatz für Bekleidung",
    "Großhändler für Elektrogeräte",
    "Großhändler für Fisch und Meeresfrüchte",
    "Großhändler für landwirtschaftliche Erzeugnisse",
    "Großmarkt",
    "Großmarkt für Mitglieder",
    "Grundbuchamt",
    "Grundschule",
    "Gruppe für darstellende Künste",
    "Grußkarten-Shop",
    "Guatemaltekisches Restaurant",
    "Gutachter",
    "Gymnasium",
    "Gymnasium in der Tschechischen Republik",
    "Gynäkologe",
    "Gyrosrestaurant",
    "Gyūdon-Restaurant",
    "Gänsestopfleber-Hersteller",
    "Gärtner",
    "Gästehaus",
    "Gästeunterkunft",
    "Günstiges japanisches Gasthaus",
    "Gürtelgeschäft",
    "HLA und HBLA",
    "HNO-Klinik",
    "Haarentfernungsstudio",
    "Haartransplantationsdienst",
    "Haartransplantationsklinik",
    "Hafenbehörde",
    "Hafenbetriebsgesellschaft",
    "Haferbreirestaurant",
    "Haitianisches Restaurant",
    "Halalrestaurant",
    "Hallengolfplatz",
    "Hallenschwimmbad",
    "Hals-Nasen-Ohren-Arzt",
    "Hammelfleisch-Grillrestaurant",
    "Handarbeitsgeschäft",
    "Handballplatz",
    "Handballverein",
    "Handchirurg",
    "Handelsentwicklung",
    "Handelsregister",
    "Handelsschule",
    "Handtaschengeschäft",
    "Handwerk",
    "Handwerkskammer",
    "Handwerksmesse",
    "Handwerksmuseum",
    "Harley-Davidson-Händler",
    "Hauptschule",
    "Hauptzollamt",
    "Hausarzt",
    "Hausboot-Vermietungsdienst",
    "Haushaltsgeräte-Kundendienst",
    "Haushaltsgerätereparaturservice",
    "Haushaltshilfe",
    "Haushaltsversicherungsmakler",
    "Haushaltswarengeschäft",
    "Hausmeisterservice",
    "Hausreinigungsdienst",
    "Haussitter",
    "Haussitteragentur",
    "Haustiertrainer",
    "Hausverwaltungsunternehmen",
    "Hautarzt",
    "Haute-Couture-Modehaus",
    "Hawaiianisches Restaurant",
    "Hawaiianisches Warengeschäft",
    "Head-Start-Zentrum",
    "Hebamme",
    "Heilpraktiker",
    "Heimatmuseum",
    "Heimkinofachhandel",
    "Heimwerkerfachhandel",
    "Heizkörper-Reparaturdienst",
    "Heizkörpergeschäft",
    "Heizungs- und Klimatechnikbetrieb",
    "Heizungsanlagenanbieter",
    "Heizungsmonteur",
    "Heizölhändler",
    "Herrenfriseur",
    "Herrenfriseurschule",
    "Herrenmodengeschäft",
    "Herrenschneider",
    "Herrnhuter Brüdergemeine",
    "Hersteller",
    "Hersteller elektronischer Geräte",
    "Hersteller von Maschinenteilen",
    "Hersteller von Verkaufsständern",
    "Hersteller von fliegenden Bauten",
    "Herzklinik",
    "Hindutempel",
    "Hispanische Kirche",
    "Historische Gesellschaft",
    "Historische Sehenswürdigkeit",
    "Historisches Museum",
    "Hoagie-Restaurant",
    "Hobbygeschäft",
    "Hochdruck-Reinigungsdienst",
    "Hochschule",
    "Hochschule für Aufbaustudien",
    "Hochschule mit Wohnheimen auf dem Campus",
    "Hochseilgarten",
    "Hochzeitsbuffet",
    "Hochzeitsfotograf",
    "Hochzeitskapelle",
    "Hochzeitsplaner",
    "Hochzeitsräumlichkeit",
    "Hochzeitsservice",
    "Hochzeitssouvenirladen",
    "Hochzeitstortenbäckerei",
    "Hockeybedarf",
    "Hockeyfeld",
    "Hockeyverein",
    "Hofladen",
    "Holzbearbeitungsbedarf",
    "Holzboden-Einbaudienst",
    "Holzhandlung",
    "Holzofengeschäft",
    "Holzrahmenanbieter",
    "Homöopath",
    "Homöopathische Apotheke",
    "Honda-Händler",
    "Honduranisches Restaurant",
    "Hospiz",
    "Host-Club",
    "Hot-Bedstone-Spa",
    "Hotdog-Restaurant",
    "Hotel Garni",
    "Hotelfachschule",
    "Hua Niao-Marktplatz",
    "Hua-Gong-Shop",
    "Hubschrauberlandeplatz",
    "Hufschmied",
    "Hunan-Restaurant",
    "Hundeausführer",
    "Hundecafé",
    "Hundepark",
    "Hundepension",
    "Hundeschule",
    "Hundetagesbetreuung",
    "Hundezüchter",
    "Hutgeschäft",
    "Hydraulikgeräte-Reparaturdienst",
    "Hygieneartikel-Großhändler",
    "Hygieneartikelladen",
    "Hypnosetherapieservice",
    "Hypothekengeber",
    "Hypothekenmakler",
    "Hyundai-Händler",
    "Hähnchenimbiss",
    "Hähnchenrestaurant",
    "Hämatologe",
    "Händler für Häuser in Modulbauweise",
    "Händler für Militärartikel",
    "Händler für gebrauchte Lkw",
    "Häuslicher Pflegedienst",
    "Höhenarbeiten",
    "Hörgeräte-Reparaturdienst",
    "Hörgeräteakustiker",
    "IMAX-Kino",
    "Ikan-Bakar-Restaurant",
    "Im- und Exportfirma",
    "Imageberater",
    "Imbiss",
    "Imkerei",
    "Immigrations- und Einbürgerungsbehörde",
    "Immobilienagentur",
    "Immobilienauktionator",
    "Immobilienberater",
    "Immobiliengutachter",
    "Immobilienmakler",
    "Immobiliensachverständiger",
    "Immobilienvermittlung",
    "Immunologe",
    "Importeur",
    "Imprägnierdienst",
    "Indian reserve (kanadisches Indianerreservat)",
    "Indianerreservat (USA)",
    "Indianisches Restaurant",
    "Indisches Restaurant",
    "Indisches Sizzler-Restaurant",
    "Indonesisches Restaurant",
    "Indoor Cycling",
    "Indoor-Snowcenter",
    "Indoorspielplatz",
    "Industrie- und Handelskammer",
    "Industrieanlagenanbieter",
    "Industriegebiet",
    "Infiniti-Händler",
    "Ingenieur",
    "Ingenieur für Festmüllverwertung",
    "Inkassobüro",
    "Inlandsflughafen",
    "Innenarchitekt",
    "Innenarchitekturbüro",
    "Insolvenzdienstleister",
    "Insolvenzverwalter",
    "Insolvenzverwalter für Immobilien",
    "Institut für Geografie und Statistik",
    "Institut für Immobilienwirtschaft",
    "Institut zur Landumverteilung",
    "Internat",
    "Internationale Schule",
    "Internationaler Flughafen",
    "Internetanbieter",
    "Internetcafé",
    "Internetladen",
    "Investmentbanking",
    "Investmentgesellschaft",
    "Irisches Restaurant",
    "Irish Pub",
    "Isländisches Restaurant",
    "Israelisches Restaurant",
    "Isuzu-Händler",
    "Italienisches Restaurant",
    "Izakaya-Restaurant",
    "Jachthafen",
    "Jachtmakler",
    "Jagd- und Angelgeschäft",
    "Jagdrevier",
    "Jagdsportgeschäft",
    "Jagdverein",
    "Jaguar-Händler",
    "Jain-Tempel",
    "Jalousiengeschäft",
    "Jamaikanisches Restaurant",
    "Japanische Delikatessen",
    "Japanische Gasthäuser",
    "Japanisches Curry-Restaurant",
    "Japanisches Restaurant",
    "Japanisches Restaurant für Schnitzelgerichte mit Misosoße",
    "Japanisches Restaurant für Zungenspezialitäten",
    "Japanisches Restaurant im Kyoto-Stil",
    "Japanisches Restaurant mit Krebsspezialitäten",
    "Japanisches Restaurant mit Seeteufelspezialitäten",
    "Japanisches Restaurant mit regionaler Küche",
    "Japanisches Steakhaus",
    "Javanisches Restaurant",
    "Jazz-Club",
    "Jeansgeschäft",
    "Jeep-Händler",
    "Jemenitisches Restaurant",
    "Jeschiwa",
    "Jiaozi-Restaurant",
    "Jiu-Jitsu-Schule",
    "Judoschule",
    "Judoverein",
    "Jugendherberge",
    "Jugendorganisation",
    "Jugendstrafanstalt",
    "Jugendzentrum",
    "Juristische Bibliothek",
    "Juristische Fakultät",
    "Juwelier",
    "Juweliergeschäft",
    "Jüdisches Restaurant",
    "Kabarettklub",
    "Kaffeeautomat",
    "Kaffeegeschäft",
    "Kaffeegroßhändler",
    "Kaffeemaschinen-Anbieter",
    "Kaffeeröster",
    "Kaffeestand",
    "Kaiseki-Restaurant",
    "Kalifornisches Restaurant",
    "Kalligrafieunterricht",
    "Kaltnudelrestaurant",
    "Kamera-Reparaturservice",
    "Kamerageschäft",
    "Kammerjäger",
    "Kampfsportbedarf",
    "Kampfsportschule",
    "Kampfsportverein",
    "Kam­bo­d­scha­nisches Restaurant",
    "Kanadisches Restaurant",
    "Kanalreinigung",
    "Kantine",
    "Kantonesisches Restaurant",
    "Kanu- und Kajak-Laden",
    "Kanu- und Kajaktourenanbieter",
    "Kanu- und Kajakverleih",
    "Kanu- und Kayakklub",
    "Kanugebiet",
    "Kapelle",
    "Kapselhotel",
    "Kapverdisches Restaurant",
    "Karaokebar",
    "Karateclub",
    "Karateschule",
    "Kardiologe",
    "Karibisches Restaurant",
    "Karma-Händler",
    "Karnevalsverein",
    "Karosserieteileanbieter",
    "Karosseriewerkstatt",
    "Kartendienst",
    "Kasachisches Restaurant",
    "Kaschmirisches Restaurant",
    "Kastilisches Restaurant",
    "Katalonisches Restaurant",
    "Kathedrale",
    "Katholische Kirche",
    "Katholische Schule",
    "Katsudon-Restaurant",
    "Katzenhostel",
    "Katzenpension",
    "Kaufhaus",
    "Kautionsurkundendienst",
    "Kebabimbiss",
    "Kegelverein",
    "Keksgeschäft",
    "Keramikhersteller",
    "Kernkraftwerk",
    "Kernspintomografiezentrum",
    "Kerzengeschäft",
    "Kfz-Ausbeulservice",
    "Kfz-Ersatzteilgeschäft",
    "Kfz-Kennzeichenservice",
    "Kfz-Prüfstelle",
    "Kfz-Reparatur und -Wartung",
    "Kfz-Versicherungsunternehmen",
    "Kia-Händler",
    "Kickboxschule",
    "Kiefermöbelhersteller",
    "Kieferorthopäde",
    "Kiesgrube",
    "Kieswerk",
    "Kimonogeschäft",
    "Kinderarzt",
    "Kinderaugenarzt",
    "Kinderbekleidungsgeschäft",
    "Kinderbetreuungseinrichtung",
    "Kinderbuchladen",
    "Kinderbücherei",
    "Kindergarten",
    "Kinderhalle",
    "Kinderheim",
    "Kinderkardiologe",
    "Kinderklinik",
    "Kinderladen",
    "Kindermuseum",
    "Kindermöbelgeschäft",
    "Kinderpartyservice",
    "Kinderparybuffet",
    "Kinderpsychiater",
    "Kinderpsychologe",
    "Kinderspielparadies",
    "Kindertagesstätte",
    "Kindertheater",
    "Kinderzahnarzt",
    "Kinesiologe",
    "Kino",
    "Kirche",
    "Kirche Jesu Christi der Heiligen der Letzten Tage",
    "Kirche des Nazareners",
    "Kirche für Taubstumme",
    "Kirchengemeinde",
    "Kirchgemeinde",
    "Kirchliche Schule",
    "Kirmes-Fahrgeschäft",
    "Klavierbauer",
    "Klaviergeschäft",
    "Klavierlehrer",
    "Klavierreparaturdienst",
    "Klavierstimmdienst",
    "Klavierumzugsdienst",
    "Kleinanhängerhändler",
    "Kleingeräte-Reparaturdienst",
    "Kleinmotor-Reparaturwerkstatt",
    "Klempner",
    "Klempnerbedarf",
    "Kletterhalle",
    "Klettern",
    "Klimaanlagenanbieter",
    "Klimaanlagengeschäft",
    "Klinik für Essstörungen",
    "Klinik für Psychopädagogik",
    "Klinik für Schwangerschaftsabbrüche",
    "Klinik für chinesische Medizin",
    "Klinik für plastische Chirurgie",
    "Kloster",
    "Kläranlage",
    "Klärgrubenservice",
    "Kneipe",
    "Knochensetzer",
    "Knotenpunkt",
    "Kochschule",
    "Koffergeschäft",
    "Kofferreparaturdienst",
    "Kolumbianisches Restaurant",
    "Kommissionsgeschäft",
    "Kommunales Gesundheitszentrum",
    "Kommunalverwaltung",
    "Konditorei",
    "Kongresszentrum",
    "Konkanisches Restaurant",
    "Konkursberatung",
    "Konservative Synagoge",
    "Konservatorium",
    "Konservenfabrik",
    "Kontaktlinsenanbieter",
    "Konzertsaal",
    "Kopiergeräte-Reparaturdienst",
    "Koreanische Kirche",
    "Koreanisches Grillrestaurant",
    "Koreanisches Restaurant",
    "Koscheres Restaurant",
    "Kosmetikerin",
    "Kosmetikgeschäft",
    "Kosmetikindustrie",
    "Kosmetikproduktanbieter",
    "Kosmetikschule",
    "Kosmetische Gesichtsbehandlungen",
    "Kostenlose Klinik",
    "Kostenloser Parkplatz",
    "Kostümgeschäft",
    "Kostümverleih",
    "Kraftfahrzeugbehörde",
    "Kraftwerksberater",
    "Kranhändler",
    "Krankenhaus",
    "Krankenhausabteilung",
    "Krankenpflegeschule",
    "Krankenversicherungsagentur",
    "Kranservice",
    "Kranvermietung",
    "Krebstherapiezentrum",
    "Kreditgenossenschaft",
    "Kreditinstitut",
    "Kreolisches Restaurant",
    "Kreuzfahrtgesellschaft",
    "Kriegsmuseum",
    "Kriminalitätsopferhilfe",
    "Kroatisches Restaurant",
    "Krocketclub",
    "Krämerladen",
    "Kräuterkundler",
    "Kräuterladen",
    "Kräutermedizinladen",
    "Kubanisches Restaurant",
    "Kulturelles Denkmal",
    "Kulturzentrum",
    "Kung-Fu-Schule",
    "Kunstgalerie",
    "Kunsthändler",
    "Kunstmuseum",
    "Kunstpflanzenanbieter",
    "Kunstpflanzengeschäft",
    "Kunstrestaurator",
    "Kunstschule",
    "Kunstspringerbecken",
    "Kunststofffenster",
    "Kunststoffhandel",
    "Kunststoffhersteller",
    "Kunstturnzentrum",
    "Kunstzentrum",
    "Kupferschmied",
    "Kurgarten",
    "Kurierdienst",
    "Kurstadt",
    "Kurzwarengeschäft",
    "Kurzwarenhandlung",
    "Kushiage- und Kushikatsu-Restaurant",
    "Kutschenfahrtdienst",
    "Käseladen",
    "Königreichssaal der Zeugen Jehovas",
    "Küchenmöbelgeschäft",
    "Küchenumbauunternehmen",
    "Kühlanlage",
    "Kühlhaus",
    "Kühlschrank-Reparaturdienst",
    "Kühlschrankgeschäft",
    "Künstler",
    "Künstleragentur",
    "Kürbisfeld",
    "Küstenwachenstation",
    "LASIK-Chirurg",
    "LPG-Umrüstung",
    "Labor",
    "Laborbedarf-Fachhandel",
    "Laden für Gesundheits- und Schönheitsprodukte",
    "Laden für japanische Süßwaren",
    "Laden für juristische Fachbücher",
    "Laden für medizinische Fachbücher",
    "Laden für preiswerte japanische Süßigkeiten",
    "Ladestation für Elektrofahrzeuge",
    "Lager",
    "Lageranbieter",
    "Lagereinrichtung zur Miete",
    "Lak Müang",
    "Lamborghini-Händler",
    "Laminierdienst",
    "Lampenreparaturdienst",
    "Lancia-Händler",
    "Land Rover-Händler",
    "Landespolizei",
    "Landesregierungsamt",
    "Landhaus",
    "Landmaschinen-Reparaturdienst",
    "Landmaschinenhändler",
    "Landmaschinenhändler für Milchviehbetriebe",
    "Landratsamt",
    "Landschaftlich schöner Ort",
    "Landschaftsarchitekt",
    "Landschaftsbaubedarf",
    "Landschaftsbeleuchtungs-Gestalter",
    "Landschaftsgestalter",
    "Landschaftsschutzgebiet",
    "Landvermesser",
    "Landvermessungsbüro",
    "Landwirtschaftliche Organisation",
    "Landwirtschaftlicher Betrieb mit Besichtigungstour",
    "Landwirtschaftsbetrieb",
    "Landwirtschaftsdienst",
    "Landwirtschaftsschule",
    "Langzeithotel",
    "Laotisches Restaurant",
    "Laser-Haarentfernungs-Service",
    "Lasergamezentrum",
    "Lateinamerikanisches Restaurant",
    "Laufsportgeschäft",
    "Lebensmittel-Großhändler",
    "Lebensmittel-Lieferservice",
    "Lebensmittelanbieter",
    "Lebensmittelhersteller",
    "Lebensmittelhändler",
    "Lebensmittelmakler",
    "Lebensversicherungsmakler",
    "Ledergroßhändler",
    "Ledermantelgeschäft",
    "Lederreinigungsdienst",
    "Lederreparaturdienst",
    "Lederwarenanbieter",
    "Lederwarengeschäft",
    "Leichenhalle",
    "Leichtathletikbahn",
    "Leistenanbieter",
    "Leitender Justizangestellter",
    "Lexus-Händler",
    "Libanesisches Restaurant",
    "Lieferant für die Lebensmittelherstellung",
    "Lieferwagenvermietung",
    "Life Coach",
    "Ligurisches Restaurant",
    "Limousinenservice",
    "Lincoln-Mercury-Händler",
    "Linoleumgeschäft",
    "Litauisches Restaurant",
    "Little-League-Spielfeld",
    "Lkw-Händler",
    "Lkw-Raststätte",
    "Lkw-Spedition",
    "Lkw-Vermietung",
    "Lkw-Werkstatt",
    "Logistikdienst",
    "Logopäde",
    "Lombardisches Restaurant",
    "Lottoannahmestelle",
    "Lounge für Reisende",
    "Luft- und Raumfahrtunternehmen",
    "Luftballongeschäft",
    "Luftballonkünstler",
    "Luftbildfotograf",
    "Luftfahrtberater",
    "Luftfahrtingenieur",
    "Luftfahrtschule",
    "Luftkompressoren-Reparaturdienst",
    "Luftkompressorenanbieter",
    "Luftschacht-Reinigungsdienst",
    "Lufttaxi",
    "Luftwaffenstützpunkt",
    "Lunchpaketanbieter",
    "Lungenspezialist",
    "Lutherische Kirche",
    "Lymphdrainagetherapeut",
    "MINI-Händler",
    "Madrilenisches Restaurant",
    "Magen-Darm-Chirurg",
    "Mah-Jongg-Haus",
    "Makrobiotisches Restaurant",
    "Malaysisches Restaurant",
    "Maler",
    "Mallorquinisches Restaurant",
    "Maltesisches Restaurant",
    "Manado-Restaurant",
    "Managementschule",
    "Mandarin-Restaurant",
    "Marinestützpunkt",
    "Marketingberater",
    "Marketingbüro",
    "Markisenanbieter",
    "Markt",
    "Markt für Autoteile",
    "Marktforschungsinstitut",
    "Marokkanisches Restaurant",
    "Maschinenbau",
    "Maschinenbauunternehmen",
    "Maschinenhersteller",
    "Maschinenwerkstatt",
    "Maserati-Händler",
    "Massage-Fachhandel",
    "Massageschule",
    "Masseur",
    "Matratzengeschäft",
    "Maurer",
    "Maurerbedarf",
    "Mausoleenbauer",
    "Mauthäuschen",
    "Mautstraßenrastplatz",
    "Maybach-Händler",
    "Mazda-Händler",
    "Maßschneider",
    "McLaren-Händler",
    "Mechaniker",
    "Mechanikwerkstatt",
    "Medical Spa",
    "Medienunternehmen",
    "Meditationszentrum",
    "Mediterranes Restaurant",
    "Medium",
    "Medizinische Fakultät",
    "Medizinische Vereinigung",
    "Medizinischer Abrechnungsdienst",
    "Medizinischer Transkriptionsdienst",
    "Medizinisches Labor",
    "Medizinisches Zentrum für bildgebende Diagnostik",
    "Medizintechnikhersteller",
    "Meeresfrüchte-Donburi-Restaurant",
    "Meeresfrüchte-Restaurant",
    "Meeresfrüchtefarm",
    "Mennonitische Kirche",
    "Mercedes-Benz Händler",
    "Messebauunternehmen",
    "Messerladen",
    "Messestand-Aufbauservice",
    "Messianische Synagoge",
    "Metallbauunternehmen",
    "Metallhandlung",
    "Metallmaschinenlieferant",
    "Metalloberflächenveredler",
    "Metallverarbeitungsunternehmen",
    "Metallwarenhersteller",
    "Metallwarenhändler",
    "Metallwerkstatt",
    "Methodistische Kirche",
    "Metro-Eisenbahngesellschaft",
    "Metzgerei",
    "Mexikanisches Restaurant",
    "Mexikanisches Torta-Restaurant",
    "Mieterverein",
    "Mietwagenanbieter",
    "Mikrowellen-Reparaturdienst",
    "Milchproduktanbieter",
    "Milchprodukte",
    "Milchviehbetrieb",
    "Militärarchiv",
    "Militärfriedhof",
    "Militärische Ausbildungsstätte",
    "Militärische Rekrutierungsstelle",
    "Militärkasernen",
    "Militärkrankenhaus",
    "Militärmuseum",
    "Militärstützpunkt",
    "Mineralien-Fachgeschäft",
    "Miniaturengeschäft",
    "Minigolfplatz",
    "Ministerium für öffentliche Sicherheit",
    "Mitbewohnervermittlungsdienst",
    "Mitsubishi-Händler",
    "Mittelschule",
    "Mitwohnzentrale",
    "Mobiler Geldautomat",
    "Mobiltelefon-Ladestation",
    "Mobiltelefongeschäft",
    "Modedesignschule",
    "Modellagentur",
    "Modellauto-Spielplatz",
    "Modellbauer",
    "Modellbaugeschäft",
    "Modellbauverein",
    "Modelleisenbahngeschäft",
    "Modellflugzeuggeschäft",
    "Modernes indisches Restaurant",
    "Modeschmuckgeschäft",
    "Mongolisches Grillrestaurant",
    "Monjayaki-Restaurant",
    "Montessorischule",
    "Mopedhändler",
    "Moschee",
    "Motorrad-Ersatzteilgeschäft",
    "Motorradfahrschule",
    "Motorradgeschäft",
    "Motorradhändler",
    "Motorradstellplatz",
    "Motorradverleih",
    "Motorradversicherung",
    "Motorradwerkstatt",
    "Motorroller-Reparaturwerkstatt",
    "Motorrollerhändler",
    "Motorrollervermietung",
    "Motorschlittenvermietung",
    "Motorsportgeschäft",
    "Motorüberholungsdienst",
    "Muay-Thai-Boxhalle",
    "Mund-, Kiefer- und Gesichtschirurg",
    "Murtabak-Restaurant",
    "Museum für Lokalgeschichte",
    "Museum für Raumfahrtgeschichte",
    "Museum für moderne Kunst",
    "Musikgeschäft",
    "Musikinstrumenten-Reparaturdienst",
    "Musikinstrumentengeschäft",
    "Musikinstrumentenverleih",
    "Musiklehrer",
    "Musikproduktion",
    "Musikschule",
    "Musikverein",
    "Musterhauszentrale",
    "Möbelgeschäft",
    "Möbelgeschäft für Bars/Restaurants",
    "Möbelgroßhändler",
    "Möbelhaus für Rohmöbel",
    "Möbelladen für Geschäfte und Supermärkte",
    "Möbelpacker",
    "Möbelreparatur-Werkstatt",
    "Möbeltischler",
    "Möbelvermietung",
    "Möbelzubehör",
    "Möbliertes Apartmenthaus",
    "Mühle",
    "Müllabfuhr",
    "Mülldeponie",
    "Münzhändler",
    "Münzschließfach",
    "Nachhilfeanbieter",
    "Nachmittagsbetreuung",
    "Nachrichtendienst",
    "Nachtclub",
    "Nachtmarkt",
    "Nagelstudio",
    "Nahrungsmittelverpackung",
    "Nasi-Goreng-Restaurant",
    "Nasi-Uduk-Restaurant",
    "Nationalbibliothek",
    "Nationales Schutzgebiet",
    "Nationales Waldgebiet",
    "Nationalmuseum",
    "Nationalpark",
    "Naturkostrestaurant",
    "Naturkundemuseum",
    "Naturschutzgebiet",
    "Natursteinhandel",
    "Navarrisches Restaurant",
    "Neapolitanisches Restaurant",
    "Neonleuchtreklameanbieter",
    "Nepalesisches Restaurant",
    "Nephrologe",
    "Neuheitenshop",
    "Neurochirurg",
    "Neurologe",
    "Neurologische Krankenhausfachabteilung",
    "Neuseeländisches Restaurant",
    "New-Age-Kirche",
    "Nicaraguanisches Restaurant",
    "Nicht konfessionsgebundene Kirche",
    "Nichtraucher-Ferienunterkunft",
    "Nichtregierungsorganisation",
    "Niederländisches Restaurant",
    "Niedrigemissionszone",
    "Nissan-Händler",
    "Nonnenkloster",
    "Nordafrikanisches Restaurant",
    "Norditalienisches Restaurant",
    "Nordostindisches Restaurant",
    "Norwegisches Restaurant",
    "Notar",
    "Notarvereinigung",
    "Notarzt",
    "Notaufnahme",
    "Notenhandel",
    "Notfallambulanz",
    "Notfalltraining",
    "Notfalltrainingszentrum",
    "Notrufzelle",
    "Nudelrestaurant",
    "Nudistenclub",
    "Nuevo-Latino-Restaurant",
    "Nussgeschäft",
    "Nutztierfutterhersteller",
    "Nähgeschäft",
    "Nähmaschinen-Reparaturdienst",
    "Nähmaschinengeschäft",
    "Oaxakanisches Restaurant",
    "Obanzai-Restaurant",
    "Obdach",
    "Obdachlosenbetreuung",
    "Obdachlosenheim",
    "Oberbekleidungsgeschäft",
    "Oberschule",
    "Oberstufe",
    "Observatorium",
    "Obst- und Gemüsehandel",
    "Obst- und Gemüsehändler",
    "Obst- und Gemüseverarbeitung",
    "Obstgarten",
    "Obstgroßhändler",
    "Oden-Restaurant",
    "Ofengeschäft",
    "Off-Track-Wettbüro",
    "Offroad-Rennstrecke",
    "Ohrlochservice",
    "Okonomiyaki-Restaurant",
    "Oldsmobile-Händler",
    "Olivenöl-Abfüllbetrieb",
    "Olivenölkooperative",
    "Onkologe",
    "Online-Marketing-Unternehmen",
    "Opel-Händler",
    "Opernensemble",
    "Opernhaus",
    "Oralchirurg",
    "Orchideenfarm",
    "Ordnungsamt",
    "Organisation der Aborigines und Torres-Strait-Insulaner",
    "Organisation für Kunst",
    "Organisationsberater",
    "Orientalisches Restaurant",
    "Orientteppichgeschäft",
    "Orthodoxe Kirche",
    "Orthodoxe Ostkirche",
    "Orthodoxe Synagoge",
    "Orthopäde",
    "Orthopädische Klinik",
    "Ostafrikanisches Restaurant",
    "Osteuropäisches Restaurant",
    "Ostjavanisches Restaurant",
    "Outdoor-Sportgeschäft",
    "Outlet-Center",
    "Outlet-Store",
    "POI - Ort von Interesse",
    "PR-Agentur",
    "Paarberater",
    "Pachinko-Salon",
    "Padang-Restaurant",
    "Padel-Tennisplatz",
    "Padel-Tennisverein",
    "Pagode",
    "Paintballanlage",
    "Paintballladen",
    "Paketkasten",
    "Pakistanisches Restaurant",
    "Pan-asiatisches Restaurant",
    "Pan-lateinamerikanisches Restaurant",
    "Pandschabi-Restaurant",
    "Papierfabrik",
    "Papierladen",
    "Papierproduktanbieter",
    "Paraguayisches Restaurant",
    "Parfümerie",
    "Park and Ride",
    "Parkhaus",
    "Parkplatz",
    "Parodontologe",
    "Parodontologe für Zahnimplantate",
    "Parsischer Tempel",
    "Parsisches Restaurant",
    "Partnervermittlung",
    "Partyausstattungsverleih",
    "Partyplaner",
    "Passamt",
    "Passfotoverarbeitung",
    "Pastaladen",
    "Patentamt",
    "Patentanwalt",
    "Pathologe",
    "Patientenhilfe",
    "Paukschule",
    "Pavillonanbieter",
    "Pecel-Lele-Restaurant",
    "Pelzgeschäft",
    "Pempek-Restaurant",
    "Pennsylvania-Dutch-Restaurant",
    "Pension",
    "Persisches Restaurant",
    "Personal Trainer",
    "Personalberatung",
    "Personalvermittler",
    "Peruanisches Restaurant",
    "Perückengeschäft",
    "Peugeot-Händler",
    "Pfadfinderheim",
    "Pfadfindersaal",
    "Pfandleihhaus",
    "Pfannkuchenrestaurant",
    "Pfarrhaus",
    "Pferdeanhängerhändler",
    "Pferdegestüt",
    "Pferdepension",
    "Pferdetrainer",
    "Pferdeverleih",
    "Pferdezüchter",
    "Pfingstgemeinde",
    "Pflanzen-Großhändler",
    "Pflegedienst",
    "Pflegeexperte",
    "Pflegeheim",
    "Pflegeverband",
    "Pflichtverteidigerbüro",
    "Pfälzer Restaurant",
    "Pharmaunternehmen",
    "Pharmazeutischer Großhandel",
    "Philharmonie",
    "Philippinisches Restaurant",
    "Pho-Restaurant",
    "Physiotherapeut",
    "Physiotherapiezentrum",
    "Pianobar",
    "Picknickstellen",
    "Piemontesisches Restaurant",
    "Pilates-Studio",
    "Pizza-Lieferdienst",
    "Pizzeria",
    "Plastischer Chirurg",
    "Plattenladen",
    "Po' Boys-Restaurant",
    "Podologe",
    "Poliklinik",
    "Politische Partei",
    "Polizeiakademie",
    "Polizeibedarf",
    "Polnisches Restaurant",
    "Poloclub",
    "Polstergeschäft",
    "Polsterreinigungsdienst",
    "Polygrafendienst",
    "Polynesisches Restaurant",
    "Pontiac-Händler",
    "Ponyclub",
    "Poolbedarf",
    "Poolbillardclub",
    "Popcornladen",
    "Porsche-Händler",
    "Porträtstudio",
    "Portugiesisches Restaurant",
    "Porzellangeschäft",
    "Postamt",
    "Postergeschäft",
    "Postfachvermietung",
    "Pozole-Restaurant",
    "Presbyterianische Kirche",
    "Presseberater",
    "Privatdetektiv",
    "Private Universität",
    "Privater Golfplatz",
    "Privates Krankenhaus",
    "Privatschule",
    "Privatzimmer",
    "Produkte und Dienstleistungen für die Gewichtsabnahme",
    "Proktologe",
    "Propangasanbieter",
    "Propeller-Fachhandel",
    "Protestantische Kirche",
    "Prothetiker",
    "Provenzalisches Restaurant",
    "Prozessanwalt",
    "Prägedienst",
    "Psychiater",
    "Psychiatrische Klinik",
    "Psychiatrische Versorgung",
    "Psychologe",
    "Psychologischer Berater",
    "Psychotherapeut",
    "Psychomtoriktherapeut",
    "Pueblanisches Restaurant",
    "Puerto-ricanisches Restaurant",
    "Pulverlackbeschichtungsdienst",
    "Puppenladen",
    "Puppenrestaurierungsdienst",
    "Puppentheater",
    "Pyrotechniker",
    "Qing-Fang-Marktplatz",
    "Quad-Händler",
    "Quad-Reparaturwerkstatt",
    "Quadverleih",
    "Quilt-Fachgeschäft",
    "Quäker-Kirche",
    "RAM-Händ­ler",
    "RSL-Klub",
    "Racletterestaurant",
    "Racquetballverein",
    "Radiologe",
    "Radiosender",
    "Radkappenanbieter",
    "Ramen-Restaurant",
    "Rasenbowlingverein",
    "Rasenmäher-Fachhandel",
    "Rasenmäher-Reparaturdienst",
    "Rasenpflegedienst",
    "Rasensprengeranlagen-Anbieter",
    "Rastplatz",
    "Rathaus",
    "Rattanmöbelgeschäft",
    "Raumausstatter",
    "Rechtsanwalt",
    "Rechtsanwalt für Einwanderungsrecht",
    "Rechtsanwalt für Familienrecht",
    "Rechtsanwalt für Insolvenzrecht",
    "Rechtsdienstleistungen",
    "Rechtsservice für Bagatellschäden",
    "Recycling-Abgabestelle",
    "Recyclinghof",
    "Reflexologe",
    "Reformhaus",
    "Reformierte Kirche",
    "Reformsynagoge",
    "Regentonnenanbieter",
    "Regierungsbehörde",
    "Regierungsbüro der japanischen Präfektur",
    "Regionaler Flughafen",
    "Registrierungsamt",
    "Rehaklinik",
    "Reifengeschäft",
    "Reihenhauskomplex",
    "Reiki-Therapeut",
    "Reinigungsdienst",
    "Reinigungsmittelanbieter",
    "Reisebüro",
    "Reisgroßhändler",
    "Reiskuchenladen",
    "Reisladen",
    "Reismühle",
    "Reiswaffelgeschäft",
    "Reiterhof",
    "Reiterverein",
    "Reitgeschäft",
    "Reitparcours",
    "Reitschule",
    "Reitsportanlage",
    "Reitsportgeschäft",
    "Religionsbuchladen",
    "Religiöse Institution",
    "Religiöse Organisation",
    "Religiöse Schule",
    "Religiöser Ort",
    "Renaulthändler",
    "Rennstrecke",
    "Rennwagen-Ersatzteilgeschäft",
    "Rennwagen-Händler",
    "Reparaturdienst für Waschmaschinen und Wäschetrockner",
    "Reparaturdienst für audiovisuelle Geräte",
    "Reparaturladen für Mobiltelefone",
    "Reparaturservice für Klimaanlagen",
    "Reparaturservice für Wasserbetten",
    "Reparaturservice für Öfen",
    "Reparaturwerkstatt für Dieselmotoren",
    "Reparaturwerkstatt für Elektrogeräte",
    "Reparaturwerkstatt für Elektromotoren",
    "Reproduktionsklinik",
    "Reptiliengeschäft",
    "Restaurant für Eintöpfe mit Innereien",
    "Restaurant für Fleischgerichte",
    "Restaurant für Rindfleisch nach koreanischer Art",
    "Restaurant für Rippchen nach koreanischer Art",
    "Restaurant für japanische Süßigkeiten",
    "Restaurant für pazifische Küche",
    "Restaurant mit Essen zum Mitnehmen",
    "Restaurant mit Fusionsküche",
    "Restaurant mit Küche aus Florida",
    "Restaurant mit Küche aus Neuengland",
    "Restaurant mit Küche aus Que­bec",
    "Restaurant mit Küche aus Sri Lanka",
    "Restaurant mit Küche aus Südwestfrankreich",
    "Restaurant mit Küche aus den italienischen Marken",
    "Restaurant mit Küche der Berry-Region",
    "Restaurant mit Küche der Chesapeake-Region",
    "Restaurant mit Küche der Mittelatlantikregion (USA)",
    "Restaurant mit Küche der Seychellen",
    "Restaurant mit Küche der Südstaaten (USA)",
    "Restaurant mit Küche des Südwestens (USA)",
    "Restaurant mit Küche des pazifischen Nordwestens (Kanada)",
    "Restaurant mit Küche des pazifischen Nordwestens (USA)",
    "Restaurant mit Shanghaier Küche",
    "Restaurant mit Spezialitäten aus Chettinad",
    "Restaurant mit Spezialitäten aus Karnataka",
    "Restaurant mit Sushiförderband",
    "Restaurant mit asiatischem Fondue (Feuertopf)",
    "Restaurant mit asiatischer Fusionsküche",
    "Restaurant mit elsässischer Küche",
    "Restaurant mit europäischer Küche",
    "Restaurant mit kantabrischer Küche",
    "Restaurant mit kleinen Gerichten zum Teilen",
    "Restaurant mit kontinentaleuropäischer Küche",
    "Restaurant mit moderner Küche aus Louisiana",
    "Restaurant mit moderner amerikanischer Küche",
    "Restaurant mit moderner britischer Küche",
    "Restaurant mit moderner europäischer Küche",
    "Restaurant mit moderner französischer Küche",
    "Restaurant mit römischer Küche",
    "Restaurant mit singapurischer Küche",
    "Restaurant mit traditioneller amerikanischer Küche",
    "Restaurant mit westlicher Küche",
    "Restaurant mit westlicher Küche in japanischem Stil",
    "Restaurant mit zentralamerikanischer Küche",
    "Restaurierungsdienst für antike Möbel",
    "Restpostenhändler",
    "Retreat-Zentrum",
    "Rettungsdienst",
    "Rheumatologe",
    "Riesenrad",
    "Rinderfarm",
    "Rockmusikklub",
    "Rohkost-Restaurant",
    "Rohranbieter",
    "Rohrmöbelgeschäft",
    "Rohschinkengeschäft",
    "Rohschinkenlager",
    "Rolls-Royce-Händler",
    "Rollstuhl-Reparaturdienst",
    "Rudergebiet",
    "Ruderverein",
    "Rugbyfeld",
    "Rugbygeschäft",
    "Rugbyligaverein",
    "Rugbyverein",
    "Rumänisches Restaurant",
    "Russisch-orthodoxe Kirche",
    "Russisches Lebensmittelgeschäft",
    "Russisches Restaurant",
    "Ryotei-Restaurant",
    "Röntgenanlagenanbieter",
    "Röntgenlabor",
    "SACEM",
    "SB-Station für Gesundheitschecks",
    "Saab-Händler",
    "Saatgutanbieter",
    "Saftbar",
    "Saisonartikelgeschäft",
    "Sakebrauerei",
    "Salat-Shop",
    "Salsabar",
    "Salsakurs",
    "Salvadorianisches Restaurant",
    "Sambaschule",
    "Sambódromo",
    "Sammelkartengeschäft",
    "Sammlergeschäft",
    "Sandaufbereitungsanlage",
    "Sandstrahldienst",
    "Sandwichladen",
    "Sanitärinspektion",
    "Sanitärtechnik",
    "Sanitätshaus",
    "Sanitätsstation",
    "Sardisches Restaurant",
    "Satay-Restaurant",
    "Sattlerei",
    "Saturn-Händler",
    "Sauna-Club",
    "Saunageschäft",
    "Schach- und Kartenklub",
    "Schalldämpfergeschäft",
    "Schaumgummihersteller",
    "Schaumstoffhändler",
    "Schauspielschule",
    "Scheckeinlösungsdienst",
    "Scheibenreparaturdienst",
    "Scheibentönungsservice",
    "Scheidungsanwalt",
    "Scheidungsservice",
    "Schießplatz",
    "Schiffbau",
    "Schifffahrtsmuseum",
    "Schiffsausrüstungsbranche",
    "Schildergeschäft",
    "Schinkenfachgeschäft",
    "Schlachthof",
    "Schlaflabor",
    "Schlafzimmermöbelgeschäft",
    "Schlagzeug- und Trommelgeschäft",
    "Schlauchanbieter",
    "Schleifdienst",
    "Schleifmittelanbieter",
    "Schlittschuh-Schleifservice",
    "Schloss",
    "Schlüsseldienst",
    "Schmied",
    "Schmuckankauf",
    "Schmuckdesigner",
    "Schmuckgraveur",
    "Schmuckgroßhandel",
    "Schmuckgutachter",
    "Schmuckreparaturdienst",
    "Schneemobilhändler",
    "Schneeräumdienst",
    "Schneiderei",
    "Schnäppchenmarkt",
    "Schokoladencafé",
    "Schokoladenfabrik",
    "Schokoladengeschäft",
    "Schokoladenmanufaktur",
    "Schornsteinfeger",
    "Schottisches Restaurant",
    "Schrankmöbelhaus",
    "Schraubenhändler",
    "Schreibdienst",
    "Schreibmaschinen-Reparaturdienst",
    "Schreibwarengeschäft",
    "Schrotthändler",
    "Schrottplatz",
    "Schuhgeschäft",
    "Schuhputzservice",
    "Schuhreparaturdienst",
    "Schulbezirksamt",
    "Schuldnerberatung",
    "Schule",
    "Schule für Rechnungswesen",
    "Schulspeisesaal",
    "Schulungscenter für Fernunterricht",
    "Schulungszentrum",
    "Schulzentrum",
    "Schuppenbauer",
    "Schwangerschaftsbetreuung",
    "Schwedisches Restaurant",
    "Schweinefarm",
    "Schweizerisches Restaurant",
    "Schweißbedarf",
    "Schweißbetrieb",
    "Schwimmbad",
    "Schwimmbecken",
    "Schwimmbecken-Reinigungsdienst",
    "Schwimmbecken-Reparaturdienst",
    "Schwimmbeckenbauer",
    "Schwimmender Markt",
    "Schwimmlehrer",
    "Schwimmverein",
    "Schwimmwettbewerb",
    "Schwulen- und Lesbenverband",
    "Schwäbisches Restaurant",
    "Schönheitsklinik",
    "Schönheitssalon",
    "Schülerheim",
    "Schützenhaus",
    "Schützenverein",
    "Scrapbooking-Geschäft",
    "Seat-Händler",
    "Second-Hand-Buchgeschäft",
    "Second-Hand-Fahrradladen",
    "Seefahrtbedarf",
    "Segelclub",
    "Segelmacher",
    "Segelschule",
    "Segelveranstaltungsrevier",
    "Sehenswürdigkeit",
    "Seidenwarengeschäft",
    "Sekundarschule",
    "Sekundarschule für Jungen",
    "Sekundarschule für Mädchen",
    "Selbstverteidigungsschule",
    "Selbstverteidigungsstreitkräfte der Marine",
    "Seminar",
    "Seniorenheim",
    "Seniorensiedlung",
    "Seniorenzentrum",
    "Serbisches Restaurant",
    "Service für die Beförderung von Tieren im Flugzeug",
    "Servicestelle für Vaterschaftstests",
    "Sexshop",
    "Sfiha-Restaurant",
    "Shabu-Shabu-Restaurant",
    "Sheriffbüro",
    "Shintō-Schrein",
    "Shisha-Bar",
    "Shishaladen",
    "Shōchū-Brauerei",
    "Shōgi-Kurs",
    "Sicherheitsdienst",
    "Sicherheitstechnikfachgeschäft",
    "Sichuanisches Restaurant",
    "Siebdruckbedarf",
    "Siebdruckerei",
    "Siebenten-Tags-Adventisten-Kirche",
    "Siegelgeschäft",
    "Singendes-Telegramm-Service",
    "Sizilianisches Restaurant",
    "Skandinavisches Restaurant",
    "Skateboardgeschäft",
    "Skateclub",
    "Skatepark",
    "Skatepark/Skatehalle",
    "Skateshop",
    "Skeet-Schießanlage",
    "Skiclub",
    "Skigebiet",
    "Skigeschäft",
    "Skischule",
    "Skivermietung",
    "Skulptur",
    "Skulpturenmuseum",
    "Smart-Händler",
    "Smokinggeschäft",
    "Snowboardgeschäft",
    "Soba-Restaurant (japanische Nudeln)",
    "Société de Flocage",
    "Sofageschäft",
    "Softairbedarf",
    "Softballclub",
    "Softballfeld",
    "Softwareentwickler/-hersteller",
    "Softwarehändler",
    "Solaranlageninstallationsservice",
    "Solartechnik-Anbieter",
    "Sommerrodelbahn",
    "Sonderschule",
    "Sonnenbrillengeschäft",
    "Sonnenschutzanlagenhändler",
    "Sonnenstudio",
    "Soto-Ayam-Restaurant",
    "Soul-Food-Restaurant",
    "Souvenirladen",
    "Sozialamt",
    "Sozialarbeiter",
    "Sozialeinrichtung",
    "Sozialministerium",
    "Sozialstation",
    "Sozialwohnungsbau-Programm",
    "Spa",
    "Spa und Fitness-Studio",
    "Spanisches Restaurant",
    "Sparkasse",
    "Speisewürzenhersteller",
    "Spezialist für Haarverlängerung",
    "Spezialklinik",
    "Spiegelgeschäft",
    "Spieldosengeschäft",
    "Spielebibliothek",
    "Spielefachhandel",
    "Spielhalle",
    "Spielkasino",
    "Spielplatz",
    "Spielwarengeschäft",
    "Spielzeugmuseum",
    "Spiritistenzentrum",
    "Spirituosengeschäft",
    "Sport-Fanartikelgeschäft",
    "Sportanlage",
    "Sportbekleidungsgeschäft",
    "Sportgeschäft",
    "Sportkartengeschäft",
    "Sportmassagetherapeut",
    "Sportmediziner",
    "Sportmedizinische Klinik",
    "Sportplatz",
    "Sportsbar",
    "Sportschule",
    "Sportstadion",
    "Sportverein",
    "Sportwarenhersteller",
    "Sprachschule",
    "Sprungfederanbieter",
    "Spureinstellungs-Service",
    "Squashanlage",
    "Squashklub",
    "Staatliche Universität",
    "Staatliches Amt für Bildung",
    "Staatliches Krankenhaus",
    "Staatliches Spirituosengeschäft",
    "Staatsanwalt",
    "Staatsanwaltschaft",
    "Staatsarchiv",
    "Staatsexamen-Ausbildungsinstitution",
    "Stadion",
    "Stadtbezirksamt",
    "Stadtplanungsamt",
    "Stadtrundfahrtenveranstalter",
    "Stadtverwaltung",
    "Stadtwerke",
    "Stadtwäldchen",
    "Stahlbauunternehmen",
    "Stahlhersteller",
    "Stahlhändler",
    "Stall",
    "Stammeshauptverwaltung",
    "Standesamt",
    "Statiker",
    "Station für innere Medizin",
    "Statuen",
    "Staubsauger-Reparaturwerkstatt",
    "Staubsaugergeschäft",
    "Steakhaus",
    "Stehbar",
    "Steinbruch",
    "Steinhandel",
    "Steinmetz",
    "Steinrelief",
    "Stempelgeschäft",
    "Stereoanlagen-Reparaturdienst",
    "Stereoanlagenverleih",
    "Steuerberater",
    "Steuerberatung",
    "Steuerschätzer",
    "Stickerei-Service",
    "Stickereifachgeschäft",
    "Stiefelgeschäft",
    "Stiefelreparaturbetrieb",
    "Stiftung",
    "Stoffgeschäft",
    "Stoffgroßhändler",
    "Stoßdämpfer-Spezialist",
    "Strafverteidiger",
    "Strandartikelgeschäft",
    "Strandbad",
    "Strandpavillon",
    "Strandreinigungsunternehmen",
    "Straßenbauunternehmen",
    "Straßenbefestigungsunternehmen",
    "Straßenradfahren",
    "Streitkräfteverbund",
    "Stripklub",
    "Stromversorgungsunternehmen",
    "Studentenvereinigung",
    "Studentenwerk",
    "Studentenwohnheim",
    "Studentenwohnservice",
    "Studienzentrum",
    "Studio für Fotomodellportfolios",
    "Stundenhotel",
    "Städtische Beschäftigungsabteilung",
    "Städtische Tourismusabteilung",
    "Städtische Verkehrsabteilung",
    "Städtisches Beurkundungsbüro",
    "Städtisches Finanzamt",
    "Städtisches Gerichtsgebäude",
    "Subaru-Händler",
    "Suchtbehandlungszentrum",
    "Sukiyaki- und Shabu-Shabu-Restaurant",
    "Sukiyaki-Restaurant",
    "Sundae-Restaurant",
    "Sundanesisches Restaurant",
    "Superfund-Altlast",
    "Supermarkt",
    "Suppenbar",
    "Suppenküche",
    "Suppenrestaurant",
    "Surfer-Lebensrettungsverein",
    "Surfschule",
    "Surfshop",
    "Surinamisches Restaurant",
    "Sushi Takeaway",
    "Sushi-Restaurant",
    "Suzuki-Händler",
    "Swimmschule",
    "Synagoge",
    "Syokudo- und Teishoku-Restaurant",
    "Syrisches Restaurant",
    "Sägenschärfdienst",
    "Sägewerk",
    "Südafrikanisches Restaurant",
    "Südamerikanisches Restaurant",
    "Südasiatisches Restaurant",
    "Süditalienisches Restaurant",
    "Südostasiatisches Restaurant",
    "Südsulawesi-Restaurant",
    "Süßigkeiten- und Desserbuffet",
    "Süßwarengeschäft",
    "Süßwarengroßhändler",
    "T-Shirt-Druck",
    "T-Shirt-Geschäft",
    "Tabakladen",
    "Tabaskanisches Restaurant",
    "Taco-Restaurant",
    "Taekwondo-Schule",
    "Taekwondoschule",
    "Tai-Chi-Schule",
    "Taiwanisches Restaurant",
    "Tako-yaki-Restaurant",
    "Tamale-Restaurant",
    "Tankstelle",
    "Tankstelle für alternative Kraftstoffe",
    "Tanzclub",
    "Tanzensemble",
    "Tanzlehrer für Gesellschaftstänze",
    "Tanzlokal",
    "Tanzpavillon",
    "Tanzschule",
    "Tanzsportartikel",
    "Taoistischer Tempel",
    "Tapas-Restaurant",
    "Tapasbar",
    "Tapetengeschäft",
    "Taschengeschäft",
    "Tatamigeschäft",
    "Tattoo- und Piercingstudio",
    "Tattoostudio",
    "Tauchclub",
    "Tauchschule",
    "Tauchtourenanbieter",
    "Tauchzentrum",
    "Taxistand",
    "Taxiunternehmen",
    "Technikmuseum",
    "Technische Universtität",
    "Technischer Berater",
    "Technisches Hilfswerk",
    "Teegroßhandel",
    "Teehaus",
    "Teeladen",
    "Teemarktplatz",
    "Teichanlagenbauer",
    "Teichbedarf",
    "Teichfischhändler",
    "Telefongesellschaft",
    "Telefonreparaturdienst",
    "Telekommunikationsanbieter",
    "Telekommunikationsausrüster",
    "Telekommunikationsingenieur",
    "Telekommunikationsschule",
    "Telekommunikationsunternehmen",
    "Telemarketingdienst",
    "Tempura Donburi-Restaurant",
    "Tempura-Restaurant",
    "Tennis-Fachhandel",
    "Tennishalle",
    "Tennislehrer",
    "Tennisplatz",
    "Tennisplatz-Bauunternehmen",
    "Tennisverein",
    "Teppanyaki-Restaurant",
    "Teppichgeschäft",
    "Teppichreinigung",
    "Teppichverleger",
    "Terrassenbauer",
    "Tesla-Schauraum",
    "Tex-Mex-Restaurant",
    "Textilunternehmen",
    "Thai-Massage",
    "Thailändisches Restaurant",
    "Theater",
    "Theaterensemble",
    "Themenpark",
    "Thermalbad",
    "Thermalquellenhotel",
    "Thüringer Restaurant",
    "Tibetisches Restaurant",
    "Tickethändler",
    "Tiefbauunternehmen",
    "Tiefkühlkosthersteller",
    "Tierarzt",
    "Tierfreundliche Unterkunft",
    "Tierfriedhof",
    "Tierfriseur",
    "Tierfänger",
    "Tierheim",
    "Tierklinik",
    "Tierpark",
    "Tierpräparator",
    "Tierrettung",
    "Tierschutzorganisation",
    "Tiersitter",
    "Tiertränke",
    "Tiervermittlung",
    "Tierärztlicher Notdienst",
    "Tiki-Bar",
    "Tischtennisanlage",
    "Tischtennisbedarf",
    "Tischtennisverein",
    "Tofuladen",
    "Tofurestaurant",
    "Toneranbieter",
    "Tonkatsu-Restaurant",
    "Tonstudio",
    "Tortenbäckerei",
    "Tortenfachgeschäft",
    "Toskanisches Restaurant",
    "Touren- und Reiseanbieter",
    "Tourenveranstalter",
    "Touristeninformation",
    "Toyota-Händler",
    "Traditionelles Teehaus",
    "Trainingsplatz mit Schlagkäfig",
    "Traktorhändler",
    "Traktorreparaturwerkstatt",
    "Transkriptionsdienst",
    "Transportdienst",
    "Transportunternehmen",
    "Treppenbauunternehmen",
    "Tresor- und Geldschrankhändler",
    "Treuhandbank",
    "Treuhanddienst",
    "Trinkwasserquelle",
    "Trockenbau-Fachhandel",
    "Trockenbauunternehmen",
    "Trockenblumenfachhandel",
    "Trockeneisanbieter",
    "Trophäenhandel",
    "Tschechisches Restaurant",
    "Tsukigime-Parkplatz",
    "Tunesisches Restaurant",
    "Tuningfachhandel",
    "Turkmenisches Restaurant",
    "Turnverein",
    "Tätowierungsentfernungs-Service",
    "Töpferei",
    "Türfachhandel",
    "Türkisches Restaurant",
    "Udon-Nudelrestaurant",
    "Uhrengeschäft",
    "Uhrenhersteller",
    "Uhrenreparatur",
    "Uhrenreparaturservice",
    "Uhrmacher",
    "Ukrainisches Restaurant",
    "Umbauer",
    "Umspannwerk",
    "Umstandsmode",
    "Umweltberater",
    "Umweltbüro",
    "Umwelteinrichtung für erneuerbare natürliche Ressourcen",
    "Umweltingenieur",
    "Umzugs- und Lagerservice",
    "Umzugsbedarf",
    "Unagi-Restaurant",
    "Ungarisches Restaurant",
    "United Methodist Church",
    "Unity Church",
    "Universität",
    "Universitätsbibliothek",
    "Universitätsfachbereich",
    "Universitätsklinikum",
    "Unterhaltungsagentur",
    "Unterkunft für Selbstversorger",
    "Unterkunft in Wohngebäuden",
    "Unternehmen für Eisenbahntechnik",
    "Unternehmen für Holzfällarbeiten",
    "Unternehmen für Isolationsarbeiten",
    "Unternehmen für Maschinenbau",
    "Unternehmensberater",
    "Unternehmensmakler",
    "Unterwäschegeschäft",
    "Urologe",
    "Uruguayisches Restaurant",
    "Usbekisches Restaurant",
    "Valencianisches Restaurant",
    "Vastu-Berater",
    "Veganes Restaurant",
    "Vegetarisches Restaurant",
    "Venezianisches Restaurant",
    "Venezolanisches Restaurant",
    "Veranstalter von Walbeobachtungen",
    "Veranstaltungsort für Livemusik",
    "Veranstaltungsplaner",
    "Veranstaltungsraum",
    "Veranstaltungsstätte",
    "Veranstaltungstechnik-Dienstleistung",
    "Verband der Maori",
    "Verband der Wege-, Kanal- und Hafenbauingenieure",
    "Verband technischer Wirtschaftsingenieure",
    "Verband, Verein oder Organisation",
    "Verbrauchermarkt",
    "Verbrauchermarktorganisation",
    "Verbraucherzentrale",
    "Verbrennungsanlage",
    "Verein",
    "Verein für traditionelle Kostüme",
    "Vergnügungszentrum",
    "Verkehrsministerium",
    "Verkehrspolizist",
    "Verkehrssicherheitsdienstleister",
    "Verkehrsüberwachungskamera",
    "Verlag",
    "Vermietung virtueller Büros",
    "Vermietung von Hütten und Mobilheimen",
    "Vermietungsagentur für Anlagen mit Eigentumswohnungen",
    "Verpackungsunternehmen",
    "Versanddienst",
    "Versandservice",
    "Versicherungsagentur",
    "Versicherungsanwalt",
    "Versicherungsgesellschaft",
    "Versicherungsmakler",
    "Versorgungsamt",
    "Versorgungsbetrieb-Auftragnehmer",
    "Vertriebsdienst",
    "Veteranenkrankenhaus",
    "Veteranenzentrum",
    "Videobearbeitungsdienst",
    "Videogeräte-Reparaturdienst",
    "Videogeschäft",
    "Videokamera-Reparaturdienst",
    "Videokaraoke",
    "Videoproduktionsdienst",
    "Videorekorder-Reparaturdienst",
    "Videothek",
    "Videothek für Videospiele",
    "Videoüberwachung",
    "Viehauktionshaus",
    "Viehmarkt",
    "Viehzüchter",
    "Vietnamesisches Restaurant",
    "Vineyard-Kirche",
    "Vintage-Moden-Geschäft",
    "Violinengeschäft",
    "Visa- und Passamt",
    "Vogel-Fachgeschäft",
    "Vogelabwehrdienst",
    "Vogelbeobachtungsgebiet",
    "Volkshochschule",
    "Volkswagen-Händler",
    "Volleyballfeld",
    "Volleyballverein",
    "Volvo-Händler",
    "Vorhangsgeschäft",
    "Vorschule",
    "Vorstandsetagenvermietung",
    "WLAN-Hotspot",
    "Waagen-Reparaturdienst",
    "Waagenanbieter",
    "Wachsmuseum",
    "Waffengeschäft",
    "Wahrsagerei",
    "Waisenhaus",
    "Waldorf-Kindergarten",
    "Waldorfschule",
    "Walisisches Restaurant",
    "Wallfahrtsort",
    "Wandergebiet",
    "Warenlager",
    "Warmwassersystem-Anbieter",
    "Waschsalon",
    "Wasseraufbereitungsanlage",
    "Wasserballpool",
    "Wasserbettengeschäft",
    "Wasserflaschenanbieter",
    "Wasserflughafen",
    "Wassermühle",
    "Wasserschadensanierung",
    "Wasserskiangebot",
    "Wasserskigeschäft",
    "Wasserskiklub",
    "Wasserskilehrer",
    "Wassersportanlage",
    "Wassersportausrüstungs-Verleih",
    "Wasserstrahl-Schneidedienst",
    "Wasserversorgungsunternehmen",
    "Wasserwerk",
    "Web-Hosting-Unternehmen",
    "Webdesigner",
    "Wechselstube",
    "Wehr",
    "Weichschildkröten-Restaurant",
    "Weide-Viehzuchtbetrieb",
    "Weidelandbereitstellung",
    "Weidenkorbhersteller",
    "Weinberg",
    "Weingroßhändler und -Importeur",
    "Weinhandlung",
    "Weinherstellungsbedarf",
    "Weinkellerei",
    "Weinlagereinrichtung",
    "Weinstube",
    "Weiterbildungszentrum",
    "Wellness-Center",
    "Wellness-Programm",
    "Wellnesshotel",
    "Wellnesszentrum",
    "Werbeagentur",
    "Werbeagentur für Postwurfsendungen",
    "Werbeartikelanbieter",
    "Werft",
    "Werkzeug-Reparaturwerkstatt",
    "Werkzeuggeschäft",
    "Werkzeughersteller",
    "Werkzeugmacher",
    "Werkzeugmacherei",
    "Werkzeugverleih",
    "Wesleyanische Kirche",
    "Westafrikanisches Restaurant",
    "Wettbüro",
    "Wettervorhersage",
    "Wettlokal",
    "Whirlpool-Fachhandel",
    "Whirlpool-Reparaturdienst",
    "Wildpark",
    "Wildtier- und Safaripark",
    "Wildtier-Rettungsdienst",
    "Wimpernstudio",
    "Windelservice",
    "Windhundstadion",
    "Windkraftanlagenbauer",
    "Windpark",
    "Windsurfing-Shop",
    "Wing Chun-Schule",
    "Wintergartenbauer",
    "Wintergartenbauunternehmen",
    "Wirtschaftsauskunftei",
    "Wirtschaftsingenieur",
    "Wirtschaftsingenieurverband",
    "Wirtschaftsprüfer",
    "Wirtschaftsprüfungsunternehmen",
    "Wissenschaftsmuseum",
    "Wohnanhängertransporter",
    "Wohnanlage",
    "Wohnanlage/Wohnsiedlung",
    "Wohngenossenschaft",
    "Wohnmobil-Reparaturwerkstatt",
    "Wohnmobilbedarf",
    "Wohnmobilhändler",
    "Wohnmobillager",
    "Wohnmobilvermietung",
    "Wohnungsbau",
    "Wohnungsbaubehörde",
    "Wohnungsbaugesellschaft",
    "Wohnungsbauunternehmen",
    "Wohnungsvermietungsagentur",
    "Wohnwagen-Parkplatz",
    "Wohnwagenbedarf",
    "Wohnwagenhändler",
    "Wohnwagenpark",
    "Wohnwagenvermietung",
    "Wok Restaurant",
    "Wollgeschäft",
    "Wrestlingschule",
    "Wägestation",
    "Wählerregistrierungsamt",
    "Wäscherei",
    "Yachtclub",
    "Yakiniku-Restaurant",
    "Yakitori-Restaurant",
    "Yoga-Studio",
    "Yogaseminarzentrum",
    "Yukatekisches Restaurant",
    "Zahnarzt",
    "Zahnersatzklinik",
    "Zahnklinik",
    "Zahnmedizinische Fakultät",
    "Zahnpflegebedarf",
    "Zahnradiologie",
    "Zahnversicherungsagentur",
    "Zahnärztlicher Notdienst",
    "Zahnästhetische Praxis",
    "Zauberartikelgeschäft",
    "Zauberer",
    "Zaunbauer",
    "Zaunfachhandel",
    "Zeitarbeitsagentur",
    "Zeitschriftenladen",
    "Zeitungskiosk",
    "Zeitungsverlag",
    "Zeltverleih",
    "Zentralbank",
    "Zentraljavanisches Restaurant",
    "Zentrum für Familienplanung",
    "Zentrum für Reisemedizin",
    "Zentrum für Schmerztherapie",
    "Zentrum für ärztliche Untersuchungen",
    "Zigarrengeschäft",
    "Zimmermann",
    "Zimmerpflanzenservice",
    "Zimmervermittlung",
    "Zirkus",
    "Zivile Polizei",
    "Zivilschutz",
    "Zivilstandsamt",
    "Zollamt",
    "Zollmakler",
    "Zoohandlung",
    "Zoologisches Museum",
    "Zuckerfabrik",
    "Zugewiesene Landfläche",
    "Zugreparaturzentrum",
    "Zunft",
    "Zwangsvollstreckungsdienst",
    "Ägyptisches Restaurant",
    "Änderungsschneiderei",
    "Ärztehaus",
    "Ärztlicher Notdienst",
    "Äthiopisches Restaurant",
    "Öffentliche Behindertentoilette",
    "Öffentliche Bücherei",
    "Öffentliche Damentoilette",
    "Öffentliche Herrentoilette",
    "Öffentliche Sauna",
    "Öffentliche Schule",
    "Öffentliche Toilette",
    "Öffentlicher Golfplatz",
    "Öffentlicher Park",
    "Öffentlicher Parkplatz",
    "Öffentliches Bad",
    "Öffentliches Gesundheitszentrum",
    "Öffentliches Großbad",
    "Öffentliches Schwimmbad",
    "Ökodrogerie",
    "Ökoenergieanbieter",
    "Ökologenverbund",
    "Ökologischer Park",
    "Öl- und Erdgasunternehmen",
    "Ölfeldausrüster",
    "Ölraffinerie",
    "Ölwechseldienst",
    "Örtlicher medizinischer Dienst",
    "Österreichisches Restaurant",
    "Übersetzer",
    "Übungsgelände für Fahrschüler",
    "Škoda-Händler",

    "Softwareentwickler",
    "Dienstleister",
    "Webentwickler",
    "Appentwickler",
    "Kneipe",
    "Biergarten",
    "Glasbierfachgeschäft",
    "Grillfachhandel",
    "Fotofachgeschäft",

    "Stadt",
    "Stadtverwaltung",
    "Gemeindeverwaltung",

    "Spielplatz",
    "Sehenswürdigkeit",
    "Park",
    "Quelle",
    "Öffentliches WC",
    "Späti",
    "Schloss",
    "Burg",
    "Freibad",
    "See",

    "Kfz-Gutachter",
    "Grillschule",
    "Döner-Restaurant",
    "Fairtrade-Store",
    "Hotel",
    "Foodturck",
]