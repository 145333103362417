import React from "react"
import "./SubPage.scss"

function SubPage({
    title,
    children,
    onNavigateBack = () => alert("Diese Funktion ist im Emulator nicht verfügbar.") //todo: Nur auf dem Desktop, da mobil sich der Emulator schließt
}) {
    return (
        <div className="SubPage">
            <div className="navTop" onClick={onNavigateBack}>
                <span>
                    <i className="fa-solid fa-chevron-left" />
                    {title}
                </span>
            </div>
            <div className="children">
                {children}
            </div>
        </div>
    )
}
export default SubPage