import Uri from "../text/Uri"
import "./DefaultTile.scss"

function DefaultTile({ title, buttons, desc, icon, url, uri }) {
    return (
        <div className="col-12 col-md-3 col-xxl-2 ">
            <Uri uri={uri} url={url}>
                <div className="DefaultTile">
                    <div className="inner">
                        <div className="iconBox">
                            {icon}
                        </div>
                        <h2>{title}</h2>
                    </div>

                    <div className="hoverBox">
                        <p>{desc}</p>
                    </div>

                </div>
            </Uri>
        </div>
    )
}
export default DefaultTile