import axios from 'axios';
import { useEffect, useState } from 'react';

function HelpPage() {

    const [Error, setError] = useState(false);
    const [ArticleData, setArticleData] = useState([]);

    useEffect(() => {
        axios.get("https://diestadt.app/wp-json/wp/v2/posts")
            .then((res) => {
                setArticleData(res.data)
            })
            .catch((err) => {

            })
    })

    return (
        <div className="container mt-5">

            <h1>Sie benötigen Hilfe?</h1>

            <h2>Hilfreiche Artikel:</h2>
            <div className="mb-5">
                <div>
                    {ArticleData.map((i, k) =>
                        <a key={k} href={i.link} target="_blank">
                            <li>{i.title.rendered}</li>
                        </a>
                    )}
                </div>
            </div>
            <hr />


            <h2>Kontaktieren Sie uns gerne!</h2>

            {contactOptions.map((i, k) =>
                <a className="ml-4 mb-4" key={k} href={i.url}>
                    <h4><span style={{ width: 36, display: "inline-block" }}>{i.icon}</span>{i.text}</h4>
                </a>
            )}
        </div>
    )
}

export default HelpPage

let contactOptions = [
    {
        text: "(+49) 6634 96 90 99 - 0",
        icon: <i className="fa-duotone fa-phone-rotary mr-2"></i>,
        url: "tel:4966349690990"
    },
    {
        text: "info@dashdesign.eu",
        icon: <i className="fa-duotone fa-envelope-open"></i>,
        url: "mailto:info@dashdesign.eu"
    },
    // {
    //     text: "Livechat",
    //     icon: <i className="fa-duotone fa-comments"></i>,
    //     url: "#"
    // },
    {
        text: "Poststr. 1, 35305 Grünberg",
        icon: <i className="fa-solid fa-map-marker mr-2"></i>,
        url: "#"
    },
]