import React, { forwardRef } from "react";

import Config from "../../../const/Config"
import "./Image.css";

const cdnAddress = Config.CDNAddress;

export const Image = forwardRef(({ id, imageDetail, style, ...props }, ref) => {
  return (
    <img
      className="gallery-img"
      {...props}
      src={cdnAddress + "/image?publicId=" + imageDetail.publicID}
      style={style}
      ref={ref}
    >
    </img>
  );
});

export default Image;