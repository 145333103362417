import React from "react"
import "./RegionPicker.scss"

function RegionPicker({ prefix = "Region auswählen:" }) {
    return (
        <div className="RegionPicker">
            <div>
                <span>{prefix} </span>
                <select>
                    <option>Grünberg</option>
                    <option>Mücke</option>
                </select>
            </div>
        </div>
    )
}
export default RegionPicker