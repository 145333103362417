import { BrowserRouter, Link, Redirect, Route, Switch } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import "./assets/styles/App.scss";
import Config from './const/Config';
import { isLoggedIn } from './functions/auth';
import PageLogin from './pages/auth/PageLogin';
import PageNewPassword from './pages/auth/PageNewPassword';
import PageRegister from "./pages/auth/PageRegister";
import HomeSwitch from './switches/HomeSwitch';

function App() {

  console.log("ServerAddress: " + Config.ServerAddress)
  let date = "1899-11-30 00:00:00.000"

  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <Route path="/login" children={<PageLogin />} />
          <Route path="/register" children={<PageRegister />} />
          <Route path="/newpassword" children={<PageNewPassword />} />



          <PrivateRoute path="/" children={<HomeSwitch />} />
          <HomeSwitch />
          <Route path="/">
            <Link to="/">404 - Zur Homepage</Link>
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}
export default App;

function PrivateRoute({ children, ...rest }) {
  return (
    <Route {...rest} render={({ location }) => {
      return isLoggedIn()
        ? children
        : <Redirect
          to={{
            pathname: '/login',
            state: { from: location }
          }}
        />
    }} />
  )
}