import { useState } from "react"
import renderPrice from "../../../functions/finance"
import "./SelectBoxes.scss"

function defaultRenderBox(obj, isActive, bonusPayload) {

    let basePrice = obj.price || bonusPayload.price
    let factor = obj.priceFactor || 1
    let price = basePrice * factor
    let priceText = !bonusPayload.showPrice ? `Ab ${renderPrice(price)}` : ""


    return <div>
        <div className="icon">{obj.image}</div>
        <h3>{obj.title}</h3>
        <p>{obj.description}</p>

        {
            Array.isArray(obj.benefits) ?
                <p className="benefits">
                    {/* <b>Vorteile:</b><br /> */}
                    {obj.benefits.map((b) =>
                        <small><li> {b}</li></small>
                    )}
                </p>
                : null}

        <p><b>{priceText}</b></p>
        {/* <i className={isActive ? "fa-solid fa-check-circle" : "fa-regular fa-circle"} /> */}
        {/* <span>{isActive ? "Gewählt" : ""}</span> */}
    </div >
}

function SelectBoxes({ boxes = [], bonusPayload, onChange, multiple, disabled = false, required, renderBox = defaultRenderBox }) {

    const [Active, setActive] = useState([])

    const isActive = (boxID) => Active.includes(boxID)
    const getBoxClassName = (boxID) => isActive(boxID) ? "inner isActive" : "inner"

    const setBox = (boxID, value) => {
        let newActive = Active
        if (value) {
            newActive.push(boxID)

        } else {
            console.log("OK filter", Active)
            newActive = Active.filter(id => id != boxID)
            console.log("filter fertig: ", newActive)
        }
        handleOnChange(Active)
        setActive([...newActive])
    }

    const clickBox = (boxID) => {
        if (!disabled) {
            if (multiple) {
                let active = isActive(boxID)
                console.log(Active.length <= 1)
                if (active && Active.length <= 1) {
                    //nix: Weil man mindestens eins wählen muss
                } else {
                    console.log(`'"Setzte box ${boxID}' auf ${!active}`)
                    setBox(boxID, !active)
                }
            } else {

                handleOnChange([boxID])
                setActive([boxID])
            }
        }
    }

    function handleOnChange(activeArray) {
        let objectArray = boxes.filter((box) => activeArray.includes(box.ID))
        onChange({ target: { value: objectArray } })
    }

    return (
        <div className="SelectBoxes">
            <div className="d-flex flex-wrap">
                {boxes.map((i, k) =>
                    <div key={k} className="SelectBox col-12 col-md-6 align-items-stretch">
                        <div key={k} className={getBoxClassName(i.ID)} onClick={() => clickBox(i.ID)}>
                            {renderBox(i, isActive(i.ID), bonusPayload)}
                            {/* {i.content || i.title}
                            ID: {i.ID} */}

                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
export default SelectBoxes