import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router'
import TitleBar from '../../components/bars/TitleBar/TitleBar';
import Config from '../../const/Config'

function AdminEditUser() {
    let { uuid } = useParams()
    const [UserData, setUserData] = useState({});
    const [UserPremissions, setUserPremissions] = useState([]);

    useEffect(() => {
        let config = {
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem("token") },
        }

        let url = Config.ServerAddress + "/admin/user?targetUUID=" + uuid
        axios.get(url, config)
            .then(res => {
                setUserData(res.data[0][0])
                setUserPremissions(res.data[1])
                console.log(res.data)
            })
            .catch(err => { console.log(err) })


    }, [])

    return (
        <div className="p-5">
            <Helmet>
                <title>{UserData.PreName + UserData.FamilyName || "Benutzer anpassen"}</title>
            </Helmet>

            <div className="container">
                <TitleBar showBackButton title={"Admin-Bereich"} />

                <h4>
                    {UserData.PreName || "<Fehler kein Vorname>"} <b>{UserData.FamilyName || "<Fehler kein Nachname>"} ({UserData.ID})</b>
                </h4>
                <p><a href={"mailto:" + UserData.email}>{UserData.email}</a></p>
                <p><a href={"tel:" + UserData.Phone}>{UserData.Phone}</a></p>



                <div>
                    <h3>Rechte auf Einträge:</h3>
                    <div>
                        {UserPremissions.map((i, k) =>
                            <p key={k}>{i.title}</p>
                        )}
                    </div>
                </div>

                <small>🕞 {UserData.created}</small>
                {/* Benutzer anpassen: {uuid} */}
            </div>
        </div>

    )
}

export default AdminEditUser
