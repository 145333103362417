import { useState } from "react"
import "./CouponInput.scss"

function CouponInput({ onCouponChange = () => null }) {

    const [Value, setValue] = useState("")
    const [RabattAmount, setRabattAmount] = useState()

    function handleChange(e) {
        let value = e.target.value
        let amount = getCouponAmount(value)
        setRabattAmount(amount)
        onCouponChange(amount, value)
        setValue(value)
    }

    return (
        <div className="CouponInput">
            <hr></hr>
            <h3 className="mb-3">Gutscheincode:</h3>
            <input value={Value} onChange={handleChange} />
            <div className="p-2">
                {!RabattAmount ? null : <p><b>{RabattAmount}%</b> Rabatt werden angerechnet.</p>}
            </div>
        </div>
    )
}
export default CouponInput

function getCouponAmount(coupon = "") {
    coupon = coupon.toLowerCase()

    let couponItem = demoData.filter((i => i.code.toLowerCase() == coupon))[0]
    return couponItem?.amount
}

let demoData = [
    {
        code: "STARTUPWEEKEND",
        amount: 100,
    },
    {
        code: "KOMMUMNE_CÖLBE_GHG-134",
        amount: 100,
    },
    {
        code: "alena",
        amount: 30,
    },
    {
        code: "alena2",
        amount: 50,
    },
    {
        code: "alena20",
        amount: 20,
    },
    {
        code: "darnok",
        amount: 10,
    },
    {
        code: "A",
        amount: 1,
    }
]