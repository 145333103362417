import React, { useRef, useState } from "react"
import "./InputLoop.scss"

function InputLoop({ inputs, onChange, bonusPayload }) {
    const scrollRef = useRef(null)
    const [Values, setNewValues] = useState([])

    function updateValue(key, value) {
        //Value im Entry anpassen
        let newValues = Values
        newValues[key] = value
        setNewValues([...newValues])

        //Pürfen ob eingabe Fertig
        let finish = true
        inputs.forEach((input, id) => {
            if (Values[id] == null) finish = false
        });
        onChange({ target: { value: Values, isFinish: finish } })
        executeScroll()
    }

    let filledCount = Values.length

    const executeScroll = () => scrollRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })

    return (
        <div className="InputLoop">
            {inputs.map((i, k) => {
                let disabled = filledCount < k
                let scrollTo = filledCount == k
                let className = disabled ? "Input disabled" : " Input"
                let comp = React.cloneElement(i.component, {
                    onChange: (e) => { updateValue(k, e.target.value) },
                    field: i.field,
                    disabled: disabled,
                    onEmulatorUpdate: bonusPayload?.onEmulatorUpdate,
                    bonusPayload: { ...bonusPayload, showPrice: filledCount > k, }
                })
                return (<div ref={scrollTo ? scrollRef : null} key={k} className={className}>
                    <h2>{i.title}</h2>
                    <p>{i.description}</p>
                    {comp}
                </div>)
            }
            )}
        </div>
    )
}
export default InputLoop