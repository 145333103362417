import React from "react"
import "./TimeSpanInput.scss"

function TimeSpanInput({ value = [], onChange, onFocus }) {

    // if (value.length < 2) {
    //     value = Array(2)
    // }

    function updateValue(key, targetValue) {
        if (targetValue == "") targetValue = null
        value[key] = targetValue
        onChange({ target: { value: value } })
    }

    if (!value[0] && !value[1]) return null
    return (
        <div className="TimeSpanInput">
            <input
                onFocus={onFocus}
                onChange={(e) => updateValue(0, e.target.value)}
                type="time"
                size={5}
                value={value[0]} />

            <span>bis</span>

            <input
                onFocus={onFocus}
                onChange={(e) => updateValue(1, e.target.value)}
                type="time"
                value={value[1]} />

            <span>Uhr</span>
        </div>
    )
}
export default TimeSpanInput