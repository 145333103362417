import React, { useEffect, useState } from "react"
import AiButton from "../buttons/AiButton"
import "./SelectInput.scss"

function SelectInput(props) {

    let { type, items, custom, onChange, value } = props

    function setItem(newValue) {
        onChange({ target: { value: newValue } })
    }

    return (
        <div className="SelectInput">

            <input type={getType(type)} disabled={!custom} {...props} />

            <div className="mt-1">
                {items.filter(f => f != value).map((i, k) =>
                    <AiButton
                        onClick={() => setItem(i)}
                        key={k}
                    >
                        {i || "Ka"}
                    </AiButton>
                )}
            </div>
        </div>
    )
}
export default SelectInput

function getType(type) {
    switch (type) {
        case "INT": return "number";
        case "STRING": return "text";
        default: return "text";
    }
}