export const AboTypes = [
    {
        ID: 1,
        title: "Standard",
        description: "Perfekt für alle, die einfach mit dabei sein möchten!",
        priceFactor: 1,
        icon: <i className="fa-solid fa-users" />,
        benefits: [
            <>Kontaktdaten verlinken</>,
            <>Social-Media verknüpfen</>,
            <>Bilder hinzufügen</>,
            <>Texte hinzufügen</>,
            <>Aktuelle Hinweise hinzufügen</>,
            <>Öffnungszeiten einbauen</>,
            <>Listung in Kategorien</>,
        ],
    },
    {
        ID: 2,
        title: "Premium",
        description: "Perfekt für alle, die Digitalisierung ernst nehmen",
        priceFactor: 1.65,
        icon: <i className="fa-solid fa-house" />,
        benefits: [
            <><b>Vorteile von "Standard"</b></>,
            <>Listung vor Standardeinträgen</>,
            <>Gutscheine schalten</>,
            <>Label "Empfolen"</>,
            <>Webshop verlinken</>,
            <>"Termine vereinbaren"-Schaltfläche</>,
            // <>20€ Webegutschein</>,
        ],
    },
    {
        ID: 3,
        title: "Premium+",
        description: "Perfekt für alle, die Ihren Kunden einen optimalen Service bieten möchten",
        priceFactor: 3,
        icon: <i className="fa-solid fa-house" />,
        benefits: [
            <><b>Vorteile von "Premium"</b></>,
            <>Listung vor allen anderen</>,
            <>Produkte & Dienstleistungen vorstellen</>,
            <>Team/ Ansprechpartner vorstellen</>,
            <>360° Rundgang einbinden</>,
            <>Flyer und Prospekte einstellen</>,
            // <>News, Stellenangebote Flat</>,
            // <>Firmenzertifikate vorstellen</>,
            <>Corporate Design</>,
            <>Vorzeitiger Zugriff auf BETA-Funktionen</>,
            <>Label "Optimaler Service"</>,
            <>Persönlicher Ansprechpartner</>,
            // <>100€ Webegutschein</>,
            // <>25% Werbegeld-Multiplikator</>,
        ],
    },
]
// Buy-Ado-on: <>Speißekarte</>,

export const Interval = Object.freeze({
    quarter: 0,
    yearly: 1,
    twoYearly: 2,
});


export const Intervals = [
    {
        ID: 1,
        title: "Jährlich",
        description: "Jedes Jahr eine Rechnung",
        priceFactor: 1.2,
        icon: <i className="fa-solid fa-house" />,
    },

    {
        ID: 2,
        title: "Zweijährlich",
        description: "Jedes zweite Jahr eine Rechnung",
        priceFactor: 1,
        icon: <i className="fa-solid fa-house" />,
    },
    // {
    //     ID: 3,
    //     title: "Quatalsweise",
    //     description: "Jeden 3. Monat eine Rechnung",
    //     priceFactor: 2,
    //     icon: <i className="fa-solid fa-users" />,
    // },
]

export const CompanyTypes = [
    {
        ID: 1,
        title: "Eingetragener Verein",
        description: "Nur für die Rechtsform 'e.V.'",
        // priceFactor: 1,
        price: 3.33,
        icon: <i className="fa-solid fa-users" />,
    },
    {
        ID: 2,
        title: "Sonstiges Gewerbe",
        description: "Für Unternehmen, Restaurants, Selbstständige, Bildungseinrichtungen und alle weiteren Gewerbetriebende",
        description: "",
        // priceFactor: 3,
        price: 9.99,
        icon: <i className="fa-solid fa-house" />,
    },
]