import axios from 'axios';
import React, { useEffect, useState } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import Config from '../../../const/Config';
import { getToken } from '../../../functions/auth';
import CropperComponent from "./CropperComponent";
import "./CropperPage.css";
import Gallery from './Gallery';

const cdnAddress = Config.CDNAddress;

function CropperPage({ EntryID, update }) {

    const [imgDetails, setImgDetails] = useState([]);


    async function updateImageDetails() {
        let detailRes = await axios.get(cdnAddress + "/getDetails?entryID=" + EntryID).catch(err => console.error(err))
        if (Array.isArray(detailRes.data)) {
            setImgDetails(detailRes.data.reverse());
        }
    }

    useEffect(() => updateImageDetails(), []);

    async function handleDelete(imageDetail) {

        let headers = {
            'Content-Type': 'application/json',
            'Authorization': getToken()
        }
        await axios.post(cdnAddress + "/deleteDetails", [imageDetail.ID], { headers })
        updateImageDetails();

    }

    function createImageDetail(publicID, EntryID) {
        let json = {
            publicID: publicID,
            entryID: Number(EntryID)
        }
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': getToken()
        }
        axios.post(cdnAddress + "/createDetail", JSON.stringify(json), { headers })
            .then((res) => {

                updateImageDetails();
            })
            .catch((err) => {
                console.log(err);
            });

    }

    function handleImageUpdate(imageDetails) {
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + sessionStorage.getItem("token")
        }
        axios.post(cdnAddress + "/manipulateDetails", imageDetails, { headers })
            .then((res) => {
                console.log(res)
                update();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <div className='cropper-page'>
            <h1>Bilder</h1>
            <p>{imgDetails.length}/8 Bilder sind veröffentlicht</p>
            <CropperComponent
                className={"EntryCropper"}
                imageTypeID={1}
                onUploadSuccess={(publicID) => createImageDetail(publicID, EntryID)} />

            <h1></h1>
            <Gallery imageDetails={imgDetails} setImageDetails={setImgDetails} handleDelete={handleDelete} handleImageUpdate={handleImageUpdate} />
        </div>

    )
}

export default CropperPage
