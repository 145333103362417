import axios from 'axios';
import { useState } from 'react';
import Config from '../../const/Config';
import { getToken } from '../../functions/auth';
import { getHumanDate } from '../../functions/date';
import "./EventItem.scss";

function EventItem(props) {
    let { ID, title, startDate, startTime, priceText, date, endDate, url, description, locationName, image, onEdit } = props;

    const [Deleted, setDeleted] = useState(false)

    function getDateString() {
        let string = ""

        string += getHumanDate(startDate)

        startDate = new Date(startDate)

        if (endDate) {
            endDate = new Date(endDate)
            string += " - " + getHumanDate(endDate)
            let startYear = startDate.getFullYear()
            if (startYear == endDate.getFullYear()) {
                // string += " " + startYear
            }
            // <i className="fa-solid fa-clock-eight-thirty"></i>
        }

        // if (true) {
        //     string += " - " + getHumanDate(endDate)
        // }
        return string
    }

    const handleEditClick = () => {
        onEdit(props);
    };

    const handleDelete = () => {
        if (window.confirm(`Möchten Sie das Event '${title}' unwiderruflich löschen?`)) {
            setDeleted(true)
            deleteEventInDatabase(ID)
        }
    };

    let eventUrl = "https://mittelhessen.app/event/" + ID

    if (Deleted) return null
    let fallbackImg = "https://blog.dashdesign.eu/wp-content/uploads/2021/11/404-image.png"
    return (
        <div className="col-12 col-lg-6">
            <div className="EventItem" onClick={handleEditClick}>
                <div className="w-100 d-flex flex-column">
                    <div className="imageBox col-12">
                        <div className="ratio ratio-4x3">
                            <div className="image w-100" style={{ backgroundImage: `url(${image || fallbackImg})` }} />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="p-4 d-flex flex-column justify-content-between">
                            <div>
                                <span className="date">
                                    {getDateString()}

                                </span>
                                <span className="title">{title}</span>

                                <div className="tags">

                                    <span>
                                        <i className="fa-solid fa-location-pin" />
                                        {locationName || "Kein Ort"}
                                    </span>

                                    <span>
                                        <i className="fa-solid fa-hands-holding-dollar"></i>
                                        {priceText}
                                    </span>

                                    <span>
                                        <i className="fa-solid fa-clock-eight-thirty"></i>
                                        {startTime ? `ab ${startTime} Uhr` : "Ganztägig"}
                                    </span>

                                    {url ?
                                        <span>
                                            <a href={url} target="_blank"><i className="fa-solid fa-square-up-right" />Website verknüpft</a>
                                        </span>
                                        : null}
                                </div>
                            </div>
                            <small className="details">
                                <div><a className="edit" ><i className="fa-solid fa-pen" /> Bearbeiten</a></div>
                                <div><a className="edit" href={eventUrl} target="_blank" ><i class="fa-solid fa-arrow-up-right-from-square"></i> Vorschau</a></div>
                                <div><a className="edit del" onClick={handleDelete}><i className="fa-solid fa-trash" /> Löschen</a></div>
                            </small>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    )
}
export default EventItem
const monthList = ["Jan.", "Feb.", "März", "April", "Mai", "Juni", "Juli", "Aug.", "Sep.", "Okt.", "Nov.", "Dez."]

function deleteEventInDatabase(eventID) {
    let config = {
        method: 'delete',
        url: Config.ServerAddress + '/event/?eventID=' + eventID,
        headers: {
            'Authorization': getToken()
        },
    };

    axios(config)
        .then((response) => {
            return true
        })
        .catch((error) => {
            alert("Event nicht gelöscht!")
            console.log(error);
            return false
        });

}