import React from "react"
import "./PdfInput.scss"
import WebsiteInput from "./WebsiteInput"

function PdfInput(props) {
    return (
        <div className="PdfInput">

            {/* <h4>PDF-Dokument auswählen:</h4>
            <input type="file" accept=".pdf" />

            <br></br>
            <h4>oder URL verlinken:</h4> */}

            <WebsiteInput {...props} />

        </div>
    )
}
export default PdfInput