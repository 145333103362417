import React, { useState } from "react"
import { Link } from "react-router-dom"
import CodeBox from "../../../components/defaults/boxes/CodeBox"
import "./IFrameEditPage.scss"

function IFrameEditPage(p) {

    console.log(p)

    let { entryID } = p

    const [Options, setOptions] = useState([
        { key: "mainColor", value: "00eeff", }
    ])

    let url = `https://iframe.mittelhessen.app/events/?organizerID=${entryID}`

    return (
        <div className="IFrameEditPage">
            <div>
                <h2 className="mb-4">Veranstaltungen</h2>

                <div>

                    <h3>Per iFrame synchronisieren </h3>
                    <h4>HTML-Quellcode:</h4>
                    <CodeBox>
                        {`<iframe src="${url}" allowfullscreen title="Veranstaltungen" ></iframe>`}
                    </CodeBox>

                    {/* <h4>Anpassungen:</h4>
                <ColorInput value={Color} onChange={(e) => setColor(e.target.value.light)} /> */}
                    <h4>Vorschau: </h4>
                    <iframe height={474} width="100%" src={url} />
                    <p>Durch die Einbettung jeglicher Inhalte auf Ihrer Website stimmen Sie weiterhin den <Link href="_blank" to="/servicebedingungen">Servicebedingungen</Link> zu.</p>
                </div>
                <hr></hr>

                <div>
                    <h3>Mit WordPress synchronisieren</h3>
                    <p>Aktuell empfehlen wir Ihnen bei WordPress unsere iFrame einzubinden, da sich unser WordPress-Plugin noch in Arbeit befindet.</p>
                    {/* <div className="d-flex flex-row">
                        <a><button>Anleitung</button></a>
                        <a><button>Erweiterung installieren</button></a>
                    </div> */}
                </div>
                <hr></hr>

                <div>
                    <h3>Per REST-API synchronisieren</h3>
                    <p>Gerne stellen wir Ihnen unsere API zu Verfügung. Dieser Service ist den meisten Fällen sogar kostenfrei! <br />Kontaktieren Sie dafür bitte <a href="mailto:info@dashdesign.eu">info@dashdesign.eu</a></p>
                </div>
                <hr></hr>

                <div>
                    <h3>React Component</h3>
                    <button>GitHub-Projekt öffnen</button>
                </div>
            </div>
        </div>
    )
}
export default IFrameEditPage