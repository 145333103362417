import { useState } from "react";
import { businessCategoryList } from "../../../../const/Data/tags";
import "./DropdownInput.scss";

function DropdownInput({ value, onChange }) {
    const [searchTerm, setSearchTerm] = useState("");
    const [Value, setValue] = useState(value)
    let categories = businessCategoryList
    const sortedCategories = categories.sort((a, b) => a.localeCompare(b));

    let filteredCategories = sortedCategories.filter((category) =>
        category.toLowerCase().includes(searchTerm.toLowerCase())
    );

    function removeValue() {
        onChange({ target: { value: "" } })
        setValue("")
    }

    function handlePress(category) {
        setValue(category)
        onChange({ target: { value: category } })
        filteredCategories = []
    }

    return (
        <div className="DropdownInput">
            {Value ?
                <div onClick={removeValue}>
                    <h4>{Value}</h4>
                    <i className="fa-solid fa-times-circle"></i>
                </div>
                :
                <>
                    <input
                        type="text"
                        placeholder="Suche Kategorie..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div className="list">
                        {filteredCategories.length > 0 && searchTerm && (
                            filteredCategories.map((category, index) => (
                                <div onClick={() => handlePress(category)} key={index} value={category}>
                                    {category}
                                </div>
                            ))
                        )}
                    </div>
                </>
            }
        </div>
    )
}
export default DropdownInput