import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { getEmail, getFullName, getUuid, isAdmin, logout } from "../../../functions/auth"
import "./ProfileMenu.scss"

function ProfileMenu({ isOpen }) {
    let history = useHistory()

    function handleLogout() {
        if (logout()) history.push("/")
    }

    if (!isOpen) return null
    return (
        <div className="ProfileMenu">

            <h4>{getFullName()}</h4>
            <div>
                <p><b>{getEmail()}</b></p>
                {isAdmin() ? <small>Admin</small> : null}
            </div>
            <hr />
            <div onClick={() => handleLogout()} className="item sign-out">
                <span>  <i className="fa-solid fa-sign-out" />Abmelden</span>
            </div>


        </div>
    )
}
export default ProfileMenu