import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import Formular from "../../components/api/Formular";
import DashdesignBackendLogo from '../../components/defaults/icons/DashdesignBackendLogo';
import Config from '../../const/Config';
import { Login } from '../../services/auth';
import "./PageRegister.scss";

const apiAdress = Config.ServerAddress

function PageRegister() {

    const [Sended, setSended] = useState(false);
    const [Data, setData] = useState();

    function handleSended(res) {
        let username = Data.email
        let password = Data.newPassword

        console.log("handleSended: ", username, password)

        Login(username, password)
            .then(payload => {
                console.log(payload)
                setSended(true)
            })
            .catch(err => {
                console.log(err)
                alert(err)
            })
            .finally(() => {
                // setLoading(false)
            })


    }

    function handleOnChange(values) {
        setData(values)
    }
    let formularProps = {
        apiAdress: apiAdress + "/auth/register/",
        axiosHeaders: {},
        placeholderPrefix: "z.B.:",
        title: <div>
            <DashdesignBackendLogo />
            <hr></hr>
            <h1>Benutzeraccount erstellen</h1>
            <h2 className="m-0">Der Benutzeraccount ist kostenlos und wird wichtig um einen Eintrag zu erstellen oder zu bearbeiten.</h2>
        </div>,
        sendText: "Zustimmen und weiter",
        onSend: (res) => handleSended(res),
        onChange: handleOnChange,
        fields: [
            {
                title: "Vorname",
                type: "STRING",
                value: "",
                placeholder: "Konrad",
                autoComplete: "given-name",
                keyName: "preName",
                required: true,
            },
            {
                title: "Nachname",
                type: "STRING",
                value: "",
                placeholder: "Mayer",
                autoComplete: "family-name",
                keyName: "familyName",
                required: true,
            },
            {
                title: "E-Mail",
                type: "STRING",
                value: "",
                placeholder: "konrad@dashdesign.eu",
                autoComplete: "email",
                keyName: "email",
                required: true,
            },
            {
                title: "Firmenname",
                type: "STRING",
                value: "",
                placeholder: "dashdesign e.K.",
                autoComplete: "organization",
                keyName: "companyName",
                // required: true,
            },
            {
                title: "Telefonnummer",
                type: "STRING",
                value: "",
                placeholder: "06634 / 96 90 99 0",
                autoComplete: "tel",
                keyName: "phone",
                required: true,
            },
            {
                title: "Anrede auswählen",
                type: "SELECTION",
                options: [

                    {
                        key: "Herr",
                        value: "male"
                    },
                    {
                        key: "Frau",
                        value: "female"
                    },
                    {
                        key: "Divers / Keine Angabe",
                        value: "diverse"
                    }
                ],
                value: "male",
                placeholder: "",
                autoComplete: "",
                keyName: "gender",
                required: true,
            },
            {
                title: "Passwort anlegen",
                type: "NEW_PASSWORD",
                value: "",
                placeholder: "",
                autoComplete: "new-password",
                keyName: "newPassword",
                required: true,
            },
            {
                title: "",
                text: <small>Durch das Absenden des von mir ausgefüllten Kontaktformulars stimme ich zu,
                    dass meine Daten zur Bearbeitung dieser Anfrage durch die <a href="https://dashdesign.eu" target="_blank">dashdesign e.K.</a> genutzt
                    und gespeichert werden. <br />
                    Diese Seite ist durch reCAPTCHA geschützt. Mit dem Fortfahren bestätige ich außerdem,
                    dass ich die <a href="https://policies.google.com/privacy?hl=de-DE" target="_blank">Datenschutzbestimmungen</a> von Google gelesen habe und die <a href="https://policies.google.com/terms?hl=de-DE" target="_blank">Nutzungsbedingungen</a>
                    von Google akzeptiere.</small>,
                type: "BOOL",
                value: "",
                placeholder: "",
                keyName: "agb",
                required: true,
            },
        ]
    }


    return (
        <div className="PageRegister">
            <div className="container">
                {Sended ?
                    <Redirect to="/neuer-eintrag" />
                    :
                    <Formular {...formularProps} />
                }
            </div>
        </div>
    )
}

export default PageRegister
