import { useState } from 'react';

function NewPasswordInput({ defaultProps, onChange, placeholder }) {

    const [Visible, setVisible] = useState(false);
    const [Password, setPassword] = useState("");

    const [Copyed, setCopyed] = useState(false);

    function handleChange(e) {
        onChange(e)
        setCopyed(true)
        setPassword(e.target.value)
    }

    function randomPassword() {
        let randomString = Math.random().toString(36).slice(-10);
        setCopyed(false)
        setPassword(randomString)
        setVisible(true)
        onChange({ target: { value: randomString } })
    }

    function copyPassword() {
        setCopyed(true);
        copyToClipboard(Password)
        alert("Passwort wurde in die Zwischenablage kopiert. 😇✅")
        setVisible(false)
    }

    return (<>
        <input {...defaultProps}
            id="new-password"
            autoComplete="new-password"
            type={Visible ? "text" : "password"}
            value={Password}
            onChange={(e) => handleChange(e)} placeholder={placeholder} />
        {/* <input {...defaultProps} id="newPassword2"
            autoComplete="confirm-password" placeholder={placeholder} /> */}
        <div>
            <button onClick={() => setVisible(!Visible)}>
                <i className={Visible ? "fa-solid fa-eye-slash" : "fa-solid fa-eye"}></i>
            </button>

            <button onClick={() => randomPassword()}>Zufälliges Passwort</button>

            <button
                style={{
                    backgroundColor: "var(--text1)", color: "#fff",
                    opacity: Password.length >= 8 && !Copyed ? 1 : 0
                }}
                onClick={() => copyPassword()}
            >

                <i style={{ paddingRight: 8, }} class=" fa-duotone fa-copy" />
                Passwort in Zwischenablage

            </button>

        </div>
    </>)
}
export default NewPasswordInput

const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
};