import { Link, Redirect, Route, Switch } from 'react-router-dom'
import TitleBar from '../components/bars/TitleBar/TitleBar'
import PageDashboard from '../pages/dashboard/PageDashboard'
import EntryNewPage from '../pages/entry/EntryNewPage'
import HelpPage from '../pages/help/HelpPage'
import AdminSwitch from './AdminSwitch'
import EntrySwitch from './EntrySwitch'
import "./HomeSwitch.scss"

function HomeSwitch() {

    let isLoggedIn = sessionStorage.getItem("token") != null


    return (
        <div className="HomeSwitch">
            <TitleBar />
            <div className="content">
                <Switch>

                    <Route path="/dashboard"  >
                        <PageDashboard />
                    </Route>

                    <Route path="/help"  >
                        <HelpPage />
                    </Route>
                    <Route path="/entry/"  >
                        <EntrySwitch />
                    </Route>

                    {/* Fallback von früher & besser zu merlen & schreibenm  */}
                    {/* <Route path={["/neuer-eintrag"]}  >
                        <Redirect to="/entry/new" />
                    </Route> */}
                    <Route path={"/neuer-eintrag"} component={EntryNewPage} />

                    {/* Fallback wegen falsche Mail  */}
                    <Route path="/eintrag-erstellen"> Fallback
                        <Redirect to="/entry/new" />
                    </Route>


                    <Route path="/admin"  >
                        <AdminSwitch />
                    </Route>





                    <Route path="/" exact >
                        <Redirect to="/dashboard" />
                    </Route>


                    <Route path="/"  >
                        <div>
                            <p>Diese Seite ist (noch) nicht vergfügbar. <Link to="/hilfe">Hilfe öffnen</Link> <Link to="/dashboard">Zur Startseite</Link></p>
                        </div>
                    </Route>

                </Switch>
            </div>

        </div >
    )
}

export default HomeSwitch
