import { DndContext, closestCenter, useSensor, useSensors, PointerSensor, KeyboardSensor, DragOverlay } from '@dnd-kit/core';
import { horizontalListSortingStrategy, rectSortingStrategy, SortableContext, arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import React, { useState } from 'react'
import Config from '../../../const/Config'
import { SortableImage } from './SortableImage';
import Image from './Image';
import './Gallery.scss';

function Gallery({ imageDetails, handleImageUpdate, setImageDetails, handleDelete }) {

    const [activeId, setActiveId] = useState(null);
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    function handleDragStart(event) {
        const { active } = event;
        setActiveId(active.id);
    }

    function handleDragEnd(event) {
        const { active, over } = event;
        if (!over) return;
        if (active.id !== over.id) {
            const oldIndex = imageDetails.findIndex((el) => el.ID == active.id);
            const newIndex = imageDetails.findIndex((el) => el.ID == over.id);
            let newImages = arrayMove(imageDetails, oldIndex, newIndex);
            newImages.forEach((value, index) => value.imageOrder = newImages.length - index);
            handleImageUpdate(newImages);
            setImageDetails(newImages);
        }
        setActiveId(null);
    }

    return (
        <div className="Gallery">
            {
                imageDetails.length != 0 ?
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragStart={handleDragStart}
                        onDragEnd={handleDragEnd}>
                        <SortableContext items={imageDetails} strategy={horizontalListSortingStrategy}>
                            {
                                imageDetails.map((imageDetail, id) => (
                                    <SortableImage onDelete={handleDelete} key={imageDetail.ID} id={imageDetail.ID} imageDetail={imageDetail} />
                                ))
                            }
                        </SortableContext>
                        <DragOverlay>{activeId ? <Image id={activeId} imageDetail={imageDetails.find((img) => img.ID == activeId)} /> : null}</DragOverlay>
                    </DndContext>
                    :
                    <div className="dummy">
                        <h1>Hier ist noch kein Bild vorhanden.</h1>
                        <p>Machen Sie den ersten Schritt und laden Sie das erste Bild hoch!</p>
                    </div>
            }


        </div>
    )
}

export default Gallery
