import axios from 'axios'
import { useEffect, useState } from 'react'
import WebsiteInput from '../defaults/inputs/WebsiteInput'
import DefaultLoader from '../defaults/loader/DefaultLoader'
import "./Formular.scss"
import ImageInput from './Inputs/ImageInput'
import NewPasswordInput from './Inputs/NewPasswordInput'

const STRING = "STRING"
const INT = "INT"
const BOOL = "BOOL"
const DATE = "DATE"
const TIME = "TIME"
const IMAGE = "IMAGE"
const TEXT = "TEXT"
const SELECTION = "SELECTION"

function Formular(props) {

    let defaultData = {};
    props.fields.forEach(i => defaultData[i.keyName] = i.value);

    const [Data, setData] = useState(defaultData);
    const [Error, setError] = useState(false);
    const [Sended, setSended] = useState(false)
    const [Loading, setLoading] = useState(false)

    function handleOnChange(e) {
        let newData = Data
        newData[e.keyName] = e.value;
        setData(newData)
        setSended(false)

        if (props.onChange) props.onChange(Data, e.keyName)

        console.log(Data)
        setError(false)
    }

    //Wenn auf send Button geklickt wird
    function handleSend() {
        let filled = true
        setLoading(true)
        setSended(false)
        //Jedes Feld durchgehen 
        let newData = Data
        props.fields.forEach((field) => {
            if (field.value == "" && field.required) {

                let inputValue = newData[field.keyName]

                console.log("Value in field " + field.title + ": " + inputValue)

                if (inputValue == "" || inputValue == undefined) {
                    filled = false
                    console.log("Kein Wert für: " + field.keyName)
                }
            }
        })

        //Zusätliche Payload: zB um EntryID mitzusenden
        if (props.bonusPayload) {
            newData = Object.assign(props.bonusPayload, newData)
        }

        setData(newData)

        if (filled) {
            let body = props.onMapData ? props.onMapData(Data) : { payload: Data };

            let axiosConfig = {
                method: props.axiosMethod || "POST",
                url: props.apiAdress,
                headers: props.axiosHeaders,
                data: body
            }
            console.log(body)

            axios(axiosConfig)
                .then(res => {
                    console.log("Formular erfolgreich gesendet.")
                    setSended(true)
                    if (props.onSend) {
                        props.onSend(res)
                    } else {
                        console.log(props)
                    }
                })
                .catch(err => {
                    let errorData = err?.response?.data
                    let errorMsg = errorData?.error || "Serverfehler, bitte prüfen Sie Ihre Eingaben oder kontaktiere den Support."

                    setError(errorMsg)
                })
                .finally(() => {
                    setLoading(false)
                })
        } else {
            setLoading(false)
            setError("Bitte befüllen Sie alle Pflichtfelder!")
        }
    }

    // if (Sended) {
    //     return <h1>Event wurde aktualisiert.</h1>
    // }
    if (Loading) {
        return <DefaultLoader loadingText="Wird gespeichert..." />
    }

    return (
        <div className="Formular w-100">

            {Sended ? <p><b>Änderungen gespeichert ✅</b></p> : null}

            {props.title ?
                <div className="mb-5">
                    {props.title}
                </div> : null
            }
            <div className="row">
                {
                    props.fields.map((i, k) =>
                        <div key={k} className={i.className}>
                            <div className="mt-1 mb-3" key={k}>
                                <label htmlFor={i.autoComplete}>{i.title}</label>

                                <FormularInput placeholderPrefix={props.placeholderPrefix} {...i} onChangeCallback={(e) => handleOnChange(e)} />
                            </div>
                        </div>
                    )}
            </div>
            {Error ? <p className="error">
                <i className="fa-duotone fa-exclamation-circle"></i> {Error}</p>
                : null}
            <button onClick={() => handleSend()}>{props.sendText}</button>
        </div>
    )
}

export default Formular

function FormularInput(props) {
    const [Value, setNewValue] = useState(props.value || "");

    // console.log(props.value)
    // console.log(Value)

    useEffect(() => {
        setNewValue(props.value)
    }, [])

    function handleOnChange(value) {
        setNewValue(value)
        props.onChangeCallback({ keyName: props.keyName, value })
    }


    let status = <div></div>
    if (props.type == STRING && !props.filled) {
        // status = <div><p>Bitte befüllen:</p></div>
    }

    let placeholderPrefix = props.placeholderPrefix || ""

    let defaultProps = {
        autoComplete: props.autoComplete,
        name: props.autoComplete,
        id: props.autoComplete,
        placeholder: placeholderPrefix + props.placeholder,
    }

    switch (props.type) {
        case STRING: return (
            <div className="w-100">
                {status}
                <input {...defaultProps} value={Value} onChange={(e) => handleOnChange(e.target.value)} />
            </div>
        )
        case "LOCATION": return (
            <div className="w-100">
                {status}
                <input {...defaultProps} value={Value} onChange={(e) => handleOnChange(e.target.value)} />
            </div>
        )
        case "URL": return (
            <div className="w-100">
                {status}
                <WebsiteInput {...defaultProps} value={Value} onChange={(e) => handleOnChange(e.target.value)} />
            </div>
        )
        case TEXT: return (
            <div>
                {status}
                <textarea {...defaultProps} value={Value} onChange={(e) => handleOnChange(e.target.value)} />
            </div>
        )
        case "NEW_PASSWORD": return (
            <div>
                {status}
                <NewPasswordInput {...defaultProps} onChange={(e) => handleOnChange(e.target.value)} />
            </div>
        )
        case BOOL: return (
            <div>
                {status}
                <div className="d-flex flex-row align-items-center">
                    <input {...defaultProps}
                        // value={Value == 1}
                        checked={Value == true}
                        className="m-0 mr-2"
                        type="checkbox"
                        onChange={(e) => {
                            handleOnChange(e.target.checked)
                            console.log(e.target.checked)
                        }
                        } />
                    <p className="mb-0 pl-2">{props.text}</p>
                </div>

                {/* <p>value: {Value}</p> */}
            </div>
        )
        case INT: return (
            <div>
                {status}
                <input {...defaultProps} value={Value} className="" type="number" onChange={(e) => handleOnChange(e.target.value)} />
            </div>
        )
        case DATE: return (
            <div>
                <input {...defaultProps} value={Value} className="" type="date" onChange={(e) => handleOnChange(e.target.value)} />
            </div>
        )
        case TIME: return (
            <div>
                <input {...defaultProps} value={Value} className="" type="time" onChange={(e) => handleOnChange(e.target.value)} />
            </div>
        )
        case IMAGE: return (
            <div>
                <ImageInput {...defaultProps} value={Value} onChange={(value) => handleOnChange(value)} />

            </div>
        )
        case SELECTION: return (
            <div>
                <select onChange={(e) => handleOnChange(e.target.value)}>
                    {props.options.map((i, k) =>
                        <option value={i.value}>{i.key}</option>
                    )}
                </select>
            </div>
        )
        default: return (<text>FEHLER: Unbakannter FormType:
            {props.type}</text>)
    }


}