import axios from "axios"
import Config from "../const/Config"
import { getToken } from "./auth"

export function generateDemoEntry() {

}

export function loadEntryDataById(EntryID) {
    return new Promise((resolve, reject) => {
        let url = Config.ServerAddress + "/editor/generallInfos/?EntryID=" + EntryID
        let config = {
            headers: { 'Authorization': getToken() },
        }
        axios.get(url, config)
            .then(
                res => {
                    let loadedData = res.data
                    let newwDataa = { ...loadedData, data: JSON.parse((loadedData.data || "{}")) }
                    resolve(newwDataa)
                })
            .catch(err => {
                reject(err)
            })
    })
}

export function modifyEntryDataById(entryID, entryData) {
    return new Promise((resolve, reject) => {
        let url = Config.ServerAddress + "/editor/generallInfos/?EntryID=" + entryID
        let config = {
            headers: { 'Authorization': getToken() },
            data: entryData,
            method: 'post',
            url: url,
        }
        console.log(entryData)
        axios(config)
            .then(
                res => {
                    resolve(res)
                })
            .catch(err => {
                reject(err)
            })
    })
}