import { useState } from "react";
import { businessCategoryList } from "../../../const/Data/tags";
import AiButton from "../buttons/AiButton";
import "./ListInput.scss";

//Komma = 188, enter = 13
const KeyCodes = [188, 13]

const delimiters = [KeyCodes.comma, KeyCodes.enter, 35];
const suggestions = businessCategoryList


function ListInput({ placeholder, value, onChange, prefix }) {
    const [List, setList] = useState(value)
    const [LastRemoved, setLastRemoved] = useState("")
    const [TextBoxValue, setTextBoxValue] = useState("")



    function saveChange(newList) {
        // onChange({ target: { value: List } }) //todo: fix
        console.log("newList safe ... ")
        console.log(newList)
        setList(newList)
        onChange({ target: { value: newList } })
    }

    function handleRemove(keyword) {
        setLastRemoved(keyword)
        let newList = List.filter(i => i != keyword)
        setList([...List].filter(i => i != keyword))
        saveChange(newList)
    }

    function handleAdd(string) {
        console.log("Add string  " + string)
        console.log("List", List)
        let newList = [...List]
        console.log("newList", newList)
        newList.push(string)
        console.log("newList", newList)
        saveChange(newList)
    }

    function onKeyPressed(e) {
        if (KeyCodes.includes(e.keyCode)) {
            console.log("ADD")
            handleAdd(TextBoxValue.toLocaleLowerCase())
            setTextBoxValue("")
            console.log(e.keyCode)
            e.preventDefault(null)
        }
    }

    return (
        <div className="ListInput">

            {/* Einträge */}
            <div className="list">
                {List.map((keyword, i) =>
                    <KeyWord keyword={keyword} key={i} prefix={prefix} onRemove={() => handleRemove(keyword)} />
                )}
            </div>

            {/* Input */}
            <input
                value={TextBoxValue}
                onChange={(e) => setTextBoxValue(e.target.value)}
                onKeyDown={onKeyPressed}
                className="mb-2"
                placeholder={placeholder}

            />

            {/* Vorschläge */}
            {LastRemoved ?
                <AiButton onClick={() => { handleAdd(LastRemoved); setLastRemoved("") }}>Entfernen von '{LastRemoved}' Rückgängig</AiButton>
                : null}
        </div>
    )
}
export default ListInput



function KeyWord({ keyword = "", prefix, onRemove = () => { } }) {
    return (
        <div className="KeyWord">
            <span className="prefix">{prefix}</span>
            <span>{keyword.toLocaleLowerCase()}</span>

            <div onClick={onRemove}>
                <i className="fa-solid fa-times-circle" />
            </div>
        </div>
    )
}

//sotriert ein array alphabetisch
function sortList(array) {
    if (Array.isArray(array)) {

        array?.sort(function (a, b) {
            if (a < b) { return -1; }
            if (a > b) { return 1; }
            return 0;
        })
        return array
    } else {
        return []
    }
}
