import React from 'react';
import "./SideBar.scss";
import SideBarEntry from './SideBarEntry';

function SideBar({ content, preUri, onClick }) {
    return (
        <div onClick={onClick} className="SideBar col-12 p-0 ps-md-4 pt-md-2">
            {content.map((i, k) =>
                <div className="Area" key={k}>
                    <span className="areaTitle">{i.title}</span>
                    {i.items.map((j, key) =>
                        <SideBarEntry preUri={preUri} {...j} key={key} />
                    )}
                </div>
            )}
        </div>
    )
}
export default SideBar
