import { useState } from "react";
import "./WeatherInput.scss";

function WeatherInput({ value = [], onChange }) {
    const [weatherOptions, setWeatherOptions] = useState(value);

    const handleOptionChange = (option) => {
        const updatedOptions = [...weatherOptions];
        if (updatedOptions.includes(option)) {
            const index = updatedOptions.indexOf(option);
            updatedOptions.splice(index, 1);
        } else {
            updatedOptions.push(option);
        }
        setWeatherOptions(updatedOptions);


        onChange({ target: { value: updatedOptions } })
    };

    const isActive = (option) => weatherOptions.includes(option.key) ? "isActive" : "";

    return (
        <div className="WeatherInput">
            <label htmlFor="weatherOptions">Wetteroptionen:</label>
            <ul>
                {opinions.map((opinion) => (
                    <div
                        key={opinion.key}
                        className={`option ${isActive(opinion)}`}
                        onClick={() => handleOptionChange(opinion.key)}
                    >
                        <label htmlFor={opinion.key}>
                            <i className={`fa fa-${opinion.icon}`} />  {opinion.title}
                            {/* // {opinion.emoji} */}
                        </label>
                    </div>
                ))}
            </ul>
        </div>
    );
}

export default WeatherInput;


const opinions = [
    {
        title: "Wetterunabhängig",
        key: "WEATHER_INDEPENDENT",
        icon: "globe",
        emoji: "🌍",
    },
    {
        title: "Bei Gewitter geschlossen",
        key: "CLOSED_DURING_STORM",
        icon: "flash",
        emoji: "🌩️",
    },
    {
        title: "Nur im Sommer",
        key: "SUMMER_ONLY",
        icon: "sun",
        emoji: "🌞",
    },
    {
        title: "Für heiße Tage",
        key: "HOT_DAYS",
        icon: "fire",
        emoji: "🔥",
    },
    {
        title: "Für schöne Tage",
        key: "BEAUTIFUL_DAYS",
        icon: "sun",
        emoji: "☀️",
    },
    {
        title: "Für kalte Tage",
        key: "COLD_DAYS",
        icon: "snowflake",
        emoji: "☃️",
    },
    {
        title: "Nur bei Schnee",
        key: "SNOW_ONLY",
        icon: "snowflake",
        emoji: "❄️",
    },
    {
        title: "Für durchwachsende Tage",
        key: "VARIABLE_DAYS",
        icon: "cloud-sun",
        emoji: "⛅",
    },
    {
        title: "Perfekt für Regnerische Tage",
        key: "RAINY_DAYS",
        icon: "cloud-rain",
        emoji: "🌧️",
    },
    {
        title: "Online",
        key: "ONLINE",
        icon: "wifi",
        emoji: "📶",
    },
    {
        title: "Vor jedem Sturm",
        key: "BEFORE_STORM",
        icon: "bolt",
        emoji: "⚡",
    },
    {
        title: "Nur bei Wind",
        key: "WIND_ONLY",
        icon: "wind",
        emoji: "💨",
    },
];

