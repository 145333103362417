export const ContactOptions = [
    {
        contactType: "INSTAGRAM", //ENUM
        url: "instagram.com", //URL an dem der Typ automatisch erkannt werdne kann / false
        fixed: false, //Tel, Email und Website werden im backend getrennt angezeigt, also sind diese "fixiert"
        title: {
            value: "Instagram",
            variable: true, //Titel varrieiert
        },
        icon: {
            iconPrefix: "fa-brands",
            icon: "instagram",
        }
    },
    {
        contactType: "CALENDLY",
        url: "calendly.com",
        fixed: false, //Tel, Email und Website werden im backend getrennt angezeigt, also sind diese "fixiert"
        title: {
            value: "Termin vereinbaren",
        },
        icon: {
            iconPrefix: "fa-solid",
            icon: "calendar",
        }
    },
    {
        contactType: "MENU",
        url: false,
        fixed: false, //Tel, Email und Website werden im backend getrennt angezeigt, also sind diese "fixiert"
        title: {

            value: "Speisekarte",

        },
        icon: {
            iconPrefix: "fa-solid",
            icon: "envelope",
        }
    },
    {
        contactType: "XING",
        url: "xing.com",
        fixed: false, //Tel, Email und Website werden im backend getrennt angezeigt, also sind diese "fixiert"
        title: {

            value: "Xing",

        },
        icon: {
            iconPrefix: "fa-brands",
            icon: "xing",
        }
    },
    {
        contactType: "FACEBOOK",
        url: "facebook.com",
        fixed: false, //Tel, Email und Website werden im backend getrennt angezeigt, also sind diese "fixiert"
        title: {

            value: "Facebook",

        },
        icon: {
            iconPrefix: "fa-brands",
            icon: "facebook",
        }
    },
    {
        contactType: "LINKEDIN",
        url: "linkedin.com",
        fixed: false, //Tel, Email und Website werden im backend getrennt angezeigt, also sind diese "fixiert"
        title: {

            value: "LinkedIn",

        },
        icon: {
            iconPrefix: "fa-brands",
            icon: "linkedin",
        }
    },
    {
        contactType: "WHATSAPP",
        url: "wa.me",
        fixed: false, //Tel, Email und Website werden im backend getrennt angezeigt, also sind diese "fixiert"
        title: {

            value: "WhatsApp",

        },
        icon: {
            iconPrefix: "fa-brands",
            icon: "whatsapp",
        }
    },
    {
        contactType: "PHONE",
        url: false,
        fixed: true, //Tel, Email und Website werden im backend getrennt angezeigt, also sind diese "fixiert"
        title: {
            value: "Anrufen",
            variable: true,
        },
        icon: {
            iconPrefix: "fa-solid",
            icon: "phone",
        }
    },
    {
        contactType: "WEBSITE",
        url: false,
        fixed: true, //Tel, Email und Website werden im backend getrennt angezeigt, also sind diese "fixiert"
        title: {
            value: "Website",
            variable: true,
        },
        icon: {
            iconPrefix: "fa-solid",
            icon: "globe",
        }
    },
    {
        contactType: "EMAIL",
        url: false,
        fixed: true, //Tel, Email und Website werden im backend getrennt angezeigt, also sind diese "fixiert"
        title: {
            value: "E-Mail",
            variable: true,
        },
        icon: {
            iconPrefix: "fa-solid",
            icon: "at", //envelope
        }
    },
]


export function getIcon(contactType) {
    console.log(contactType)
    let op = ContactOptions.filter(co => co.contactType == contactType)

    if (op.length != 1) return null
    if (op.icon) {

        let { icon, iconPrefix } = op.icon
        return `${iconPrefix || "fa-solid"} fa-${icon || "frog"}`
    } else {
        return null
    }
}