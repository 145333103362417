import Config from "../../const/Config";
import { getToken } from "../../functions/auth";
import DefaultFetcher from "./DefaultFetcher";

function EntryFetch({ renderItem }) {

    let config = {
        method: 'get',
        url: Config.ServerAddress + '/entries/list/own',
        headers: {
            'Authorization': getToken()
        }
    };

    console.log(config)

    return (
        <>
            <DefaultFetcher
                config={config}
                renderItem={renderItem} />
        </>
    )
}
export default EntryFetch