import "./AdminSupportEmail.scss"

function AdminSupportEmail({ }) {
    return (
        <div className="AdminSupportEmail">
            <div className="container mt-5">
                <h1>Standard E-Mail versenden</h1>

                <div>
                    <div>
                        <label>Übersicht Preise Mail</label>
                        <input placeholder="konrad.mayer@mittelhessen.app" />
                        <button>Senden</button>
                    </div>
                    <EmailTemplateItem />
                    <EmailTemplateItem />
                    <EmailTemplateItem />
                    <EmailTemplateItem />
                </div>
            </div>
        </div>
    )
}
export default AdminSupportEmail

function EmailTemplateItem() {
    return (
        <div>
            <label>Übersicht Preise Mail</label>

            <div>

            </div>

            <div className="row">
                <input className="col" placeholder="konrad.mayer@mittelhessen.app" />
                <button className="col-1"><i class="fa-sharp fa-solid fa-paper-plane-top"></i></button>
            </div>

        </div>
    )
}