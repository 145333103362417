import React from "react"
import renderPrice from "../../../functions/finance"
import "./PriceBox.scss"


function PriceBox({ perMonth = 0.00, durationText, intervalText, mwstValue = "19", discountAmount = 0 }) {

    let perMonthString = renderPrice(perMonth)
    let perMonthDiscount = Math.round(perMonth * ((100 - discountAmount)))
    let perMonthDiscountString = renderPrice(perMonthDiscount)
    // let perMonthString
    return (

        <div className="PriceBox">
            <div className="d-flex flex-column">

                {discountAmount == 0 ?
                    <div>
                        <h1>{perMonthString}/Monat</h1>
                    </div>
                    :
                    <div>
                        <del><p>{perMonthString}/Monat</p></del>
                        <h1>{perMonthDiscountString}/Monat</h1>
                    </div>
                }

                {discountAmount != 100 ?
                    <>
                        <span>{intervalText} im Voraus</span>
                        <small>Exkl. {mwstValue}% MwSt</small>
                    </>
                    :
                    null
                }
            </div>

        </div>
    )
}
export default PriceBox