import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import DashdesignBackendLogo from '../../components/defaults/icons/DashdesignBackendLogo';
import MittelhessenSkyline from '../../components/defaults/icons/skyline/MittelhessenSkyline';
import PasswordInput from '../../components/defaults/inputs/PasswordInput';
import { Login } from '../../services/auth';
import "./PageLogin.scss";

function PageLogin(props) {
    const history = useHistory();
    const [redirectToReferrer, setRedirectToReferrer] = useState(false)
    const [Error, setError] = useState(false);
    const [Loading, setLoading] = useState(false);
    const [Password, setPassword] = useState("");
    const [Username, setUsername] = useState("");



    let fromUri = history?.location?.state?.from?.pathname || "/"
    function handleLogin() {

        setError(false)
        setLoading(true)

        Login(Username, Password)
            .then(payload => {
                // console.log(payload)
                history.push(fromUri);
                // window.location.href = fromUri
                // return redirect(fromUri);
            })
            .catch(err => {
                setError(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }


    // if (redirectToReferrer === true) {
    //     console.log(fromUri)
    //     return <Redirect to={fromUri ? fromUri : '/'} />
    // }

    // if (Error) return <p>Error: {Error}</p>
    if (Loading) return <p>Lädt..</p>

    return (
        <div className="PageLogin row p-0 m-0">

            <div className=" col-12  col-md-6 col-lg-4 col-lg-5 p-0 m-0">
                <div className="left">


                    <div className="header">
                        <DashdesignBackendLogo />
                        <MittelhessenSkyline backgroundColor={"var(--background)"} color='var(--main)' cloudsColor={"var(--backgroundSub)"} />
                    </div>

                    <div className='p-2 p-md-3 p-xxl-5 mt-5'>
                        <div>
                            <h1>Anmelden</h1>
                            {fromUri ?
                                <p>Bitte melden Sie sich an, um fortzufahren.</p>
                                :
                                null
                            }
                        </div>

                        <div className="inner">
                            <div className="mb-4 ">

                                <div>
                                    <form>
                                        <input required
                                            className="mb-3 email"
                                            autoComplete="email"
                                            onChange={(e) => { setUsername(e.target.value) }}
                                            placeholder="E-Mail" />

                                        <PasswordInput
                                            required
                                            className="mb-3"
                                            onChange={(e) => { setPassword(e.target.value) }}
                                            placeholder="Passwort"
                                            type="password" />
                                    </form>
                                </div>

                                <div className="d-flex" style={{ marginTop: -12, }}>
                                    <Link className="p-2 small" to="/register">Noch keinen Account?</Link><br />
                                    <Link className="p-2 small" to={{ pathname: "/newpassword", state: { email: Username } }}>Passwort vergessen?</Link>
                                </div>

                                <div style={{ height: 32, }}>
                                    {Error ?
                                        <span className="errorText"><i className="fa-solid fa-exclamation-triangle" />{Error}</span>
                                        : <p></p>
                                    }
                                </div>
                                <div className="d-flex flex-row justify-content-between align-items-end mt-3">
                                    {Loading ?
                                        <button className="m-0" style={{ opacity: 0.5 }}>Lädt...</button>
                                        :
                                        <button onClick={() => handleLogin()} className="m-0">Anmelden<i className="fa-solid fa-sign-in ps-2" /></button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-6 col-lg-7 p-0 imageBox" style={{ backgroundImage: `url(https://mittelhessen.app/assets/image/location/braunfels/braunfels_blick-auf-burg_drohne.jpg)` }} >
                {/* <a href="URL" target="_blank"><p>Bild: © XY</p></a> */}
            </div>
        </div >
    )
}

export default PageLogin
