import React, { useEffect, useState } from "react"
import { NavLink } from "react-router-dom"

function SideBarEntry({ preUri, uri, title, children, label, fields }) {

    let showLabel = label ? <small>({label})</small> : null

    return (
        <div className="item">
            <NavLink className={"NavLink"} to={preUri + uri}>
                <div className="d-flex align-items-center">
                    <span>{title} {showLabel}</span>

                </div>
            </NavLink>
            {children ?
                <div className="children">
                    {children}
                </div>
                : null}
        </div>
    )
}

export default SideBarEntry