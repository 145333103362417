import React, { useState } from "react"
import AiButton from "../buttons/AiButton"
import "./OpeningHours.scss"
import TimeSpanInput from "./TimeSpanInput"

function OpeningHours(props) {
    let hours = props.value

    const [ShowCopyButton, setShowCopyButton] = useState(false)
    const [MondayCopy, setMondayCopy] = useState(false)

    const getDayKeyById = (id) => dayNamesHuman[id]

    // Zeiten von Montag auf jeden Werktag anwenden
    const handleCopyModayToWorkDays = () => {
        updateHours(copyMondayToWorkDays(hours));
        setMondayCopy(true)
    }

    const updateHours = (newValue) => {
        props.onChange({ target: { value: newValue } })
    }

    const addTimeSpan = (dayName) => {
        let dayList = hours
        let dayHours = dayList[dayName]
        dayHours = [...dayHours, ["08:00", "16:00"]]
        dayList[dayName] = dayHours
        updateHours(dayList)
    }

    const handleNewTimeStamp = (dayName, hoursOnDayId, value) => {
        console.log("handleNewTimeStamp value ", value)
        let dayList = hours
        let dayHours = dayList[dayName]


        dayHours[hoursOnDayId] = value

        updateHours(dayList)
    }

    return (
        <div className="OpeningHours">
            {dayNamesHuman.map((dayName, k) => {
                let hoursOnDayArray = hours[getDayKeyById(k)] || []
                return (
                    <div>

                        <div className="day">
                            <h4>{dayName}:</h4>
                            {hoursOnDayArray.length === 0 ? <div className="red">Geschlossen <a></a></div> : null}
                            {hoursOnDayArray.map((h, key) =>
                                <TimeSpanInput
                                    key={key}
                                    onFocus={() => { setShowCopyButton(dayName == "Mo") }}
                                    value={h}
                                    onChange={(e) => handleNewTimeStamp(dayName, key, e.target.value)}
                                />
                            )}
                            <button onClick={() => addTimeSpan(dayName)}>
                                <i className="fa-solid fa-circle-plus"></i>
                            </button>
                        </div>

                        {dayName == "Mo" && ShowCopyButton && !MondayCopy ? <div>
                            <AiButton onClick={handleCopyModayToWorkDays}>Übertragen Mo-Fr</AiButton>
                            <AiButton onClick={handleCopyModayToWorkDays}>Übertragen Mo-Sa</AiButton>
                            <AiButton onClick={handleCopyModayToWorkDays}>Übertragen auf alle Tage</AiButton>
                        </div> : null}

                        {dayName == "Fr" && MondayCopy ? <div>
                            <AiButton onClick={handleCopyModayToWorkDays}>Auch auf Samstag anwenden</AiButton>
                            <AiButton onClick={handleCopyModayToWorkDays}>Auf alle Tage übertragen</AiButton>
                        </div> : null}

                    </div>
                )
            }
            )}
        </div>
    )
}
export default OpeningHours

function copyMondayToWorkDays(hours) {

}

const dayNamesHuman = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"]
const dayNamesHumanFull = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"]
const dayNames = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"]