import React from "react"
import "./BuyButton.scss"

function BuyButton({ disabled, free = false, softDisabled, onClick = () => { } }) {
    return (
        <div className="BuyButton">
            <button className={softDisabled ? "softDisabled" : null} disabled={disabled} onClick={onClick}>
                <span>{free ? "Kostenfreien" : "Kostenpflichtigen"} Vertrag abschließen</span>
                {/* <small>Kostenpflichtig </small> */}
            </button>
            <span className="agbText"><small>Mit dem Kauf stimmen Sie unseren <a target="_blank" href="https://dashdesign.eu/agb/">AGB</a> zu.</small></span>
        </div>
    )
}
export default BuyButton