import React, { useState } from "react"
import "./ListEditor.scss"
import ObjectEditor from "./ObjectEditor"

function ListEditor({ objectKeys, value = [], onChange, className }) {

    let values = value
    console.log(value)

    function updateField(key, value) {
        let newValues = values
        console.log("key")
        console.log(key)
        console.log(value)
        newValues[key] = value
        console.log(key)
        console.log(newValues)
        // setNewValues([...newValues])
        onChange({ target: { value: newValues } })
    }

    function handleCreate(e) {
        let newValues = values
        values.push(e)
        console.log("NEW!", e, values)
        // setNewValues([...newValues])
        onChange({ target: { value: newValues } })
    }

    function reset() {
        onChange({ target: { value: [] } })
    }

    return (
        <div className={"ListEditor " + className}>
            <button onClick={reset}>DEBUG: RESET</button>
            <div className="exists">
                {values.map((i, k) =>
                    <ObjectEditor value={i} objectKeys={objectKeys} onChange={(e) => updateField(k, e.target.value)} />
                )}
            </div>
            <hr></hr>
            <AddValue objectKeys={objectKeys} onCreate={handleCreate} />
        </div>
    )
}

export default ListEditor

function AddValue({ objectKeys = [], onCreate }) {
    const [New, setNew] = useState([])

    function updateField(e) {
        setNew(e.target.value)
    }

    function sendCreate() {
        onCreate(New)
        setNew([...[null]])
    }

    return <div>
        <h3>Eintrag hinzufügen</h3>
        <ObjectEditor onChange={updateField} value={New} objectKeys={objectKeys} />


        <button onClick={sendCreate}>Eintrag hinzufügen</button>
    </div>
}