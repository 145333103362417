import React, { useEffect, useState } from "react"
import IconHeading from "../../../../text/IconHeading"
import "./Corona.scss"

function Corona({ entryData }) {

    let corona = entryData.payload?.corona || {}

    return (
        <div className="Corona style">
            <IconHeading icon="fa-solid fa-virus">Corona-Informationen</IconHeading>

            {corona.rule ?
                <IconHeading small icon="fa-solid fa-syringe">{corona.rule}</IconHeading>
                : null}

            {corona.mask ?
                <IconHeading small icon="fa-solid fa-head-side-mask">{corona.mask}</IconHeading>
                : null}

            {corona.maxPersons ?
                <IconHeading small icon="fa-solid fa-user">{corona.maxPersons}</IconHeading>
                : null}

            {corona.text ?
                <IconHeading small icon="fa-solid fa-info-circle">{corona.text}</IconHeading>
                : null}
        </div>
    )
}
export default Corona