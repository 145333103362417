import React, { useEffect, useState } from "react"
import "./DefaultLoader.scss"

function DefaultLoader({ loadingText = "Lädt Daten ...", loading = true }) {
    if (!loading) return null
    return (
        <div className="DefaultLoader">
            <i className="fa-solid fa-circle-notch fa-spin"></i>
            <span>{loadingText}</span>
        </div>
    )
}
export default DefaultLoader