// Config:
const apiVersion = ""
const useTestServer = false // (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');
const useTestCDN = false;

// Erweiterte Einstellungen:
const mainServerAddress = "https://v1-api.diestadt.app"
const testServerAddress = "http://localhost:1400"
const appServerAddress = mainServerAddress
const testAppServerAddress = testServerAddress
const cdnAddress = "https://cdn.dashdesign.eu"
const cdnTestAddress = "http://localhost:36188"
const usedServerHost = (useTestServer ? testAppServerAddress : appServerAddress)


// Technik dieser Datei:
const Config = {
    // MainServerAddress: mainServerAddress,
    ServerAddress: usedServerHost + apiVersion,
    CDNAddress: useTestCDN ? cdnTestAddress : cdnAddress
}

export default Config