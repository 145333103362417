import React, { useEffect, useState } from "react"
import "./ValidateInput.scss"

function ValidateInput({ onChange = () => console.log("Hmm Kein onChange"), value, placeholder, regex }) {

    const [Validate, setValidate] = useState(false)
    const [Text, setText] = useState(value)

    let isEmpty = !Text || Text == ""
    function validateChange() {
        let text = Text
        let validate = regex.test(text) || isEmpty
        if (validate || isEmpty) {
            onChange({ target: { value: Text } })
            setValidate(true)
        }
        else {
            setValidate(false)
        }
    }

    useEffect(() => {
        validateChange()
    }, [Text])

    return (
        <div className="ValidateInput">
            <div className="input-group mb-3">
                <input
                    onChange={(e) => setText(e.target.value)}
                    type="text"
                    value={Text}
                    className="form-control"
                    placeholder={placeholder}
                />

                {!isEmpty ?
                    <div className="icon">
                        <i className={Validate ? "fa-solid fa-check-circle" : "fa-solid fa-times-circle"} />
                    </div>
                    : null}
            </div>
            {!Validate ? <small>Solange kein gültiger Wert eingetragen ist, wird dieses Feld nicht gespiechert!</small> : null}
        </div>
    )
}
export default ValidateInput