import React, { useEffect, useState } from "react"
import "./Video_EmulatorScreen.scss"


function Video_EmulatorScreen({ videoSrc }) {

    return (
        <div className="Video_EmulatorScreen">
            <video muted autoplay loop width="320" autoplay="autoplay">
                <source src={videoSrc} type="video/mp4"></source>
            </video>
        </div>
    )
}
export default Video_EmulatorScreen