



export const logout = (force) => {
    if (force || window.confirm("Möchten Sie sich wirklich abmelden?")) {
        sessionStorage.removeItem("token")
        localStorage.removeItem("uuid")
        localStorage.removeItem("t")
        localStorage.removeItem("preName")
        localStorage.removeItem("lastName")
        localStorage.removeItem("mail")
        localStorage.removeItem("isAdmin")
        window.location.reload();
        return true
    } else {
        return false
    }
}

//Get Value
/** Gibt den JWT-Token inkl. 'Bearer (...)' zurück */
export const getToken = () => "Bearer " + sessionStorage.getItem("token")
export const getEmail = () => localStorage.getItem("mail")
export const getUuid = () => localStorage.getItem("uuid")
export const getPreName = () => localStorage.getItem("preName") || "Xyy"
export const getFamilyName = () => localStorage.getItem("familyName") || "Yyy"
/** Gibt das Vollen Name des Useres zurück z.B.: "Konrad Mayer" */
export const getFullName = () => `${getPreName()} ${getFamilyName()}`
/** Gibt das Namenskürzel des Useres zurück z.B.: "KM" bei Konrad Meister */
export const getAbbreviation = () => {
    let preName = getPreName()
    let familyName = getFamilyName()

    let abbreviation = preName.substring(0, 1) + familyName.substring(0, 1)

    abbreviation = abbreviation.toUpperCase()

    //Viele Leute, besonders deutsche, möchten nicht 'SS' als kürzel nutzen
    if (abbreviation == "SS") {
        abbreviation = `S${preName.substring(1, 2)}S`
    }
    return abbreviation
}

//Check Bool
export const isAdmin = () => localStorage.getItem("isAdmin") === "true"
export const isLoggedIn = () => {
    let token = sessionStorage.getItem("token")
    if (token == "Bearer null") return false
    return (token)
}
