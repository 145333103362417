import axios from 'axios';
import Config from '../../const/Config';
import { getToken } from '../../functions/auth';


export function getFormularProps(overrideObjs, EntryID, onSend, onChange) {
    let formularProps = Object.assign({}, eventPostFormularProps);

    Object.keys(overrideObjs).forEach((i) => {
        formularProps.fields.forEach((f) => {
            if (f.keyName == i) {
                f.value = overrideObjs[i];
            }
        });
    });

    formularProps.apiAdress = Config.ServerAddress + "/events/";
    formularProps.axiosHeaders = { 'Authorization': getToken() };
    formularProps.onSend = onSend;
    formularProps.onMapData = (data) => { return { payload: { ...data, entryID: EntryID } } }
    formularProps.onChange = onChange;
    return formularProps;


}

export async function loadEventsFromApi(filter) {
    return new Promise((resolve, reject) => {


        let data = JSON.stringify({ filter });

        let config = {
            method: 'post',
            url: Config.ServerAddress + '/events/list',
            headers: {
                'Content-Type': 'application/json'
            },
            params: {
                amount: 100,
                page: 0,
            },
            data: data
        };

        axios(config)
            .then(res => resolve(res.data))
            .catch(err => {
                reject(err)
                alert("Fehler beim laden der Events.")
            });
    })
}

let eventPostFormularProps = {
    apiAdress: Config.ServerAddress + "/event/",
    title: null,
    sendText: "Event veröffentlichen",
    fields: [
        {
            title: "Titel*",
            type: "STRING",
            value: "",
            placeholder: "Gallusmarkt",
            keyName: "title",
            required: true,
        },



        {
            title: "Startdatum*",
            type: "DATE",
            value: "",
            placeholder: "Beschreiben Sie Event mit einigen Sätzen.",
            keyName: "startDate",
            required: true,
            className: "col-12 col-md-9",
        },
        {
            title: "Startzeit",
            type: "TIME",
            value: "",
            placeholder: "Beschreiben Sie Event mit einigen Sätzen.",
            keyName: "startTime",
            className: "col-12 col-md-3",
        },



        {
            title: "Enddatum",
            type: "DATE",
            value: "",
            placeholder: "Beschreiben Sie Event mit einigen Sätzen.",
            keyName: "endDate",
            className: "col-12 col-md-9",
        },



        {
            title: "Beschreibung",
            type: "TEXT",
            value: "",
            placeholder: "Beschreiben Sie das Event mit einigen Sätzen.",
            keyName: "description",

        },

        {
            title: "Veranstaltungsort",
            type: "LOCATION",
            value: "",
            placeholder: "z.B.: Brunnental, Grünberg",
            keyName: "locationName",

        },

        {
            title: "Eintrittspreis",
            type: "TEXT",
            value: "",
            placeholder: "z.B.: 'Eintritt 4€\nKinder 2€'",
            keyName: "priceText",
        },


        {
            title: "Website des Events",
            type: "URL",
            value: "",
            placeholder: "z.B.: https://gallusmarkt-gruenberg.de/",
            keyName: "url"
        },

        {
            title: "Event-Bild",
            type: "IMAGE",
            value: "",
            placeholder: "Bild-URL eintragen",
            keyName: "image"
        },


    ]
}