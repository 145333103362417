import React, { useEffect, useState } from "react"
import "./IconHeading.scss"

function IconHeading({ icon = "fa-solid fa-frog", children, small }) {
    return (
        <div className={"IconHeading " + (small ? " small" : null)}>
            <div className="iconBox">
                <i className={`${icon}`} />
            </div>
            <h3>{children}</h3>
        </div>
    )
}
export default IconHeading