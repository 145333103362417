import React, { useEffect, useState } from "react"

function Clock({ }) {

    const [today, setDate] = React.useState(new Date()); // Save the current date to be able to trigger an update

    React.useEffect(() => {
        const timer = setInterval(() => { // Creates an interval which will update the current data every minute
            setDate(new Date());
        }, 1 * 1000);
        return () => {
            clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
        }
    }, []);

    const time = today.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });


    return <span>{time || "12:00"}</span>
}
export default Clock