import React, { useEffect, useState } from "react"
import "./EmailInput.scss"
import ValidateInput from "./ValidateInput"

function EmailInput(props) {
    const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return (
        <div className="EmailInput">
            <ValidateInput {...props} regex={regex} />
        </div>
    )
}
export default EmailInput