import React from "react"
import IconHeading from "../../../../text/IconHeading"
import "./OpeningTimes.scss"

function OpeningTimes({ entryData }) {
    return (
        <div className="OpeningTimes style">
            <IconHeading icon="fa-solid fa-calendar">Öffnungszeiten</IconHeading>
            <p>Wird im Simulator noch nicht angezeigt.</p>
        </div>
    )
}
export default OpeningTimes