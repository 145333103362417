import axios from 'axios';
import React from 'react';
import { Helmet } from 'react-helmet';
import Formular from '../../components/api/Formular';
import TitleBar from '../../components/bars/TitleBar/TitleBar';
import Config from '../../const/Config';

export class AdminAddCoronaEntry extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cities: []
        }
    }

    async componentDidMount() {
        const config = {
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem("token") },
        }
        let result = await axios.get(Config.ServerAddress + "/admin/getCities", config).catch((err) => console.log(err));
        if (Array.isArray(result.data)) {
            this.setState({ cities: result.data });
        }
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Corona-Einträge</title>
                </Helmet>
                <div className="container">
                    <TitleBar showBackButton title="Corona-Einträge" />
                    {
                        this.state.cities.length == 0 ?
                            <h2>Lädt... Bitte warten.</h2> :
                            <Formular {...this.getProps()}></Formular>

                    }
                </div>
            </div>);
    }

    onSend() {
        alert("Veröffentlicht!");
    }

    getProps() {
        const props = {
            apiAdress: Config.ServerAddress + "/admin/addCoronaEntry",
            axiosHeaders: { 'Authorization': 'Bearer ' + sessionStorage.getItem("token") },
            title: null,
            sendText: "Corona-Eintrag veröffentlichen",
            onMapData: (a) => a,
            onSend: this.onSend,
            fields: [
                {
                    title: "Stadt-Nr*",
                    type: "SELECTION",
                    value: 0,
                    options: this.state.cities.map((i) => { return { key: i.name, value: i.ID } }),
                    placeholder: "",
                    keyName: "cityId",
                    required: true,
                },
                {
                    title: "Aktive Fälle*",
                    type: "INT",
                    value: "",
                    placeholder: "",
                    keyName: "active",
                },
                {
                    title: "Sieben-Tage-Inzidenz der Gemeinde*",
                    type: "INT",
                    value: "",
                    placeholder: "",
                    keyName: "sevenDays",
                },
                {
                    title: "Sieben-Tage-Inzidenz des Landes",
                    type: "INT",
                    value: "",
                    placeholder: "",
                    keyName: "sevenDaysLand"
                }


            ]
        };
        return props;
    }

}

