import React from "react"
import "./CityThemes.scss"
import Device from "./components/Device"
import "./Emulator.scss"
import EntryPage_EmulatorScreen from "./screens/EntryPage/EntryPage_EmulatorScreen"

function Emulator({ entryData, extraData, emulator, mobileShowEmulator, onNavigateBack }) {

    const Page = emulator?.page;

    return (
        <div className={"Emulator " + (mobileShowEmulator ? "mobileShowEmulator" : null)}>
            <Device live={emulator ? emulator.live : false} hideStatusbar={emulator ? emulator.hideStatusbar : false}>
                {Page ? <div onClick={onNavigateBack}>
                    <Page {...emulator.props} extraData={extraData}></Page>
                </div> :
                    <EntryPage_EmulatorScreen onNavigateBack={onNavigateBack} entryData={entryData} />
                }
            </Device>
        </div>
    )
}
export default Emulator