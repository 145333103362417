import React, { useState } from "react"
import { ContactOptions } from "../../../const/ContactOptions"
import "./LinksInput.scss"

const FIXED_TYPES = [
    {
        title: "Telefonnummer",
        contactType: "PHONE"
    },
    {
        title: "E-Mail",
        contactType: "MAIL"
    },
    {
        title: "Website",
        contactType: "MAIL"
    },
]

function LinksInput({ value, setNewValue }) {

    let links = value

    function handleLinkChange(e, linkID) {
        let newValue = e.target.value
        links[linkID].value = newValue
        setNewValue(links)
    }

    function changeFixedType(contactType, value) {
        let newValue = {
            value: value,
            contactType: contactType
        }
        let id = links.findIndex(link => link.value === contactType)
        if (id) {
            links[0] = newValue
        } else {
            links.push(newValue) // wenn es contactType nonit gibt
        }
        setNewValue(links) //links wieder aktualisieren
    }

    //Input Komponente für Telefon & E-Mail
    function FixedValueInput({ contactType, title }) {
        let currentValue = value.filter(item => item.contactType == contactType)[0]?.value
        return <div>
            <h3>{title}</h3>
            <input onChange={e => changeFixedType(contactType, e.target.value)} value={currentValue} />
        </div>
    }

    let socialMediaLinks = links.filter((link) => {
        let isFixed = FIXED_TYPES.filter(fixedType => fixedType.contactType == link.contactType)[0] !== null
        return isFixed
    })

    return (
        <div className="LinksInput">
            {/* {FIXED_TYPES.map((i, k) => <FixedValueInput {...i} key={k} />)} */}


            <h2>Soziale Medien & Weitere URL's</h2>
            {/* {socialMediaLinks.map((i, k) => <LinkBox onLinkChange={handleLinkChange} {...i} id={k} key={k} />)} */}

            <p>Bald verfügbar.</p>

            {ContactOptions.map((i, k) => {
                let value = socialMediaLinks.filter(link => link.contactType == i.contactType)[0]?.value
                if (!value) return null //Wenn es zB kein Xing gibt, wird dieser Typ nicht angezeigt. Die Rohdaten aus Mongo könnenn nicht gemappt werden, da sonst infos fehlen, zB über die Icons 
                return <LinkBox onLinkChange={handleLinkChange} {...i} value={value} id={k} key={k} />
            })}


            < AddSocialMedia />

        </div>
    )
}
export default LinksInput




function AddSocialMedia() {
    const [Url, setUrl] = useState("")

    return <div>
        <h3>Soziales Netzwerk oder URL hinzufügen</h3>
        <input value={Url} placeholder="Profil-URL" />
    </div>
}

function LinkBox(contactOptionsItem) {
    let { contactType, value, onLinkChange, id, icon } = contactOptionsItem



    return <div className="LinkBox" style={{ marginBottom: 32, }}>
        <i className={`${icon.iconPrefix || "fa-solid"} fa-${icon.icon || "frog"}`} />
        <input value={value} onChange={(e) => onLinkChange(e, id)} />
    </div>
}

