import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import EntryFetch from "../../components/api/EntryFetch"
import "./EntryListPage.scss"

function EntryListPage({ }) {

    const [SearchText, setSetSearchText] = useState()
    const [Token, setToken] = useState("Lädt...")
    const [Mail, setMail] = useState("...")

    function setSearchText(txt = "") {
        txt = txt.toLocaleLowerCase()
        setSetSearchText(txt)
    }

    useEffect(() => {
        setToken(sessionStorage.getItem("token"))
        setMail(localStorage.getItem("mail"))
    }, [])

    return (
        <div className="EntryListPage">
            <div className="container mt-4" style={{ maxWidth: 1200 }}>

                {/* NavTop */}
                <div>
                    <div className="d-flex flex-row align-items-center justify-content-between">
                        <div className="d-flex align-items-baseline">
                            <h1>Einträge</h1>
                            <Link to="/neuer-eintrag" className="p-4">
                                <i className="fa-duotone fa-circle-plus" />
                            </Link>


                            <div className="inputGroup">
                                <input
                                    className="Search"
                                    value={SearchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    placeholder="Einträge durchsuchen" />

                                <div className="input-group-append">
                                    <i className="fa-solid fa-search" />
                                    {/* <span className="input-group-text" id="basic-addon2">
                            </span> */}
                                </div>
                            </div>
                        </div>
                        <hr />
                    </div>


                    <div className="row">
                        <h4 style={{ opacity: SearchText ? 1 : 0 }}>Treffer für '{SearchText}'</h4>
                        <EntryFetch
                            key={SearchText}
                            renderItem={(i, k) =>
                                <EntryListItem
                                    key={k}
                                    searchFilter={SearchText}
                                    entryData={i}
                                />
                            }
                        />
                    </div>

                    {/* <hr className="py-5" />

                    <p>Wenn Sie eine andere E-Mail als <b>{Mail}</b> nutzen, hängen Sie bitte den folgenden Token mit:</p>
                    <span className="token">{Token}</span> */}
                </div>
            </div>
        </div>
    )
}
export default EntryListPage


function EntryListItem({ entryData, searchFilter }) {
    const [Entry, setEntry] = useState({})

    useEffect(() => {
        if (!searchFilter || entryData.general.title.toLocaleLowerCase().includes(searchFilter)) {
            let data = { ...entryData, data: JSON.parse((entryData.data || "{}")) }
            setEntry(data)
        } else {
            setEntry(null)
        }
    }, [])

    if (Entry == null || Entry == {}) return null
    return (
        <Link to={"/entry/" + Entry?.meta?.entryID + "/wichtig"} className=" col-12 col-lg-4 mb-3">
            <div className="EntryListItem">

                <div className="col-4">
                    <div className="ratio ratio-4x3">
                        <img className="entryIcon" src={Entry?.payload?.images[0]?.url} />
                    </div>
                </div>

                <div className="text col-8 p-2">
                    <h4 className="m-0">{Entry?.general?.title}</h4>
                    <p className="mb-0">{Entry?.payload?.address?.street.replace("\n", ", ") || "Ohne Adresse"}</p> {/*Todo: Adresse fixen*/}
                </div>
            </div>
        </Link>
    )
}

function getAboText(aboType) {

}