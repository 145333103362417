import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Helmet from "react-helmet"
import { Link } from 'react-router-dom';
import Config from '../../../const/Config';

function AdminCityAdd() {
    const [Name, setSetName] = useState("");
    const [AppName, setAppName] = useState("");
    const [AppSlug, setAppSlug] = useState("");
    const [FullCityName, setFullCityName] = useState("");
    const [CityType, setSetCityType] = useState("");
    const [CityWebsiteUrl, setSetCityWebsiteUrl] = useState("");
    const [CityWebsiteTitle, setCityWebsiteTitle] = useState("");
    const [CityWebsiteSearchUrl, setCityWebsiteSearchUrl] = useState("");
    const [SkylineLightImg, setSkylineLightImg] = useState("");
    const [SkylineDarkImg, setSkylineDarkImg] = useState("");

    function setName(name = "") {
        setSetName(name)
        //Autofill:
        setAppName(name)
        setAppSlug(`app.diestadt.${name.toLowerCase()}`)
        setFullCityName(`${CityType} ${name}`)
    }

    function setCityType(cityTypeStr) {
        setSetCityType(cityTypeStr)
        //Autofill:
        setFullCityName(`${cityTypeStr} ${Name}`)
    }

    function setCityWebsiteUrl(url) {
        setSetCityWebsiteUrl(url)
        //Autofill:
        try {
            let host = new URL(url).host
            host = host.replace("www.", "")
            setCityWebsiteTitle(host)
        } catch (e) { }
    }

    // "Stadt anlegen" Button Click
    function createCity() {
        let apiEndpoint = Config.ServerAddress + "/admin/city/add"
        let data = {
            name: Name,
            appName: AppName,
            appSlug: AppSlug,
            fullCityName: FullCityName,
            cityType: CityType,
            cityWebsiteUrl: CityWebsiteUrl,
            cityWebsiteTitle: CityWebsiteTitle,
            cityWebsiteSearchUrl: CityWebsiteSearchUrl,
            skylineLightImg: SkylineLightImg,
            skylineDarkImg: SkylineDarkImg,
        }
        let config = { headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem("token") }, }

        axios.post(apiEndpoint, data, config)
            .then(res => {
                alert("OK, erstellt.")
                console.log(res)
            })
            .catch(err => {
                alert("Fehler beim Anlegen der App: " + err)
            })
    }


    return (
        <div className="col-12 col-md-9 mt-4">
            <Helmet>
                <title>Neue Stadt anlegen - Adminbereich</title>
            </Helmet>


            <div className="">
                <h1>Neue Kommune</h1>
                <div className="mb-5">
                    <h3>Name</h3>
                    <input placeholder='zB: Grünberg' value={Name} onChange={(e) => setName(e.target.value)} />
                </div>

                <div className="mb-5">
                    <h3>Typ</h3>
                    {/* <input placeholder='zB: Stadt / Gemeinde / Insel' value={CityType} onChange={(e) => setCityType(e.target.value)} /> */}
                    <select value={CityType} onChange={(e) => setCityType(e.target.value)}>
                        <option value="">{"<Bitte auswählen>"}</option>
                        <option value="Gemeinde">Gemeinde</option>
                        <option value="Stadt">Stadt</option>
                        <option value="Landkreis">Landkreis</option>
                        {/* <option value="Bundesland">Bundesland</option>
                        <option value="Dorf">Dorf</option>
                        <option value="Land">Land</option>
                        <option value="Kontinent">Kontinent</option>
                        <option value="Schule">Schule</option>
                        <option value="Planet">Planet</option> */}
                    </select>
                </div>
                <hr></hr>

                <h2>Details:</h2>



                <div className="mb-5">
                    <h3>Website URL</h3>
                    <input placeholder='zB: https://grünberg.de#ref123' value={CityWebsiteUrl} onChange={(e) => setCityWebsiteUrl(e.target.value)} />
                </div>

                <div className="mb-5">
                    <h3>Website Suche URL</h3>
                    {/* <select value={CityType} onChange={(e) => setCityWebsiteSearchUrl(e.target.value)}>
                        <option value="">{"<Bitte auswählen>"}</option>
                        <option value={CityWebsiteUrl + "?q="}>e-kom ('?q=')</option>
                        <option value={CityWebsiteUrl + "?s="}>Deutsches Wordpress ('?s=')</option>
                    </select>
                    <a target='_blank' href={setCityWebsiteSearchUrl + encodeURI("Stadt App")}><small>Test suche: "Stadt App"</small></a> */}
                    <input placeholder='zB: https://grünberg.de?q=' value={CityWebsiteSearchUrl} onChange={(e) => setCityWebsiteSearchUrl(e.target.value)} />
                </div>




                <div className="mb-2">
                    <h3>Skyline Dark URL</h3>
                    <input placeholder='zB: https://grünberg.de/skyline.png?color=#000' value={SkylineDarkImg} onChange={(e) => setSkylineDarkImg(e.target.value)} />
                </div>
                <div className="mb-5">
                    <h3>Skyline Light URL</h3>
                    <input placeholder='zB: https://grünberg.de/skyline.png?color=#fff' value={SkylineLightImg} onChange={(e) => setSkylineLightImg(e.target.value)} />
                </div>

                <button onClick={createCity}>Stadt Anlegen</button>

                <hr></hr>
                <h2>Bitte prüfen: </h2>


                <div className="mb-5">
                    <h3>Website Display URL</h3>
                    <input placeholder='zB: gruenberg.de' value={CityWebsiteTitle} onChange={(e) => setCityWebsiteUrl(e.target.value)} />
                </div>


                <div className="mb-5">
                    <h3>App Name (evtl. kürzen, steht später unterm App Icon)</h3>
                    <input placeholder='zB: Stadt Grünberg' value={AppName} onChange={(e) => setAppName(e.target.value)} />
                </div>

                <div className="mb-5">
                    <h3>App Slug & Bundle ID</h3>
                    <input placeholder='zB: Stadt Grünberg' value={AppSlug} onChange={(e) => setAppSlug(e.target.value)} />
                </div>



                <div className="mb-5">
                    <h3>Vollständger Stadt Name</h3>
                    <input placeholder='zB: Stadt Grünberg' value={FullCityName} onChange={(e) => setFullCityName(e.target.value)} />
                </div>

                <button onClick={createCity}>Stadt Anlegen</button>
                {/* <div className="mb-5">
                    <h2>App Name</h2>
                    <input placeholder='zB: Grünberg' value={CityName} onChange={(e) => setCityName(e.target.value)} />
                </div> */}


            </div>
        </div>
    )
}
export default AdminCityAdd