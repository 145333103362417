import axios from 'axios';
import { useEffect, useState } from 'react';
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import Config from '../../const/Config';
import "./AdminUserList.scss";
function AdminUserList() {

    const [Users, setUsers] = useState([]);

    useEffect(() => {
        let config = {
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem("token") },
        }
        let url = Config.ServerAddress + "/admin/users"
        axios.get(url, config)
            .then(res => {
                setUsers(res.data)

            })
            .catch(err => { console.log(err) })


    }, [])

    function handleSendInfoMail(user) {
        if (window.confirm("Möchtest du User-Nr. " + user.ID + " ein Info-Mail? Mail: " + user.email + "")) {
            let url = Config.ServerAddress + "/admin/infoMail"
            let data = { targetUUID: user.ID, targetUser: user }
            let config = {
                headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem("token") },
            }
            axios.post(url, data, config)
                .then(res => {
                    alert("Info-Mail an " + user.email + "")
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }


    function handleResetPassword(user) {

        if (window.confirm("Möchtest du das Passwort für User-Nr. " + user.ID + " zurüksetzen? Das neue Passwort wird an " + user.email + " gesendet.")) {

            let url = Config.ServerAddress + "/admin/newPassword"
            let data = { targetUUID: user.ID, targetUser: user }
            let config = {
                headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem("token") },
            }
            axios.post(url, data, config)
                .then(res => {
                    alert("Passwort geändert und an: " + user.email + " gesendet.")
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    function handleApiTest() {
        let url = Config.ServerAddress + "/admin/rundmail"
        let config = {
            headers: { 'Authorization': 'Bearer ' + sessionStorage.getItem("token") },
        }

        axios.post(url, "", config)
            .then(res => {
                console.log(res)
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <div className="AdminUserList">
            <Helmet>
                <title>Adminbereich</title>
            </Helmet>


            <div className="container p-4">
                <h2>Unternehmensaccounts ({Users.length || "..."})</h2>
                <hr></hr>
                <table>
                    <thead>
                        <th>ID</th>
                        <th>Vorname</th>
                        <th>Nachname</th>
                        <th>Gender</th>
                        <th>E-Mail</th>
                        <th>Telefon</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </thead>

                    {Users.map((i, k) =>
                        <tr>
                            <td style={{ opacity: 0.8 }}>{i.ID}</td>
                            <td>{i.preName}</td>
                            <td>{i.familyName}</td>
                            <td>{i.gender == 1 ? "Frau" : "Herr"}</td>
                            <td>{i.email}</td>
                            <td>{i.phone}</td>

                            <td>
                                <Link title="Berechtigungen" to={"/admin/user/" + i.ID}>
                                    <i class="fa-solid fa-user-crown"></i>
                                </Link>
                            </td>
                            <td>
                                <a title="Neues Passwort versenden" onClick={() => handleResetPassword(i)} >
                                    <i class="fa-solid fa-unlock"></i>
                                </a>
                            </td>
                            {/* <td>{i.Phone}</td> */}



                            {/* <p>UUID: {i.ID} – Gender: {i.gender}</p>
                            <div className="d-flex flex-row">
                                <p>{i.preName} <b>{i.familyName}</b></p>
                                <a href={"mailto:" + i.email}>Mail</a>
                                <a href={"call:" + i.Phone}>Anrufen</a>
                                <Link to={"/admin/user/" + i.ID} href={"call:" + i.Phone}>Permissions</Link>
                                <a onClick={() => handleResetPassword(i)} >Neues Passwort</a>
                                {/* <span onClick={() => handleSendInfoMail(i)} className="mr-4">Info-Mail</span> 
                            </div>*/}

                        </tr>
                    )}

                </table>
            </div>
        </div>
    )
}
export default AdminUserList