import { useState } from "react"
import Formular from "../api/Formular"
import PopupBox from "../defaults/boxes/PopupBox"
import "./AddEventButton.scss"

function AddEventButton({ formularProps }) {

    const [IsOpen, setIsOpen] = useState(false)



    return (
        <div className="AddEventButton">
            <div className="col-9 p-0">

                <button onClick={() => setIsOpen(true)}>
                    <i class="fa-solid fa-plus"></i>
                </button>

                <PopupBox visible={IsOpen}>
                    <div className="container">
                        <h2>Neue Veranstaltung:</h2>
                        <Formular onSend={() => setIsOpen(false)} {...formularProps} />
                    </div>
                </PopupBox>

            </div>
        </div>
    )
}
export default AddEventButton