import React from "react";
import { Icon, IconGroup } from "../../../types/Icon";
import "./IconComponent.scss";

function IconComponent(icon: Icon) {
  let renderClass;
  switch (icon.group) {
    case IconGroup.normal:
      renderClass = "fa-duotone";
      break;
    case IconGroup.brand:
      renderClass = "fa-brand";
      break;
  }
  return <i className={`Icon ${renderClass} fa-${icon.name}`} />;
}

export default IconComponent;
