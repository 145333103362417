import TextareaAutosize from 'react-textarea-autosize'
import "../../assets/styles/inputs/ProductInputList.scss"
import { regexPhone } from "../../const/regex"
import LinksInput from "../api/Inputs/LinksInput"
import ListEditor from "../api/ListEditor"
import ObjectEditor from "../api/ObjectEditor"
import Tip from "../defaults/boxes/Tip"
import AddressInput from "../defaults/inputs/AddressInput"
import ColorInput from "../defaults/inputs/ColorInput"
import DropdownInput from '../defaults/inputs/DropdownInput/DropdownInput'
import EmailInput from "../defaults/inputs/EmailInput"
import IconInput from "../defaults/inputs/IconInput"
import ListInput from "../defaults/inputs/ListInput"
import OpeningHours from "../defaults/inputs/OpeningHours"
import PdfInput from "../defaults/inputs/PdfInput"
import SelectInput from "../defaults/inputs/SelectInput"
import Switch from "../defaults/inputs/Switch"
import ThreeHundredSixtyInput from "../defaults/inputs/ThreeHundredSixtyInput"
import ValidateInput from "../defaults/inputs/ValidateInput"
import WeatherInput from '../defaults/inputs/WeatherInput/WeatherInput'
import WebsiteInput from "../defaults/inputs/WebsiteInput"
import "./FieldList.scss"

function FieldList({ fields = [], entryData, title, onChange }) {
    return (
        <div className="FieldList">
            <h2>{title}</h2>
            <div className="pt-4">
                {fields.map((i, k) =>
                    <RenderInput {...i} entryData={entryData} id={k} onChange={onChange} key={k} />
                )}
            </div>
            {fields.length == 0 ? <p>Bald verfügbar!</p> : null}
        </div>
    )
}
export default FieldList

function RenderInput(i) {
    let { onChange, entryData, id, isRequired } = i

    //src: https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-and-arrays-by-string-path
    Object.byString = function (o, s) {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        var a = s.split('.');
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in o) {
                o = o[k];
            } else {
                return;
            }
        }
        return o;
    }

    let getValue = Object.byString(i.entryData, i.objKey); //Value aud den Daten laden

    function handleChange(e) {

        var obj = entryData  // global object

        function set(path, value) {
            var schema = obj;  // a moving reference to internal objects within obj
            var pList = path.split('.');
            var len = pList.length;
            for (var i = 0; i < len - 1; i++) {
                var elem = pList[i];
                if (!schema[elem]) schema[elem] = {}
                schema = schema[elem];
            }

            schema[pList[len - 1]] = value;
        }
        set(i.objKey, e?.target?.value || "")
        onChange(entryData)
    }

    return (
        <div>
            <div className="d-flex align-items-end mb-2">
                <div>
                    {isRequired ? <span className="RequiredLabel">Pflichtfeld</span> : null}
                    <h3 className="m-0">{i.title}</h3>
                </div>
                <Tip>{i.tip}</Tip>
            </div>

            <div className="d-flex mb-5">
                <Input key={i.title} {...i} value={getValue} entryData={i.entryData} onChange={handleChange} />
            </div>
        </div >
    )
}



function Input({ type, entryData, onChange, title, placeholder, example, value, id, typePayload }) {
    function getPlaceholder() {
        if (placeholder) {
            return placeholder
        }
        else if (example) {
            return `z.B.: ${example}`
        } else {
            return `${title} hier eintragen`
        }
    }



    let inputProps = { onChange: onChange, value: value, placeholder: getPlaceholder(), key: id }
    inputProps = { ...inputProps, ...typePayload }


    inputProps.setNewValue = function (newValue) { onChange({ target: { value: newValue } }) }

    // handleChangeAfterCheck() {
    //     if(check == true) {onChange}#
    // hier bin ich mir noch unsicher wie man das clean macht
    // }
    let res
    switch (type) {
        //custom
        case "OBJECT_LIST":
            res = <ListEditor  {...inputProps} />
            break;

        case "DROPDOWN":
            res = <DropdownInput  {...inputProps} />
            break;


        case "OBJECT":
            res = <ObjectEditor  {...inputProps} />
            break;

        //Default:
        case "LOCATION":
            res = <AddressInput {...inputProps} />
            break;
        case "STRING":
            res = <input  {...inputProps} />
            break;
        case "TEXT":
            res = <TextareaAutosize minRows={4} {...inputProps} />
            break;
        case "BOOL":
            res = <Switch  {...inputProps} />
            break;
        case "REVERSED_BOOL":
            res = <input type="checkbox"  {...inputProps} value={!inputProps.value} />
            break;
        case "INT":
            res = <input type="number"  {...inputProps} />
            break;
        case "SELECT_STRING":
            res = <SelectInput type="STRING"  {...inputProps} />
            break;
        case "SELECT_INT":
            res = <SelectInput type="STRING"  {...inputProps} />
            break;
        case "LINKS":
            res = <LinksInput  {...inputProps} />
            break;

        case "COLOR":
            res = <ColorInput {...inputProps} />
            break;
        case "URL":
            res = <WebsiteInput {...inputProps} />
            break;
        case "PHONE":
            res = <ValidateInput  {...inputProps} regex={regexPhone} />
            break;

        case "ID":
            res = <input {...inputProps} />
            break;

        case "PDF":
            res = <PdfInput {...inputProps} />
            break;
        case "360":
            res = <ThreeHundredSixtyInput {...inputProps} />
            break;
        case "MAIL":
            res = <EmailInput {...inputProps} />
            break;
        case "LIST":
            res = <ListInput {...inputProps} />
            break;
        case "LIST_INT":
            res = <ListInput filter="INT" {...inputProps} />
            break;
        case "HASHTAGS":
            res = <ListInput prefix="#" {...inputProps} />
            break;
        case "OPENING_HOURS":
            res = <OpeningHours {...inputProps} />
            break;
        case "WEATHER":
            res = <WeatherInput {...inputProps} />
            break;
        case "ICON":
            res = <IconInput {...inputProps} />
            break;
        case "ADDRESS":
            res = <AddressInput  {...inputProps} regex={regexPhone} />
            break;
        default:
            res = <p>Versuchen Sie es bitte später erneut! (Support-Code: Field-{type})</p>
    }
    return <div className="w-100">{res}</div>
}